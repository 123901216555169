import { useState, React} from "react";
import BasicModal from "../../../layout/BasicModal";
import {
  TextField,
  Typography,
  Grid,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormHelperText,
} from "@mui/material";
import BasicSlider from "../../../layout/BasicSlider";
import {
  AddTemplateFysisk,
  AddTemplateMental,
  AddTemplateForstehjelp
} from "../../../api/endpoints";
import { successNote, toastErrorNotify } from "../../../helpers/toastNotify";
import Loading from "../../../layout/Loading";
import * as Yup from "yup";
import { useCheckAvailableTemplatesQuery, useAddTemplateMutation } from "../../../services/Template/templateSlice";

const testMark = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 100,
    label: "100%",
  },
];

const TemplatecreationModal = ({ open, onClose, getTemplates }) => {
  const [patientValue, setPatientValue] = useState(1);
  const [approvalPercentage, setApprovalPercentage] = useState(0);
  const [testValue, setTestValue] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [templateUrl, setTemplateUrl] = useState(null);
  const [generalSuccess, setGeneralSuccess] = useState(0);
  const [teoriValue, setTeoriValue] = useState(false);
  const [treningValue, setTreningValue] = useState(false);
  const [radioError, setRadioError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [checkBoxText, setCheckBoxText] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const { data: availableTemplates, } = useCheckAvailableTemplatesQuery();
  const [addTemplate, { isLoading }] = useAddTemplateMutation();

  // ! Validation Check
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Malens navn er påkrevet"),
  });

  // ! Handle Radio Button and Input Change

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setHelperText("");
    setRadioError(false);
    if (e.target.value === "mental") setTemplateUrl(AddTemplateMental);
    else if (e.target.value === "fysisk") setTemplateUrl(AddTemplateFysisk);
    else setTemplateUrl(AddTemplateForstehjelp);
  };

  const handleInputChange = (e) => {
    setTemplateName(e.target.value);
  };

  // ! Handle Sliders

  const handlePasientChange = (e) => {
    setPatientValue(e.target.value);
  };

  const handleTestChange = (e) => {
    setApprovalPercentage(e.target.value);
  };

  const handleGeneralSuccessChange = (e) => {
    setGeneralSuccess(e.target.value);
  };

  // ! Handle Checkboxes

  const handleCheckboxChange1 = (e) => {
    setTeoriValue(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setTestValue(e.target.checked);
  };

  const handleCheckboxChange3 = (e) => {
    setTreningValue(e.target.checked);
  };


  // ! Add Template API Call

  async function handleClick(e) {
    e.preventDefault();
    setLoading(true);

    validateRadioValue(radioValue);
    validateCheckBoxes(teoriValue, testValue, treningValue);

    const initialValues = {
        name: templateName,
    };

    const params = {
        name: templateName,
        detail: "string",
        testApprovalPercentage: `${approvalPercentage}`,
        generalSuccessPercentage: `${generalSuccess}`,
        patientCount: `${patientValue}`,
        isActive: true,
        test: testValue,
        teori: teoriValue,
        training: treningValue,
    };

    try {
        await validationSchema.validate(initialValues, { abortEarly: false });
        const response = await addTemplate({ templateUrl, params });

        handleApiResponse(response);

    } catch (err) {
        handleApiError(err);
    } finally {
        setLoading(false);
    }
}

function validateRadioValue(value) {
    const message = value === "" ? "Vennligst velg et alternativ" : "";
    setHelperText(message);
}

function validateCheckBoxes(teori, test, trening) {
    const message = !(teori || test || trening) ? "Vennligst velg minumum ett alternativ" : "";
    setCheckBoxText(message);
}

function handleApiResponse(response) {
    if (response?.data && response.data.statusCode === 200) {
        successNote("Ny mal lagt til");
        onClose(onClose);
        resetFormValues();
    } else if (response?.error) {
        toastErrorNotify(response.error.data.message);
    }
}

function resetFormValues() {
    setTeoriValue(false);
    setTestValue(false);
    setTreningValue(false);
    setRadioValue('');
}

function handleApiError(err) {
    if (err?.inner && Array.isArray(err.inner)) {
        const errorMap = {};
        err.inner.forEach((error) => {
            errorMap[error.path] = error.message;
        });
        setValidationErrors(errorMap);
    }

    const { response } = err;

    if (!response) {
        toastErrorNotify("Ingen serverrespons");
    } else if (response.data) {
        const { data } = response;
        if (data.validationErrors && data.validationErrors.length > 0) {
            toastErrorNotify(data.validationErrors[0]);
        } else if (data.message) {
            toastErrorNotify(data.message);
        } else {
            toastErrorNotify("Feilet");
        }
    }
}

  const getContent = () => (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      maxWidth="md"
    >
      {/* // ! Radio button for helse choice */}
      {!availableTemplates?.data.fysisk && !availableTemplates?.data.mental && (
        <Grid item xs={12}>
          <FormHelperText sx={{ color: "red" }}>
            {" "}
            Mental eller fysisk malene deres er ikke tilgjengelige for
            øyeblikket
          </FormHelperText>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Opprett mal for
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="fysisk"
            name="radio-buttons-group"
            row
            onChange={handleRadioChange}
            value={radioValue}
          >
            {availableTemplates?.data.fysisk && (
              <FormControlLabel
                value="fysisk"
                control={<Radio />}
                label="Fysisk helse"
              />
            )}
            {availableTemplates?.data.mental && (
              <FormControlLabel
                value="mental"
                control={<Radio />}
                label="Mental helse"
              />
            )}
            {availableTemplates?.data.forsteHjelp && (
              <FormControlLabel
                value="forstehjelp"
                control={<Radio />}
                label="Forstehjelp"
              />
            )}
          </RadioGroup>
          <FormHelperText sx={{ color: "red" }}>{helperText}</FormHelperText>
        </FormControl>
      </Grid>

      {/* // ! Input for template name*/}

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="outlined-required"
          label="Malens navn"
          onChange={handleInputChange}
          error={Boolean(validationErrors.name)}
          helperText={validationErrors.name}
        />
      </Grid>

      {/* // ! Checkbox for test/teori/trening */}
      <Grid item xs={12}>
        <FormLabel component="legend"> Vennligst velg minst én</FormLabel>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value={teoriValue}
            control={<Checkbox />}
            label="Teori"
            onChange={handleCheckboxChange1}
          />
          <FormControlLabel
            value={testValue}
            control={<Checkbox />}
            label="Test"
            onChange={handleCheckboxChange2}
          />
          <FormControlLabel
            value={treningValue}
            control={<Checkbox />}
            label="Trening"
            onChange={handleCheckboxChange3}
          />
        </FormGroup>
        {/* {error && ( */}
        <FormHelperText error>{checkBoxText}</FormHelperText>
        {/* )} */}
      </Grid>

      {/* // ! Slider 1 */}

      {templateUrl === AddTemplateFysisk && (
        <Grid item xs={12}>
          <Typography>Vennligst velg antall pasienter</Typography>
          <BasicSlider
            min={1}
            maks={10}
            step={1}
            marks
            onChange={handlePasientChange}
          />
        </Grid>
      )}

      {(treningValue || testValue) && (
        <Grid item xs={12}>
          <Typography>Krav til riktige besvarelser, i prosent</Typography>
        </Grid>
      )}

      {/* // ! Slider 2 */}

      {testValue && (
        <Grid item xs={12}>
          <FormLabel>Test</FormLabel>
          <BasicSlider
            min={0}
            maks={100}
            step={10}
            marks={testMark}
            onChange={handleGeneralSuccessChange}
          />
        </Grid>
      )}

      {/* // ! Slider 3 */}

      {treningValue && (
        <Grid item xs={12}>
          <FormLabel>Trening</FormLabel>
          <BasicSlider
            min={0}
            maks={100}
            step={10}
            marks={testMark}
            onChange={handleTestChange}
          />
        </Grid>
      )}

      {/* // ! Submit Button */}

      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          className="PrimaryButton"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? <Loading color={"#fff"} size={25} /> : "Opprett"}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Opprett ny mal"
      content={getContent()}
    />
  );
};

export default TemplatecreationModal;
