import { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Box,
  Tooltip,
  Typography,
  Link,
  Stack,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DetailModal from "../components/CourseTemplateDetails/DetailModal";
import DeleteButtonWithConfirmation from "./DeleteButtonWithConfirmation";

const BasicCourseCard = ({
  title,
  label, disabled,
  subTitle,
  onMouseOver,
  content,
  date,
  onClick,
  buttonName,
  labelColor,
  subContent,
  courseId,
  onDeleteButtonClick,
  time
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Card
        onMouseOver={onMouseOver}
        sx={{
          width: "250px",
          height: "250px",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 17,
              display: "inline-flex",
              mb: 0.5,
            }}
            color="text.secondary"
          >
            {title}
          </Typography>
            <Typography sx={{ fontSize: 14, color: "#858585", mb:0.5}}>
            {subTitle.length > 30 ? subTitle.slice(0, 30) + '...' : subTitle}
          </Typography>
          <Typography sx={{ fontSize: 12, color: "#858585" }}>
            {content.length > 35 ? content.slice(0, 35) +'...' : content}
          </Typography>
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                color: "#9e9e9e",
                my:1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {subContent.slice(0, 30)}...
            </Typography>
            <Tooltip title="Klikk og se alle kursdetaljene">
      <Link
        sx={{
          fontSize: 14,
          textDecoration: 'none',
          cursor: 'pointer',
          color: (theme)=>theme.palette.secondary.main,
        }}
        onClick={handleOpen}
      >
        Se mer info
      </Link>
    </Tooltip>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <AccessTimeIcon sx={{ color: "#bdbdbd" }} fontSize="small" />
              <Typography sx={{ fontSize: 12, color: "#bdbdbd" }}>
                {time}
              </Typography>
            </Stack>
          </Box>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <DeleteButtonWithConfirmation onDeleteConfirm={onDeleteButtonClick} variant={'contained'} disabled={disabled}/>
        </CardActions>
      </Card>
      <DetailModal onClose={() => setOpen(false)} open={open} courseId={courseId}/>
    </>
  );
};

export default BasicCourseCard;
