import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import getCookie from "./cookieUtils";
import { useAuth } from "../hooks/useAuth";
import CenteredLoading from "../layout/CenteredLoading";

const RequireAuth = () => {
  const { loading, isLoggedIn, logout, tokenExpired } = useAuth();
  const userRoles = JSON.parse(localStorage.getItem("roles"));
  const token = getCookie("token");
  // console.log(loading, isLoggedIn, tokenExpired);

  const allowedRoles = {
    super: "org.admin.super",
    assistant: "org.admin.assistant",
    courseAdmin: "org.admin.course",
    integration: "org.admin.integration",
    department: "org.admin.department",
  };

  const hasRequiredRole = userRoles?.find((role) =>
    Object.values(allowedRoles).includes(role)
  );

  useEffect(() => {
    if (!loading && (!isLoggedIn || !token)) {
      logout(); // This will handle the redirection after setting the tokenExpired state
    }
  }, [loading, isLoggedIn, logout]);

  if (tokenExpired) {
    // Show loading screen if checking auth state or token has expired
    return (
      <CenteredLoading tekst="Økten din er utløpt. Vennligst logg inn på nytt." />
    );
  }

  if (!isLoggedIn || !token) {
    // Redirect to login if not logged in
    return <Navigate to="/login" replace />;
  }

  if (!hasRequiredRole) {
    return <Navigate to="/unauthorized" />;
  }

  // Render the child routes if the user is authenticated and the token has not expired
  return <Outlet />;
};

export default RequireAuth;
