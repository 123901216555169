import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';


const CenteredLoading = ({  tekst }) => {
  return (
         <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        }}
      >
           <CircularProgress  thickness={3} sx={{color: '#5177b8'}} />
        <Typography variant="body1" color='secondary' sx={{ marginTop: 2 }}>
          {tekst}
        </Typography>
      </Box>
  );
};

export default CenteredLoading;
