import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resultContainerLoading: false,
  availableModulesLoading: false,
  miniCalendarLoading: false
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setResultContainerLoading: (state, action) => {
      return {...state, resultContainerLoading: action.payload};
    },
    setAvailableModulesLoading: (state, action) => {
      return {...state, availableModulesLoading: action.payload};
    },
    setMiniCalendarLoading: (state, action) => {
      return {...state, miniCalendarLoading: action.payload};
    }
  }
})

export const { setResultContainerLoading, setAvailableModulesLoading, setMiniCalendarLoading } = loadingSlice.actions

export default loadingSlice.reducer
