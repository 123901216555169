import { configureStore } from "@reduxjs/toolkit";
import { scheduledCourseApi } from "./ScheduledCourse/scheduledCourseApi";
import eventReducer from "./ScheduledCourse/eventSlice";
import { courseDetailsApi } from "./GetCourseDetails/getCourseDetailsApi";
import { templateApi } from "./GetCourseDetails/getTemplateDetailsApi";
import { requestOtpApi } from "./auth/login";
import { verifyOtpApi } from "./auth/verifyOtp";
import authReducer from "./auth/authSlice";
import errorReducer from "./403Error/errorSlice";
import { getAllCoursesApi } from "./Course/courseSlice";
import { getTemplateApi } from "./Template/templateSlice";
import { getModulesApi } from "./Module/moduleSlice";
import { getResultApi } from "./GeneralResults/resultSlice";
import { getAdminDetailsApi } from "./AdminUser/adminDetails";
import loadingReducer from "../redux/Dashboard/loadingSlice";
import { tourReducer } from "../reducers/guidedTourReducer";
import templatesReducer from "../reducers/templateReducer";

export const store = configureStore({
  reducer: {
    [courseDetailsApi.reducerPath]: courseDetailsApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [scheduledCourseApi.reducerPath]: scheduledCourseApi.reducer,
    [requestOtpApi.reducerPath]: requestOtpApi.reducer,
    [verifyOtpApi.reducerPath]: verifyOtpApi.reducer,
    [getAllCoursesApi.reducerPath]: getAllCoursesApi.reducer,
    [getTemplateApi.reducerPath]: getTemplateApi.reducer,
    [getModulesApi.reducerPath]: getModulesApi.reducer,
    [getResultApi.reducerPath]: getResultApi.reducer,
    [getAdminDetailsApi.reducerPath]: getAdminDetailsApi.reducer,
    auth: authReducer,
    event: eventReducer,
    error: errorReducer,
    loading: loadingReducer,
    tour: tourReducer,
    template: templatesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(courseDetailsApi.middleware)
      .concat(templateApi.middleware)
      .concat(scheduledCourseApi.middleware)
      .concat(verifyOtpApi.middleware)
      .concat(requestOtpApi.middleware)
      .concat(getAllCoursesApi.middleware)
      .concat(getTemplateApi.middleware)
      .concat(getModulesApi.middleware)
      .concat(getResultApi.middleware)
      .concat(getAdminDetailsApi.middleware)
});
