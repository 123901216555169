import { useState, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Loading from "../../../layout/Loading";
import { useGetAllCoursesQuery } from "../../../services/Course/courseSlice";

const CourseSelect = ({ handleCourseSelect, formSubmit, reset, setReset }) => {
  // const [courseList, setCourseList] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { data, isLoading } = useGetAllCoursesQuery();
  const courseList = data?.data.map((item) => ({
    id: item.id,
    name: item.courseName,
    detail: item.detail,
  }));

  useEffect(() => {
    if (reset) {
      setSelectedCourse("");
      setReset(false);
      setFormSubmitted(false);
    } else {
      setFormSubmitted(formSubmit);
    }
  }, [reset, formSubmit]);

  // ! Select Course Change Function

  const onCourseChange = (e) => {
    setSelectedCourse(e.target.value);
    handleCourseSelect(e.target.value);
  };

  return (
    <Grid item xs={6}>
      <FormControl
        variant="standard"
        fullWidth
        required
        error={formSubmitted && !selectedCourse}
      >
        <InputLabel id="course navn">Velg kurs</InputLabel>
        <Select
          value={selectedCourse}
          label="Velg modul"
          onChange={onCourseChange}
        >
          {isLoading ? (
            <MenuItem>
              <Loading size={18} color={"#858585"} />
            </MenuItem>
          ) : (
            courseList?.map((item, i) => (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            ))
          )}
        </Select>
        {formSubmitted && !selectedCourse && (
          <FormHelperText>Dette feltet er påkrevd</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default CourseSelect;
