import { useState , useEffect} from "react";
import {
  Button,
  Typography,
  Toolbar,
  AppBar,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
// import PersonIcon from "@mui/icons-material/Person";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import QuizIcon from "@mui/icons-material/Quiz";
import GuidedTour from "../helpers/GuidedTour";
import { useGuidedTour } from "../hooks/useGuidedTour";

const drawerWidth = 195;

export const classes = {
  appbar: {
    width: `calc(100% - ${drawerWidth}px)`,
    margin: 0,
    minHeight: "50px",
  },
};

const Header = ({ title, header, subTitle, headerSize, className }) => {
  const { isTourRunning: isTourOpen, toggleTour } = useGuidedTour(); 
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // This function handles the click on the QuizIcon
  const handleQuizIconClick = () => {
    setOpenSnackbar(true);
  };

  const handleStartTour = () => {
    toggleTour(); 
    setOpenSnackbar(false);
  };

  const handleCloseTour = () => {
    toggleTour(); 
  };
  

    // Use the useEffect hook to check if it's the user's first visit
    useEffect(() => {
      const firstVisit = localStorage.getItem("firstVisit");
      if (!firstVisit) {
        // If it's their first visit, open the Snackbar and set "firstVisit" in localStorage
        setOpenSnackbar(true);
        localStorage.setItem("firstVisit", "true");
      }
    }, []);


  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar
        position="fixed"
        sx={classes.appbar}
        className="appbar"
        elevation={1}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant={headerSize} component="div" className={className}>
              {header}
            </Typography>
            <Typography variant="body2" component="div">
              {subTitle}
            </Typography>
          </Box>
          <Button color="inherit" onClick={handleQuizIconClick}>
            <QuizIcon />
          </Button>
          <Snackbar
            open={openSnackbar}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleSnackbarClose} severity="info">
              <Typography sx={{ fontSize: 14 }}>
                Velkommen til Dr. Cloud!👋
              </Typography>
              <Typography sx={{ my: 1, fontSize: 13 }}>
                Trykk på knappen for å få en guidet omvisning av vårt
                LMS-system!
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  onClick={handleStartTour}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "10px",
                    color: "#fff",
                  }}
                >
                  Start omvisning
                </Button>
              </Box>
            </Alert>
          </Snackbar>
          {/* <Button
            color="inherit"
            onClick={() => navigate("/profile")}
            sx={{ width: "10px" }}
          >
            <PersonIcon />
          </Button> */}
          {/* <Button color="inherit" onClick={handleClick}>
            <NotificationsIcon />
          </Button> */}
          {/* <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose}   open={open} severity="info" sx={{ width: "100%" }}>
              Denne funksjonen er under utvikling.
            </Alert>
          </Snackbar> */}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <GuidedTour isTourOpen={isTourOpen} handleCloseTour={handleCloseTour} />
    </Box>
  );
};

export default Header;
