import { AppRouter } from "./app-router/AppRouter";
import { ToastContainer } from "react-toastify";
import './index.css';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import theme from './theme';
import { ThemeProvider} from '@mui/material/styles';
import {useAuth} from "./hooks/useAuth"

const App = () => {
  const {  isLoggedIn } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <main className='container'>
         <AppRouter isLoggedIn={isLoggedIn}/>
      <ToastContainer />
      </main>
      </ThemeProvider>
  );
};

export default App;
