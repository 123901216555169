
export const selectTemplateDetails = (moduleId, templateDetails) => ({
    type: 'SELECT_TEMPLATE_DETAILS',
    payload: { moduleId, templateDetails }
});

export const resetTemplateDetails = (moduleId) => ({
    type: 'RESET_TEMPLATE_DETAILS',
    payload: moduleId
});

