import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DashboardResultAsDepAdmin, DashboradResultAsUpperAdmin } from '../../api/endpoints';
import getCookie from "../../utils/cookieUtils";
import { setResultContainerLoading } from '../../redux/Dashboard/loadingSlice';

export const getResultApi = createApi({
  reducerPath: 'getResultApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const token = getCookie('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getGeneralResultAsUpper: builder.query({
      query: () => ({ url: DashboradResultAsUpperAdmin, method: 'GET' }),
      providesTags: [{ type: 'GeneralResulAsUpper', id: 'GET' }],
      onQueryStarted: (arg, { dispatch, getState, queryFulfilled }) => {
        dispatch(setResultContainerLoading(true));
        queryFulfilled.then(() => {
          dispatch(setResultContainerLoading(false));
        });
      },
    }),
    getGeneralResultAsDep: builder.query({
      query: () => ({ url: DashboardResultAsDepAdmin, method: 'GET' }),
      providesTags: [{ type: 'GeneralResulAsDepAdmin', id: 'GET' }],
      onQueryStarted: (arg, { dispatch, getState, queryFulfilled }) => {
        dispatch(setResultContainerLoading(true));
        queryFulfilled.then(() => {
          dispatch(setResultContainerLoading(false));
        });
      },
    }),
  }),
});

export const { useGetGeneralResultAsUpperQuery, useGetGeneralResultAsDepQuery } = getResultApi;
