import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function ModuleCard({ moduleData, handleOpen}) {
  return (
    <Card sx={{ maxWidth:200 }}>
      <CardActionArea  onClick={() => handleOpen(moduleData)}>
        <CardMedia
          component="img"
          height="140"
          image={moduleData.image} 
          alt={moduleData.name} 
        />
        <CardContent>
    <Typography sx={{fontWeight:600, color:"#616161"}}>
        {moduleData.name}
    </Typography>
        <Typography sx={{fontSize:13, mb:0.5, color:'#858585'}}>
            {moduleData.moduleType} helse
        </Typography>
        <div style={{display: 'flex', justifyContent: "flex-end", alignItems:'center'}}>
            <AccessTimeIcon fontSize='12' sx={{mr:0.5, color:'#858585'}}/>
            <Typography sx={{fontSize:13, color:'#858585'}}>
                {moduleData.totalEstimatedTime} min
            </Typography>
    </div>
</CardContent>

      </CardActionArea>
      <CardActions sx={{ justifyContent: "center", mb:1 }}>
       <Button size="small" className='PrimaryButton' onClick={() => handleOpen(moduleData)}>
  Vis
</Button>
      </CardActions>
    </Card>
  );
}
