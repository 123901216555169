
export const validateCourseStep = (formData) => {
    let errors = {};
    if (!formData.courseName) {
        errors.courseName = "Kursnavn er påkrevd";
    }
    if (formData.courseName?.length > 25) {
        errors.courseName = "Kursnavnet kan ikke være mer enn 25 tegn";
    }    
    if (!formData.courseDetails) {
        errors.courseDetails = "Detaljer er påkrevd";
    }
    if (formData.courseDetails?.length < 4) {
        errors.courseDetails = "Beskrivelsen må være minst 4 tegn";
    }
    if (formData.courseDetails?.length > 150) {
        errors.courseDetails = "Beskrivelsen kan ikke være mer enn 150 tegn";
    }
    return errors;
}

export const validateModuleStep = (selectedModules) => {
    let errors = {};
    if (selectedModules?.length === 0) {
        errors.module = "Vennligst velg en modul";
    } else if (selectedModules?.length > 4) {
        errors.module = "Maksimalt kan du velge 4 moduler. Vennligst fjern overflødige moduler.";
    }    
    return errors;
}


export const validateTemplateStep = (selectedTemplatesFromRedux, activeModuleIndex) => {
    let errors = {};
    // chech if activeModuleIndex is an integer
    if (typeof activeModuleIndex === 'number' && !selectedTemplatesFromRedux[activeModuleIndex]) {
        errors[`template_${activeModuleIndex}`] = `Vennligst velg en mal for modul ${activeModuleIndex}`;
    }
    return errors;
};




