import React from 'react'
import { Typography, Modal, Grid} from '@mui/material';

const modalStyles = {
  wrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 1000,
      bgcolor: 'background.paper',
      boxShadow: 30,
      borderRadius:'5px',
      p: 3,
  },
};

const BasicModal = ({ open, onClose, title, subTitle, content, onSubmit }) => {

    return (
        <Modal open={open} onClose={onClose} >
            <Grid sx={modalStyles.wrapper} >
                <Typography
                    variant="h6"
                    sx={{mb:2, color:'#424242'}}
                >
                    {title}
                </Typography>
                <Typography>
                    {subTitle}
                </Typography>
                {content}
            </Grid>
        </Modal>
    )
}

export default BasicModal