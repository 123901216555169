import React, {useState} from 'react'
import {
  Box,
  TextField,
  Grid,
} from "@mui/material";

const CourseDescModal = ({errors, setErrors, formData, setFormData}) => {
  const [courseName, setCourseName] = useState(formData.courseName || "");
  const [courseDetails, setCourseDetails] = useState(formData.courseDetails || "");


    // ! Handle Input Change

    const handleInputChange = (e) => {
      setCourseName(e.target.value);
      setFormData(prevState => ({
        ...prevState,
        courseName: e.target.value
      }));
    };
  
    const handleDetailChange = (e) => {
      setCourseDetails(e.target.value);
      setFormData(prevState => ({
        ...prevState,
        courseDetails: e.target.value
      }));
    };
  

  return (
    <Box
    component="form"
    noValidate
    sx={{ mt: 1 }}
  >
     <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        maxWidth="lg"
      >
          <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="name"
            id="outlined-required"
            label="Kursets navn"
            value={courseName}
            onChange={handleInputChange}
            error={Boolean(errors.courseName)} 
            helperText={errors.courseName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            multiline
            name="detail"
            maxRows={7}
            id="outlined-required"
            label="Kursbeskrivelse"
            value={courseDetails}
            onChange={handleDetailChange}
            error={Boolean(errors.courseDetails)}
            helperText={errors.courseDetails}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CourseDescModal