import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetCourseDetails } from '../../api/endpoints';
import getCookie from "../../utils/cookieUtils"


export const courseDetailsApi = createApi({
  reducerPath: 'courseDetailsApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: '/', // set base url for your api
    prepareHeaders: (headers) => {
      const token = getCookie('token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
    },
  }),
  endpoints: (builder) => ({
    getCourseDetails: builder.query({
      query: (courseId) => ({ url: GetCourseDetails, method: 'POST', body: { courseId } }),
    }),
  }),
})

export const { useGetCourseDetailsQuery } = courseDetailsApi;
