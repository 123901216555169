import { useState, React } from "react";
import "../App.css";
import axios from "axios";
import { PasswordResetUrl } from "../api/endpoints";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { successNote, toastErrorNotify } from "../helpers/toastNotify";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography, Container,
  IconButton,
  InputAdornment,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Copyright from "../components/LoginComponents/Copyright";
import * as Yup from "yup";
import Loading from "../layout/Loading";


export default function PasswordResetPage() {
  const [paramaters] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const yupErrors = {};
  const [validationErrors, setValidationErrors] = useState({});
  const authBackgroundImage = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381778/Making%20View%20Assets/auth_background_texcon.jpg'
  const logoRed =  'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381822/Making%20View%20Assets/MV_logo_red_black_ctm6tn.png'


  const validationSchema = Yup.object({
    password1: Yup.string()
    .required("Passord er påkrevd")
    .matches(
    /\w*[A-Z]\w*/,
    "Passordet ditt må inneholde minst en stor bokstav."
    )
    .matches(/\w*\d\w*/, "Passordet ditt må inneholde minst ett tall")
    .matches(/^.{8,}$/, "Passordet ditt må være minst 8 tegn")
    .matches(
      /(?=.*[!@#$%^&*()-_=+{};:,<.>])/,
      "Passordet må inneholde minumum et spesialtegn"
    ),
    password2: Yup.string()
    .required("Bekreft passordet ditt")
    .oneOf([Yup.ref("password1")], "Passordene matcher ikke"),
    });

  const [values, setValues] = useState({
    password1: "",
    password2: "",
    showPassword1: false,
    showPassword2: false,
  });

  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };

  const handleClickShowPassword2 = () => {
    setValues({
      ...values,
      showPassword2: !values.showPassword2,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onResetSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.currentTarget);
    const initialValues = {
      password1: data.get("password1"),
      password2: data.get("password2"),
    };

    const email = paramaters.get("email");
    const code = paramaters.get("registrationKey");
  

    try {
      await validationSchema.validate(initialValues, { abortEarly: false });
      //! If validation succeeds, proceed with form submission
     await axios.post(PasswordResetUrl, {
        email,
        password1: data.get("password1"),
        password2: data.get("password2"),
        code,
      });
      successNote("Password reset successfully.");
      navigate("/login");
    } catch (err) {
      console.log(err);
      // console.log(err.response.data.data?.validationErrors);
      //! If validation fails, display error messages
      if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      } else if (!err?.response) {
        toastErrorNotify("Ingen serverrespons");
      } else if (err.response.data?.data) {
        toastErrorNotify(err.response.data.data?.validationErrors[0]);
      } else if (err.response.data.data === null) {
        toastErrorNotify(err.response.data?.message);
      } else {
        toastErrorNotify("En feil oppstod");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
  container
  component="main"
  sx={{
    backgroundImage: `url(${authBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "120vh",
  }}
>
  <Container component="main" maxWidth="xs">
    <Box
      sx={{
        marginTop: 8,
        backgroundColor: "rgba(255,255,255, 0.8)",
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 5,
      }}
    >
          <img src={logoRed} alt="Logo" className="logoLogin" />
      <Typography component="h1" variant="h5">
      Sett nytt passord
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={onResetSubmit}
        sx={{ mt: 3 }}
      >
        <Grid container>
          <Grid item sm={12}>
          <TextField
                margin="normal"
                required
                fullWidth
                name="password1"
                label="Passord"
                type={values.showPassword1 ? "text" : "password"}
                id="password1"
                error={Boolean(validationErrors.password1)}
                helperText={validationErrors.password1}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      {
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword1 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      }
                    </InputAdornment>
                  ),
                }}
              />
          </Grid>
          <Grid item sm={12}>
          <TextField
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Gjenta passord"
                type={values.showPassword2 ? "text" : "password"}
                id="password2"
                error={Boolean(validationErrors.password2)}
                helperText={validationErrors.password2}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      {
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword2 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      }
                    </InputAdornment>
                  ),
                }}
              />
          </Grid>
          <Grid item sm={12}>
          <Button
          className="PrimaryButton"
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? <Loading color={"#fff"} size={25} /> : "Sett passord"}
        </Button>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 1 }} />
      </Box>
    </Box>
  </Container>
</Grid>
  );
}
