import React from 'react'
import {
  Card,
  Chip, Grid,
  CardActionArea,
  CardContent, Box,
  Typography
} from "@mui/material";
import { useGetMentalTemplatesQuery, useGetFysiskTemplatesQuery, useGetForstehjelpTemplatesQuery } from "../../../../../services/Template/templateSlice";
import CreateTemplateButton from '../../../TemplateTab/CreateTemplateButton';
import Loading from '../../../../../layout/Loading';
import { CheckCircle } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { selectTemplateDetails } from '../../../../../actions/templateActions';
import { getModuleTypeColor } from "../../../../../utils/displayUtils";
import { moduleNameFromType } from "../../../../../utils/displayUtils";

const TemplateCard = ({ moduleType, selectedTemplateId, activeModuleIndex, moduleId }) => {
  const { data: mentalTemplates, isLoading: mentalTempLoading } = useGetMentalTemplatesQuery();
  const { data: fysiskTemplates, isLoading: fysiskTempLoading} = useGetFysiskTemplatesQuery();
  const { data: forstehjelpTemplates, isLoading: forstehjelpTempLoading } = useGetForstehjelpTemplatesQuery();
  const dispatch = useDispatch();

  let templates;
  
    if (moduleType === "Mental") {
        templates = mentalTemplates;
    } else if (moduleType === "Fysisk") {
        templates = fysiskTemplates;
    } else {
        templates = forstehjelpTemplates;
    }

  const handleSelectTemplate = (moduleId, templateDetails) => {
    dispatch(selectTemplateDetails(moduleId, templateDetails));
};

  return (
      <Grid container spacing={1.5} sx={{ overflowY: "auto", maxHeight: "500px" }}>
          {
           mentalTempLoading || fysiskTempLoading || forstehjelpTempLoading ?
           <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{height:'300px', width:'500px'}}>
          <Loading size={30} color={"#5177b8"}/> 
          <Typography sx={{mt:2}} variant="body2" color='secondary'> Laster inn malene…</Typography>
      </Box> : (
        <>
          <Grid item xl={3} lg={4} md={5} sm={6}>
        <CreateTemplateButton fontHeader={12} width={190} height={180}/>
        </Grid>
        {templates?.data.map((template, index) => (
        <Grid item key={index} sx={{mb:2}} xl={3} lg={4} md={5} sm={6}>
        <Card sx={{ width:180, height:180 }}>
          
        <CardActionArea onClick={() => handleSelectTemplate(activeModuleIndex, {
            moduleId: moduleId,
            patientCount: template.patientCount,
            testApprovalPercentage: template.testApprovalPercentage,
            generalSuccessPercentage: template.generalSuccessPercentage,
            trainingIsActive: template.trainingIsActive,
            testIsActive: template.testIsActive,
            theoryIsActive: template.theoryIsActive,
            name: template.name,
            id:template.templateId,
        })}>
          <CardContent>
    <Chip 
        label={`${moduleNameFromType(moduleType)}`}  
        sx={{ 
            backgroundColor: `${getModuleTypeColor(moduleType) }`, 
            color: '#fff', 
            fontSize: 11,
        }} 
    />
        <Typography sx={{ fontWeight: 600, color: "#616161", my: 1, fontSize: 13 }}>
        {template.name}
    </Typography>
    <Typography variant="caption" display="block" gutterBottom>
  Mal: {template.trainingIsActive ? "Trening  " : ""}{template.testIsActive ? "Test  " : ""}{template.theoryIsActive ? "Teori" : ""}
</Typography>
    <Typography variant="caption"  sx={{ whiteSpace: "pre-line", fontSize:11, color: "#757575" }}>
    {template.trainingIsActive && 
        `Krav til godkjent trening: ${template.generalSuccessPercentage}%\n`
    }
    {template.testIsActive &&
        `Krav til godkjent test: ${template.testApprovalPercentage}%\n`
    }
    {template.patientCount && 
        `Antall pasienter: ${template.patientCount}`
    }
</Typography>
</CardContent>

{selectedTemplateId === template.templateId && (
    <CheckCircle color="success" sx={{ position: 'absolute', bottom: 5, right: 5 }} />
)}

          </CardActionArea>
        </Card>
        </Grid>
      ))}
        </>
      )
        }
    </Grid>
  )
}

export default TemplateCard;
