import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Popper,
  MenuList,
  Grow,
  ClickAwayListener,
  MenuItem,
  Paper,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetAllCourses } from "../../../api/endpoints";
import apiClient from "../../../api/apiClient";

const CourseFilter = ({onSelectedCourseChange}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  // console.log('selected Courses', selectedCourses)

  // ! Get All Course List
  async function getAllCourses() {
    setLoading(true);
    try {
      const response = await apiClient.get(GetAllCourses);
      setData(response.data.data);
      setDataFetched(true);
    } catch (err) {
      console.log(err.response.data);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await getAllCourses();
    }
  
    fetchData();
  }, []);
  
  useEffect(() => {
    if (dataFetched) {
      if (selectedCourses.length === 0) {
        const allCourseIds = data.map((course) => course.id);
        onSelectedCourseChange(allCourseIds);
      } else {
        onSelectedCourseChange(selectedCourses);
      }
    }
  }, [selectedCourses, dataFetched]);
  
  
  
  

  const handleCourseSelect = (id) => {
    if (selectedCourses.includes(id)) {
      setSelectedCourses(
        selectedCourses.filter((courseId) => courseId !== id)
      );
    } else {
      setSelectedCourses([...selectedCourses, id]);
    }
  };

  const handleCourseSelectAll = () => {
    const allCourses = data.map((item) => item.id);
    setSelectedCourses(allCourses);
  };

  const handleDepartmentClearAll = () => {
    setSelectedCourses([]);
  };

  // ! Button Functions

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <>
      <Button
        // variant="outlined"
        className="FilterButton"
        endIcon={<ExpandMoreIcon />}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          mx: 3,
          px: 2,
          "@media (max-width: 600px)": {
            mx: 1,
            px: 1,
          },
          "@media (max-width: 400px)": {
            mx: 0,
            px: 0,
          },
          // Truncate text and add ellipsis when it overflows
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Kurs
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: "tooltip" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    key="select-all"
                    onClick={handleCourseSelectAll}
                  >
                    Velg alle
                  </MenuItem>
                  {data?.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => handleCourseSelect(item.id)}
                      selected={selectedCourses.includes(item.id)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                  <MenuItem key="clear-all" onClick={handleDepartmentClearAll}>
                    <ClearIcon
                      fontSize="small"
                      sx={{ color: "#858585", marginRight: 1 }}
                    />
                    Fjern alle
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default CourseFilter;
