import React, { useState, useEffect } from "react";
import { allModuleData } from "./allModuleData";
import ModuleCard from "./ModuleCard";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import {
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  CardActions,
  DialogContentText,
  Button,
  CardMedia,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {useMediaQuery} from "@mui/material";

export default function Carousel() {
  const [open, setOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const navigate = useNavigate()
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(isMediumScreen ? 3 : 4);

  const prevCard = () => {
    if (start > 0) {
      setStart(start - 1);
      setEnd(end - 1);
    }
  };

  const nextCard = () => {
    if (end < allModuleData.length) {
      setStart(start + 1);
      setEnd(end + 1);
    }
  };

  const handleOpen = (moduleData) => {
    setSelectedModule(moduleData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigate = () => {
    setOpen(false);
    navigate('/kontakt',  { state:{selectedModule} })
  };

  useEffect(() => {
    setEnd(isMediumScreen ? 3 : 4);
  }, [isMediumScreen]);


  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={0.8}>
        <IconButton onClick={prevCard}>
          <ArrowBackIos fontSize="small" />
        </IconButton>
      </Grid>

      {allModuleData.slice(start, end).map((moduleData, index) => (
        <Grid item xs={3.5} sm={3.5} md={2.5} lg={2.5} xl={2.5} key={index}>
          <ModuleCard moduleData={moduleData} handleOpen={handleOpen} />
        </Grid>
      ))}

      <Grid item xs={0.1}>
        <IconButton onClick={nextCard}>
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </Grid>

      {/* Dialog Component */}

      <Dialog open={open} onClose={handleClose}>
        {selectedModule && (
          <>
            <DialogTitle
              sx={{
                fontWeight: 600,
                color: "#616161",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {selectedModule.name} Modul
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <DialogContentText>
                  {selectedModule.moduleType} helse
                </DialogContentText>
                <DialogContentText
                  sx={{
                    fontSize: 15,
                    mb: 0.5,
                    color: "#858585",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                  {selectedModule.totalEstimatedTime} min
                </DialogContentText>
              </div>
              <CardMedia
                component="img"
                height="140"
                image={selectedModule.image}
                alt={selectedModule.name}
                sx={{ my: 1.5 }}
              />
              <DialogContentText>
                {selectedModule.moduleDetail}
              </DialogContentText>
            </DialogContent>
            <CardActions sx={{ mb: 2, mr: 1, justifyContent: "center" }}>
              <Button
                onClick={handleClose}
                size="small"
                variant="outlined"
                className="SecondaryButton"
              >
                Avbryt
              </Button>
              <Button
                size="small"
                className="PrimaryButton"
                onClick={handleNavigate}
              >
                Ta kontakt
              </Button>
            </CardActions>
          </>
        )}
      </Dialog>

    </Grid>
  );
}
