import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetMentalTemplateDetails, GetFysiskTemplateDetails } from '../../api/endpoints';
import getCookie from "../../utils/cookieUtils"


export const templateApi = createApi({
    reducerPath: 'templateApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            const token = getCookie('token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchTemplate: builder.query({
            query: ({templateId, moduleType}) => {
                let endpoint = "";
                if (moduleType === "Mental") {
                    endpoint = GetMentalTemplateDetails;
                } else if (moduleType === "Fysisk") {
                    endpoint = GetFysiskTemplateDetails;
                }
                return { url: endpoint, params: { templateId } }
            },
        }),
    }),
})

export const { useFetchTemplateQuery } = templateApi;