import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
  } from 'date-fns';
      
      const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
        startOfLast6Months: startOfMonth(addMonths(new Date(), -6)),
  endOfLast6Months: endOfDay(new Date()),
      };
      
      const staticRangeHandler = {
        range: {},
        isSelected(range) {
          const definedRange = this.range();
          return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
          );
        },
      };
      
      export function createStaticRanges(ranges) {
        return ranges.map(range => ({ ...staticRangeHandler, ...range }));
      }
      
      export const defaultStaticRanges = createStaticRanges([
        {
          label: 'Siste 7 dager',
          range: () => ({
            startDate: addDays(new Date(), -7),
            endDate: endOfDay(new Date(), ),
          }),
        },
        {
          label: 'Siste 14 dager',
          range: () => ({
            startDate: addDays(new Date(), -14),
            endDate: endOfDay(new Date()),
          }),
        },
        {
          label: 'Siste 30 dager',
          range: () => ({
            startDate: addDays(new Date(), -30),
            endDate: endOfDay(new Date()),
          }),
        },
        {
          label: 'Siste 90 dager',
          range: () => ({
            startDate: addDays(new Date(), -90),
            endDate: endOfDay(new Date()),
          }),
        },
      ]);
      
      
 