import React from "react";
import UserListUpperAdmin from "../components/EmployeeComponents/UserListUpperAdmin";
import UserListDepartmentAdmin from "../components/EmployeeComponents/UserListDepartmenAdmin";
import Header from '../layout/Header'

const EmployeePage = () => {
  const role = localStorage.getItem("roles");
  const roleFromLocal = JSON.parse(role);
  let renderFlag = false;

  return (
    <main>
      <Header header={'Ansatte'} headerSize={'h6'} className={'ansatte'}/>
      {roleFromLocal?.map((role, i) => {
        if (
          (role === "org.admin.super" ||
            role === "org.admin.course" ||
            role === "org.admin.assistant" || role === 'org.admin.integration') &&
          !renderFlag
        ) {
          renderFlag = true;
          return <UserListUpperAdmin key={i} />;
        } else if (role === "org.admin.department") {
          renderFlag = true;
          return <UserListDepartmentAdmin key={i} />;
        }
        return null;
      })}
    </main>
  );
};

export default EmployeePage;
