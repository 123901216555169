import { React} from "react";
import AddCourseButton from "./AddCourseButton";
import { Grid } from "@mui/material";
import { successNote, toastErrorNotify } from "../../../helpers/toastNotify";
import BasicCourseCard from "../../../layout/BasicCourseCard";
import { useGetAllCoursesQuery, useDeleteCourseMutation} from "../../../services/Course/courseSlice";
import CenteredLoading from "../../../layout/CenteredLoading";

const CourseTab = () => {
  const role = localStorage.getItem("roles");
  const roleFromLocal = JSON.parse(role);
  const { data, isLoading} = useGetAllCoursesQuery();

  //! Sort courses by newest first
  const courseList = data?.data.slice().reverse();


//! Delete Course
const [deleteCourse,] = useDeleteCourseMutation();

async function deleteCourses(courseId) {
  try {
    await deleteCourse(courseId).unwrap();
    successNote("Kurset er slettet");
  } catch (err) {
    if (err) {
       if (err?.response?.data?.data) {
        toastErrorNotify(err?.response.data.data?.validationErrors[0]);
      }else if (err?.data?.message) {
        toastErrorNotify(err?.data?.message);
      }else if (err.response.data.data === null) {
        toastErrorNotify(err.response.data?.message);
      }else if(!err.response){
        toastErrorNotify("Ingen serverrespons");
      } else {
        toastErrorNotify("Mislyktes");
      }
    }
  }
}

  return (
    <Grid container alignItems="center">
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        maxWidth="lg"
      >
        {
          isLoading ? (
            <CenteredLoading tekst={'Laster inn…'}/>
          ) : (
            <>
            {/* // ! Get All Cards */}
            <Grid item>
            <AddCourseButton/>
            </Grid>
            {courseList?.map((data, index) => (
              <Grid item key={index}>
                <BasicCourseCard
                  title={
                    data.courseName?.length > 80
                      ? data.courseName?.slice(0, 80).charAt(0).toUpperCase() +
                        data.courseName?.slice(0, 80).slice(1) +
                        "..."
                      : data.courseName?.charAt(0).toUpperCase() + data.courseName?.slice(1)
                  }
                  content={`Modul: ${data.modules
                      .map((module) => module.moduleName)
                      .join(", ")
                  }`}
                  subTitle={`Opprettet av: ` + data.courseOwner}
                  subContent={`${
                    data.detail.charAt(0).toUpperCase() + data.detail.slice(1)
                  }`}
                  onDeleteButtonClick={()=>deleteCourses(data.id)}
                  courseId={data.id}
                  time={
                    data?.estimatedTime
                      ? data?.estimatedTime < 60
                        ? `${data?.estimatedTime} min`
                        : data?.estimatedTime % 60 === 0
                        ? `${Math.floor(data?.estimatedTime / 60)} timer`
                        : `${Math.floor(data?.estimatedTime / 60)} timer ${
                            data?.estimatedTime % 60
                          } min`
                      : ""
                  }
                  disabled={
                    roleFromLocal.includes('org.admin.department')
                      ? !data.isDeletableByAdmin
                      : null
                  }
                  
                />
              </Grid>
            ))}
          </>
          )
        }
      </Grid>
    </Grid>
  );
};

export default CourseTab;