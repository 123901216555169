import { useState, useEffect } from "react";
import { Paper, TextField, Grid, Typography, Box, Button } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { ContactUs } from "../api/endpoints";
import axios from "axios";
import { successNote, toastErrorNotify } from "../helpers/toastNotify";
import CenteredLoading from "../layout/CenteredLoading";
import Loading from "../layout/Loading";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { useGetAdminDetailsQuery } from "../services/AdminUser/adminDetails";
import Header from '../layout/Header'
// import ReCAPTCHA from "react-google-recaptcha";

const KontaktPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // const [reCaptcha, setReCaptcha] = useState(null);
  const location = useLocation();
  const module = location.state;
  const {data, isLoading}=useGetAdminDetailsQuery()

  const [userName, setUserName] = useState(
    module?.selectedModule ? `${data?.data?.name || ""} ${data?.data?.surname || ""}` : ""
  );  
  const [companyName, setCompanyName] = useState(module?.selectedModule ? data?.data?.companyName || "" : "");
  const [phoneNum, setPhoneNum] = useState(module?.selectedModule ? data?.data?.phone || "" : "");
  const [email, setEmail] = useState(module?.selectedModule ? data?.data?.email || "" : "");
  const [message, setMessage] = useState(
    module ? `Hei, vi er interessert i å prøve ut modulen "${module?.selectedModule.name}". Jeg ser frem til muligheten for å diskutere dette videre med dere.` : ""
  );
  const [loadingAdminInfo, setLoadingAdminInfo] = useState(true);

  useEffect(() => {
    if (data) {
      if (module?.selectedModule) {
        setUserName(`${data?.data?.name || ""} ${data?.data?.surname || ""}`);
        setCompanyName(data?.data?.companyName || "");
        setPhoneNum(data?.data?.phone || "");
        setEmail(data?.data?.email || "");
      }
      setLoadingAdminInfo(false);  // Data lastet, skjul loading-tilstand
    }
  }, [data, module?.selectedModule]);
  
  
  const schema = yup.object().shape({
    userName: yup.string().required("Navn er påkrevd"),
    companyName: yup.string().required("Firma er påkrevd"),
    phoneNum: yup
      .string()
      .matches(/^(0047|\+47|47)?[2-9]\d{7}$/, "Ugyldig mobilnummer")
      .required("Mobilnummer er påkrevd"),
    email: yup
      .string()
      .email("Ugyldig e-postadresse")
      .required("E-post er påkrevd"),
    message: yup
      .string()
      .min(20, "Meldingen må være minst 20 tegn")
      .required("Melding er påkrevd"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const resetForm = () => {
      setUserName("");
      setCompanyName("");
      setPhoneNum("");
      setMessage("");
      setEmail("");
      setErrors({});
    };

    // Check if reCAPTCHA is completed
    // if (!reCaptcha) {
    //   setLoading(false);
    //   toastErrorNotify("Please complete the reCAPTCHA verification");
    //   return;
    // }

    try {
      await schema.validate(
        {
          userName,
          companyName,
          phoneNum,
          email,
          message,
        },
        { abortEarly: false }
      );

      const response = await axios.post(ContactUs, {
        nameSurname: userName,
        email,
        phone: phoneNum,
        message,
        companyName,
        reCAPTCHA: "string",
      });
      successNote("E-post er sendt");
      resetForm();
      // Reset the reCAPTCHA state
      // setReCaptcha(null);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err?.inner) {
        err?.inner?.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else if (!err?.response) {
        toastErrorNotify("No server response");
      } else if (err.response.data?.data) {
        toastErrorNotify(err?.response.data.data?.validationErrors[0]);
      } else if (err.response.data.data === null) {
        toastErrorNotify(err.response.data?.message);
      } else {
        toastErrorNotify("Mislyktes");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <Header header={'Kontakt oss'} headerSize={'h6'} className={'kontakt-oss'}/>
      {
               loadingAdminInfo ?
               <CenteredLoading tekst={'Laster inn…'}/> : (
                <Paper sx={{ m: 4, p: 3 }}>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    maxWidth="md"
                  >
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="fullname"
                        autoComplete="name"
                        label="Navn"
                        value={userName}
                        onChange={(event) => setUserName(event.target.value)}
                        error={Boolean(errors.userName)}
                        helperText={errors.userName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="company name"
                        label="Firma"
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                        error={Boolean(errors.companyName)}
                        helperText={errors.companyName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="number"
                        label="Mobilnummer"
                        autoComplete="tel"
                        value={phoneNum}
                        onChange={(event) => setPhoneNum(event.target.value)}
                        error={Boolean(errors.phoneNum)}
                        helperText={errors.phoneNum}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        autoComplete="email"
                        id="email"
                        label="E-post"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        multiline
                        minRows={5}
                        maxRows={15}
                        id="outlined-required"
                        label="Melding"
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        error={Boolean(errors.message)}
                        helperText={errors.message}
                      />
                    </Grid>
        
                    {/* <Grid item xs={12} align="center">
                      <ReCAPTCHA
                        sitekey="6LdUF0AlAAAAAGHpoXDscOHgKceBi39_TCU9Cq1A"
                        onChange={(value) => setReCaptcha(value)}
                      />
                    </Grid> */}
        
                    <Grid item display="flex" justifyContent="center" xs={12}>
                      <Button
                        variant="contained"
                        className="PrimaryButton"
                        sx={{ height: 40, width: 200 }}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <Loading color={"#fff"} size={25} x /> : "Send"}
                      </Button>
                    </Grid>
        
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 1 }}>
                      <Typography variant="subtitle1" align="center">
                        <FmdGoodIcon className="container-icon" />
                      </Typography>
                      <Typography variant="subtitle1" align="center">
                        Adresse
                      </Typography>
                      <Typography variant="body1" align="center">
                        Grønnegata 83-85 2317 Hamar
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 1 }}>
                      <Typography variant="subtitle1" align="center">
                        <LocalPostOfficeIcon className="container-icon" />
                      </Typography>
                      <Typography variant="subtitle1" align="center">
                        E-post
                      </Typography>
                      <Typography variant="body1" align="center">
                        <a href="mailto:post@makingview.com">post@makingview.com</a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
               )
      }
    </main>
  );
};

export default KontaktPage;
