import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CoursesWithModules, DeleteCourses, AddCourses } from '../../api/endpoints';
import getCookie from "../../utils/cookieUtils";

export const getAllCoursesApi = createApi({
  reducerPath: 'getAllCoursesApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const token = getCookie('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    tagTypes: ['Courses'],  
  }),
  endpoints: (builder) => ({
    getAllCourses: builder.query({
      query: () => ({ url: CoursesWithModules,  method: 'GET'}),
      providesTags: [{ type: 'Courses', id: 'GET' }],
    }),
    deleteCourse: builder.mutation({
      query: (courseId) => ({ 
        url: DeleteCourses, 
        method: 'POST',
        body: { courseId },
      }),
      // This will cause the 'getAllCourses' query data to be refetched
      // whenever this mutation succeeds
      invalidatesTags: [{ type: 'Courses', id: 'GET' }],
    }),
    addCourse: builder.mutation({
      query: (newCourseData) => ({
        url: AddCourses,
        method: 'POST',
        body: newCourseData,
      }),
      invalidatesTags: [{ type: 'Courses', id: 'GET' }],
    }),
  }),
})

export const { useGetAllCoursesQuery, useDeleteCourseMutation, useAddCourseMutation } = getAllCoursesApi;
