import { useState, React } from "react";
import { Button, Grid, Dialog, Box, IconButton } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import MultiStepForm from "./CourseCreationForm/MultiStepForm/MultiStepForm"; 
import CloseIcon from '@mui/icons-material/Close'; 
import {LogoutConfirmationModal} from '../../LoginComponents/Logout' 
import { resetTemplateDetails } from "../../../actions/templateActions";
import { useDispatch } from "react-redux";


const AddCourseButton = () => {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const dispatch = useDispatch();
  const [activeModuleIndex, setActiveModuleIndex] = useState(0);

  return (
    <Grid item>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "secondary",
          fontSize: 18,
          width: "250px", height: "240px",
          padding: 0,
        }}
      >
        Opprett nytt kurs
        <AddCircleOutline sx={{ color: "secondary", fontSize: 40 }} />
      </Button>

      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        aria-labelledby="multi-step-form-dialog"
        aria-describedby="multi-step-form-description"
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='lg'
      >
        <Box
    sx={{
      position: 'relative', 
      // width:900,
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius:'8px',
      p: 4,
    }}
  >
    <IconButton
      aria-label="close"
      onClick={() =>{setConfirmationOpen(true)}}
      sx={{
        position: 'absolute',
        right: 8, 
        top: 8, 
      }}
    >
      <CloseIcon />
    </IconButton>
    <MultiStepForm onSuccess={() => setOpen(false)}   activeModuleIndex={activeModuleIndex} 
      setActiveModuleIndex={setActiveModuleIndex}/>

  </Box>
  </Dialog>
  <LogoutConfirmationModal 
    open={confirmationOpen} 
    onClose={() => setConfirmationOpen(false)} 
    onConfirm={() => {
        setConfirmationOpen(false);
        setOpen(false);
        dispatch(resetTemplateDetails(activeModuleIndex));
    }}
    title="Bekreftelse"
    description="Er du sikker på at du vil avbryte kursopprettelsen?"
/>
    </Grid>
  );
};

export default AddCourseButton;
