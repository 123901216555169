import React from "react";
import {
  Card,
  Chip,
  CardActions,
  Stack,
  CardContent,
  Typography,
} from "@mui/material";
import DeleteButtonWithConfirmation from "./DeleteButtonWithConfirmation";


const BasicTemplateCard = ({
  title,
  label,
  subTitle,
  onMouseOver,
  content,
  date,
  onClick,
  buttonName,
  labelColor,
  subContent,
  isDefault, onDeleteButtonClick
}) => {
  return (
    <Card
      variant="outlined"
      onMouseOver={onMouseOver}
      sx={{
        width: "250px",
        height: "250px",
        display: 'flex', pb:2,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContent>
        {label ? (
          <Stack direction="row" spacing={6}>
            <Chip label={label} sx={{ backgroundColor: `${labelColor}`, color:'#fff' }} />
            <Typography sx={{fontSize: 14, color: "#858585"  }}>{date}</Typography>
          </Stack>
        ) : (
          <Typography sx={{fontSize:14, color: "#858585"  }}>
            {date}
          </Typography>
        )}
        <Typography   sx={{
              fontWeight: "bold",
              fontSize: 17,
              display: "inline-flex",
              my: 1,
            }}
            color="text.secondary">
          {title}
        </Typography>
        <Typography sx={{color:'#424242', fontSize:13}}>{content}</Typography>
        <Typography sx={{ whiteSpace: "pre-line",marginTop:0.5,  fontSize: 13.5, color: "#757575"  }}>
          {subContent}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center"}}>
        {/* <Button
          size="small"
          variant="contained"
          className="PrimaryButton"
          onClick={onClick}
          sx={{ visibility: `${isDefault ? 'hidden' : 'visible'}` }}
        >
          slett {buttonName}
        </Button> */}
        <DeleteButtonWithConfirmation onDeleteConfirm={onDeleteButtonClick} variant={'contained'}
         buttonProps={{ visibility: isDefault ? 'hidden' : 'visible' }}/>
      </CardActions>
    </Card>
  );
};

export default BasicTemplateCard;
