import React from 'react';
import { Stepper, Step, StepLabel } from "@mui/material";

const CustomStepper = ({ visibleSteps, activeStep, isStepCompleted, isStepActive }) => {

    
  return (
    <Stepper activeStep={activeStep}>
      {visibleSteps.map((label, index) => (
        <Step
          key={label}
          completed={isStepCompleted(index)}
          active={isStepActive(index)}
        >
          <StepLabel>
            {label.startsWith("Hidden Module Step") ? "Velg mal" : label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
