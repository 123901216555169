import axios from 'axios';
import  getCookie  from "../utils/cookieUtils"
import { store } from '../services/store'; 
import { setErrorMessage} from '../services/403Error/errorSlice'; 

const apiClient = axios.create();

apiClient.interceptors.request.use((config) => {
  const token = getCookie('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});


// Response Interceptor
apiClient.interceptors.response.use((response) => {
  
  // Response for 403 error
  return response;
}, (error) => {
  if (error.response && error.response.status === 403) {
    store.dispatch(setErrorMessage('Session expired. Please log in again.')); 
    const logoutUser = () => {
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; //delete cookies
      localStorage.removeItem('isLoggedIn'); 
    };
    logoutUser();
  }

  return Promise.reject(error);
});

export default apiClient;
