import { useState, useEffect } from "react";
import { getTokenExpirationDate } from "../utils/jwtUtils";
import getCookie from "../utils/cookieUtils";

export function useAuth() {
  const token = getCookie("token");
  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const isLoggedInFromStorage = localStorage.getItem("isLoggedIn");
    return isLoggedInFromStorage ? JSON.parse(isLoggedInFromStorage) : false;
  });

  useEffect(() => {
    if (token) {
      const expirationDate = getTokenExpirationDate(token);
      const now = new Date();
       if (expirationDate > now)  {
      // if (expirationDate === now) {
        setIsLoggedIn(true);
      } else {
        //! Token has expired
        setTokenExpired(true);
        //! Wait for 5 seconds before logging out
        const timer = setTimeout(() => {
          logout();
        }, 5000);
        //! Cleanup the timer when the component is unmounted
        return () => clearTimeout(timer);
      }
    }
    //! Set loading to false after the checks are done
    setLoading(false);
  }, [token]);

  const logout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
    setTokenExpired(false);
  };

  return { loading, isLoggedIn, setIsLoggedIn, logout, tokenExpired };
}
