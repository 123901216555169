import { React, useState, useEffect } from "react";
import {
  Drawer as MUIDrawer,
  ListItemButton,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Addchart,
  AdminPanelSettings,
  ContactPhone,
  IntegrationInstructions,
  BarChart, CalendarMonth,
  People,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./style.css";
import FooterIcons from "./FooterIcons";
import { useAuth } from "../../hooks/useAuth";
import { Dashboard } from "@mui/icons-material";
import LogoutConfirmationModal from "../../components/LoginComponents/Logout";

const Drawer = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const role = localStorage.getItem("roles");
  const roleFromLocal = JSON.parse(role);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const { logout } = useAuth();
  const logoWhite = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693385010/Making%20View%20Assets/MV_logo_white_akffdg.png';

  const handleCloseLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  const handleConfirmLogout = () => {
    logout();
    navigate('/login');
    handleCloseLogoutDialog();
  };

  function SidebarListItem({ primary, icon: Icon, to, isActive, className }) {
    return (
      <ListItemButton
        className={`${className} ${isActive ? "active" : "sidebar-item"}`}
        onClick={() => navigate(to)}
      >
        <ListItemIcon className="sidebar-icon">
          <Icon />
        </ListItemIcon>
        <ListItemText primary={primary} />
      </ListItemButton>
    );
  }

  const pathname = window.location.pathname;
  useEffect(() => {
    if (pathname === "/")
      setActiveIndex(1);
    else if (pathname === '/admin')
      setActiveIndex(2);
    else if (pathname === '/kalender')
      setActiveIndex(3);
    else if (pathname === '/resultat')
      setActiveIndex(4);
    else if (pathname === '/ansatte')
      setActiveIndex(5);
    else if (pathname === '/integrasjon')
      setActiveIndex(6);
    else if (pathname === '/kontakt')
      setActiveIndex(7);
    else if (pathname === '/kurs')
      setActiveIndex(8);
  }, [pathname]);

  return (
    <MUIDrawer variant="permanent" className="drawer">
      <img src={logoWhite} alt="Logo" className="logoShow" />
      <List sx={{ mt: 2 }}>
        <SidebarListItem
          primary="Dashboard"
          icon={Dashboard}
          to="/"
          isActive={activeIndex === 1}
          className={activeIndex === 1 ? "active" : ""}
        />

        {roleFromLocal?.includes("org.admin.super") && (
          <>
            <SidebarListItem
              primary="Kursplanlegger"
              icon={Addchart}
              to="/kurs"
              isActive={activeIndex === 8}
              className={activeIndex === 8 ? "active" : ""}
            />
            <SidebarListItem
              primary="Administrator"
              icon={AdminPanelSettings}
              to="/admin"
              isActive={activeIndex === 2}
              className={`sidebar3 ${activeIndex === 2 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Kalender"
              icon={CalendarMonth}
              to="/kalender"
              isActive={activeIndex === 3}
              className={`sidebar4 ${activeIndex === 3 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Resultater"
              icon={BarChart}
              to="/resultat"
              isActive={activeIndex === 4}
              className={`sidebar5 ${activeIndex === 4 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Ansatte"
              icon={People}
              to="/ansatte"
              isActive={activeIndex === 5}
              className={`sidebar6 ${activeIndex === 5 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Integrasjon"
              icon={IntegrationInstructions}
              to="/integrasjon"
              isActive={activeIndex === 6}
              className={`sidebar7 ${activeIndex === 6 ? "active" : ""}`}
            />
          </>
        )}

        {roleFromLocal?.includes('org.admin.assistant') && (
          <>
            <SidebarListItem
              primary="Kursplanlegger"
              icon={Addchart}
              to="/kurs"
              isActive={activeIndex === 8}
              className={`sidebar2 ${activeIndex === 8 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Administrator"
              icon={AdminPanelSettings}
              to="/admin"
              isActive={activeIndex === 2}
              className={`sidebar3 ${activeIndex === 2 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Kalender"
              icon={CalendarMonth}
              to="/kalender"
              isActive={activeIndex === 3}
              className={`sidebar4 ${activeIndex === 3 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Resultater"
              icon={BarChart}
              to="/resultat"
              isActive={activeIndex === 4}
              className={`sidebar5 ${activeIndex === 4 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Ansatte"
              icon={People}
              to="/ansatte"
              isActive={activeIndex === 5}
              className={`sidebar6 ${activeIndex === 5 ? "active" : ""}`}
            />
            {/* Integration Tab for Assistant Admin */}
            <SidebarListItem
              primary="Integrasjon"
              icon={IntegrationInstructions}
              to="/integrasjon"
              isActive={activeIndex === 6}
              className={`sidebar7 ${activeIndex === 6 ? "active" : ""}`}
            />
          </>
        )}

        {roleFromLocal?.includes("org.admin.integration") && (
          <>
            <SidebarListItem
              primary="Ansatte"
              icon={People}
              to="/ansatte"
              isActive={activeIndex === 5}
              className={`sidebar6 ${activeIndex === 5 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Integrasjon"
              icon={IntegrationInstructions}
              to="/integrasjon"
              isActive={activeIndex === 6}
              className={`sidebar7 ${activeIndex === 6 ? "active" : ""}`}
            />
          </>
        )}

        {roleFromLocal?.includes("org.admin.course") && (
          <>
            <SidebarListItem
              primary="Kursplanlegger"
              icon={Addchart}
              to="/kurs"
              isActive={activeIndex === 8}
              className={`sidebar2 ${activeIndex === 8 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Kalender"
              icon={CalendarMonth}
              to="/kalender"
              isActive={activeIndex === 3}
              className={`sidebar4 ${activeIndex === 3 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Resultater"
              icon={BarChart}
              to="/resultat"
              isActive={activeIndex === 4}
              className={`sidebar5 ${activeIndex === 4 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Ansatte"
              icon={People}
              to="/ansatte"
              isActive={activeIndex === 5}
              className={`sidebar6 ${activeIndex === 5 ? "active" : ""}`}
            />
          </>
        )}

        {roleFromLocal?.includes("org.admin.department") && (
          <>
            <SidebarListItem
              primary="Kursplanlegger"
              icon={Addchart}
              to="/kurs"
              isActive={activeIndex === 8}
              className={`sidebar2 ${activeIndex === 8 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Kalender"
              icon={CalendarMonth}
              to="/kalender"
              isActive={activeIndex === 3}
              className={`sidebar4 ${activeIndex === 3 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Resultater"
              icon={BarChart}
              to="/resultat"
              isActive={activeIndex === 4}
              className={`sidebar5 ${activeIndex === 4 ? "active" : ""}`}
            />
            <SidebarListItem
              primary="Ansatte"
              icon={People}
              to="/ansatte"
              isActive={activeIndex === 5}
              className={`sidebar6 ${activeIndex === 5 ? "active" : ""}`}
            />
          </>
        )}

        <SidebarListItem
          primary="Kontakt oss"
          icon={ContactPhone}
          to="/kontakt"
          isActive={activeIndex === 7}
          className={`sidebar8 ${activeIndex === 7 ? "active" : ""}`}
        />
        <LogoutConfirmationModal
          open={logoutDialogOpen}
          onClose={handleCloseLogoutDialog}
          onConfirm={handleConfirmLogout}
          title="Bekreft utlogging"
          description="Er du sikker på at du vil logge ut?"
        />
      </List>
      <FooterIcons />
    </MUIDrawer>
  );
};

export default Drawer;
