import React, { useState } from 'react';
import BasicTabs from "../layout/BasicTabs";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Header from "../layout/Header";
import { Outlet } from 'react-router-dom';

const KursPage = ({ basePath = "/kurs" }) => {

  const tabs = [
    {
      label: "Opprett kurs",
      Component: () => <Outlet />,
      icon: <AddCircleOutlineIcon />,
      path: `${basePath}`,
      tooltip: 'Her lager du nye kurs',
      className: 'create-course-step',
    },
    {
      label: "Planlegg kurs",
      Component: () => <Outlet />,
      path: `${basePath}/planlegg-kurs`,
      icon: <MoreTimeIcon />,
      tooltip: 'Velg et kurs og send invitasjon',
      className: 'plan-course-step'
    },
    {
      label: "Planlagte kurs",
      Component: () => <Outlet />,
      path: `${basePath}/planlagte-kurs`,
      icon: <EventAvailableIcon />,
      tooltip: 'Oversikt over planlagte kurs',
      className: 'scheduled-courses-step'
    },
    {
      label: "Innstillinger mal",
      path: `${basePath}/innstillinger-mal`,
      Component: () => <Outlet />,
      icon: <SettingsSuggestIcon />,
      tooltip: 'Endre eller opprett krav til kurs',
      className: 'template-settings-step'
    },
  ];

  return (
    <main>
      <Header header={'Kursplanlegger'} headerSize={'h6'} className={"kursplanlegger"} />
      <BasicTabs tabs={tabs} />
    </main>
  );
};

export default KursPage;
