import React from 'react'
import { Box} from '@mui/material';
import BigCalendar from "../components/CalendarComponents/Calendar"
import { useGetScheduledCoursesQuery } from '../services/ScheduledCourse/scheduledCourseApi';
import Header from '../layout/Header';
import CenteredLoading from "../layout/CenteredLoading";


const CalendarPage=()=>{
  const { data = [], isLoading } = useGetScheduledCoursesQuery();

  if (isLoading) return(
    <main>
      <Header header={'Kalender'} headerSize={'h6'}/>
      <CenteredLoading tekst={'Laster inn…'}/>
    </main>
  )


  return (
    <main>
      <Header header={'Kalender'} headerSize={'h6'} className={'kalender'}/>
      <Box sx={{mt:3}}> 
    <BigCalendar width={ "calc(100vw - 240px)"} height={ "calc(100vh - 90px)"} data={data}/>
  </Box>
    </main>
  );
}

export default CalendarPage;
