export function formatNorwegianDate(date) {
    const monthsInNorwegian = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];
    return date.getDate() + " " + monthsInNorwegian[date.getMonth()];
}
