import { useState, React, useEffect } from "react";
import Copyright from "../../components/LoginComponents/Copyright";
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  TextField, Link,
} from "@mui/material";
import { successNote, toastErrorNotify } from "../../helpers/toastNotify";
import Loading from "../../layout/Loading";
import { SendPhoneValidationOTP } from "../../api/endpoints";
import axios from "axios";
import { useNavigate, useSearchParams} from "react-router-dom";
import * as Yup from "yup";

export default function PhoneVerification() {
  const [loading, setLoading] = useState(false);
  const [paramaters] = useSearchParams();
  const navigate = useNavigate();
  const validationKey = paramaters.get("validationKey");
  const registrationKey = paramaters.get("registrationKey");
  const orgId = paramaters.get("orgId");
  const userId = paramaters.get("userId");
  const email = paramaters.get("email");
  const adminType = paramaters.get("type");
  const [phoneNum, setPhoneNum] = useState('')
  const [validationErrors, setValidationErrors] = useState({});
  const yupErrors = {};
  const authBackgroundImage = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381778/Making%20View%20Assets/auth_background_texcon.jpg'
  const logoRed =  'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381822/Making%20View%20Assets/MV_logo_red_black_ctm6tn.png'


  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    let userData = storedUserData ? JSON.parse(storedUserData) : {};
  
    // Compare the parameters from the link with the userData in localStorage
    if (
      (validationKey && userData.validationKey !== validationKey) ||
      (registrationKey && userData.registrationKey !== registrationKey) ||
      (email && userData.email !== email) ||
      (phoneNum && userData.phone !== phoneNum) ||
      (adminType && userData.adminType !== adminType) ||
      (orgId && userData.orgId !== orgId) ||
      (userId && userData.userId !== userId)
    ) {
      // If there's a difference and the value from the link is not null or undefined, update userData
      userData = {
        validationKey: validationKey || userData.validationKey,
        registrationKey: registrationKey || userData.registrationKey,
        email: email || userData.email,
        phone: phoneNum || userData.phone,
        adminType: adminType || userData.adminType,
        orgId: orgId || userData.orgId,
        userId: userId || userData.userId,
      };
  
      localStorage.setItem('userData', JSON.stringify(userData));
    }
  }, [validationKey, registrationKey, email, phoneNum, adminType, orgId, userId]);
  

  const storedUserData = localStorage.getItem('userData');
  // const userData = storedUserData ? JSON.parse(storedUserData) : null;
  const userData = storedUserData ? JSON.parse(storedUserData) : {
    validationKey,
    registrationKey ,
    email,
    phone: phoneNum,
    adminType,
    orgId ,
    userId 
  };


 const validationSchema = Yup.object({
number: Yup.string()
.required("Telefonnummeret ditt er påkrevd")
.matches(/^\d{8}$/, "Telefonnummeret må være åtte sifre"),
});

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    setPhoneNum(data.get('number'))

    const initialValues = {
      number: data.get("number"),
    };


const params={
  validationKey: validationKey || userData.validationKey ,
  registrationKey:   registrationKey || userData.registrationKey,
  email:  email || userData.email,
  phone: data.get("number"),
}
    try {
      await validationSchema.validate(initialValues, { abortEarly: false });
      //! If validation succeeds, proceed with form submission
     await axios.post(SendPhoneValidationOTP, params); 
      // Dispatch the action to the Redux store
      // dispatch(setUserData(userData));
      successNote("Verifiseringkode sent");
      navigate("/otpvalidation", { state: { validationKey, registrationKey, email, phone:data.get('number'), adminType, orgId, userId} });
    } catch (err) {
      //! If validation fails, display error messages
      if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      }else if(err.response.data.message){
        toastErrorNotify(err.response.data.message);
      } else if(err.response.data.title){
        toastErrorNotify(err.response.data.title);
      } else {
        toastErrorNotify("Registreringen mislyktes");
      }
      console.log(err);
    } finally {
      setLoading(false);
      // console.log('params', params)
    
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        backgroundImage: `url(${authBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "130vh",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            backgroundColor: "rgba(255,255,255, 0.9)",
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 5,
          }}
        >
          <img src={logoRed} alt="Logo" className="logoLogin" />
          <Typography component="h1" variant="h5">
          Mobilnummerverifisering
          </Typography>
          <Typography sx={{mt:2, fontSize:16}}>
          Velkommen til Dr. Cloud! <br/> <br/> Skriv inn mobilnummeret ditt for å motta en bekreftelseskode. Bekreft nummeret ditt og fullfør registreringen med denne koden.
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
                <TextField
                sx={{mb:2}}
                  required
                  fullWidth
                  id="number"
                  label="Mobilnummer"
                  name="number"
                  autoComplete="phone"
                  error={Boolean(validationErrors.number)}
                  helperText={validationErrors.number}
                />
                <Link onClick={() => navigate(adminType === "super" ? "/registerroot" : "/register")} sx={{cursor: 'pointer'}}>Har skrevet inn mobilnummeret før</Link>
            <Button
              className="PrimaryButton"
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <Loading color={"#fff"} size={25} /> : "Verify"}
            </Button>
            <Copyright sx={{ mt: 1 }} />
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
