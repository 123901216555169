import { useState } from "react";
import { Button, TextField, Link, Box, Grid, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { GetRolesUrl } from "../../api/endpoints";
import { toastErrorNotify } from "../../helpers/toastNotify";
import * as Yup from "yup";
import Loading from "../../layout/Loading";
import { useVerifyOtpMutation } from "../../services/auth/verifyOtp";
import { useDispatch } from "react-redux";
import { setToken } from "../../services/auth/authSlice";
import { getTokenExpirationDate } from "../../utils/jwtUtils";
import apiClient from "../../api/apiClient";

export default function OtpVerify({ email }) {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [stoken, setStoken] = useState();
  const [userRoles, setUserRoles] = useState();
  const [userId, setUserId] = useState();
  const yupErrors = {};
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [verifyOtp] = useVerifyOtpMutation();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    code: Yup.string()
            .length(4, "Kode må være 4 tegn lang.")
            .required("Kode feltet er påkrevd."),
});


  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const initialValues = {
      email,
      code: data.get("code"),
    };

    try {
      await validationSchema.validate(initialValues, { abortEarly: false });
      const resultAction = await verifyOtp(initialValues);

      if (resultAction.data?.statusCode === 200) {
        const token = resultAction?.data.data.token;
        setStoken(token);
        dispatch(setToken(token));

        const setTokenCookie = (token) => {
          const expirationInSeconds = getTokenExpirationDate(token) / 1000;

          if (!expirationInSeconds) {
            console.error("Invalid token");
            return;
          }

          const nowInSeconds = Math.floor(Date.now() / 1000);
          const remainingTime = expirationInSeconds - nowInSeconds;

          document.cookie = `token=${token}; Secure; SameSite=Strict; max-age=${remainingTime}`;
        };

        setTokenCookie(token);
        setIsLoggedIn(true);

        const getRoles = await apiClient.get(GetRolesUrl);

        if (getRoles.status === 200) {
          const userId = getRoles.data.data.userId;
          setUserId(userId);
    
          const userRoles = getRoles.data.data.roles.map((item) => item.name);
          setUserRoles(userRoles);
    
          localStorage.setItem("roles", JSON.stringify(userRoles));
          localStorage.setItem("isLoggedIn", true);
    
          navigate(from, { replace: true });
        }
      } else {
        handleResultActionErrors(resultAction);
      }
    } catch (err) {
      handleValidationErrors(err);
    } finally {
      setLoading(false);
    }
  };

const handleResultActionErrors = (resultAction) => {
    if (resultAction?.error?.status === 500) {
      toastErrorNotify("Server Error");
    } else if (resultAction?.error.data?.data) {
      toastErrorNotify(resultAction?.error.data.data?.validationErrors[0]);
    } else if (resultAction?.error.data.data === null) {
      toastErrorNotify(resultAction?.error.data?.message);
    } else if (!resultAction.error) {
      toastErrorNotify("Ingen serverrespons");
    } else {
      toastErrorNotify("Innlogging Mislyktes");
    }
    // console.log(resultAction?.error);
};

const handleValidationErrors = (err) => {
    if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
    }
};

  return (
    <>
      <Typography component="h1" variant="h5">
        Tast inn verifiseringskode
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleOtpSubmit}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="code"
          label="Verifiseringkode"
          id="code"
          error={Boolean(validationErrors.code)}
          helperText={validationErrors.code}
        />
        <Button
          className="PrimaryButton"
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? <Loading color={"#fff"} size={25} /> : "Godkjenn"}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/login" variant="body2">
              Tilbake til logg inn
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
