import React from 'react'
import {useState} from 'react';
import {Link, Container, Box, Grid, Typography, Button, TextField} from "@mui/material";
import axios from "axios";
// import { useNavigate } from 'react-router-dom';
import {successNote, toastErrorNotify} from "../helpers/toastNotify";
import {ForgotPasswordUrl} from "../api/endpoints"
import Copyright from "../components/LoginComponents/Copyright";
import * as Yup from "yup";
import Loading from "../layout/Loading";


export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [, setSuccess] = useState(false);
  // const navigate = useNavigate();
  const yupErrors = {};
  const [validationErrors, setValidationErrors] = useState({});
  const [email, setEmail] = useState("");
  const authBackgroundImage = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381778/Making%20View%20Assets/auth_background_texcon.jpg'
  const logoRed =  'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381822/Making%20View%20Assets/MV_logo_red_black_ctm6tn.png'

  
  const validationSchema = Yup.object({
    email: Yup.string().email("En gyldig e-postadresse er påkrevd").required("En gyldig e-postadresse er påkrevd"),
    });

  const onSubmitClicked = async (e)=>{
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.currentTarget);
    const email = data.get("email");
    const initialValues = {
      email
    };
    try{
      await validationSchema.validate(initialValues, { abortEarly: false });
      //! If validation succeeds, proceed with form submission
       await axios.post(ForgotPasswordUrl + `${email}` );
      setSuccess(true)
      successNote("Du vil motta en e-post for tilbakestilling hvis en bruker med den e-postadressen eksisterer")
      setEmail(""); 
    //   setTimeout(() => {
    //     navigate(`/passwordreset?registrationKey=?&email=${email}&userId=?`)
    // }, 3000);
    } catch(err){
      if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      toastErrorNotify(err.response.data.message)
      console.log(err.response.data.message)
    }  else if (!err?.response) {
      toastErrorNotify("Ingen serverrespons");
    } else if (err.response.data?.data) {
      toastErrorNotify(err.response.data.data?.validationErrors[0]);
    } else if (err.response.data.data === null) {
      toastErrorNotify(err.response.data?.message);
    } else {
      toastErrorNotify("En feil oppstod");
    }
  }finally{
    setLoading(false);
  }
}


return (
  <Grid
  container
  component="main"
  sx={{
    backgroundImage: `url(${authBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "120vh",
  }}
>
  <Container component="main" maxWidth="xs">
    <Box
      sx={{
        marginTop: 8,
        backgroundColor: "rgba(255,255,255, 0.8)",
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 5,
      }}
    >
          <img src={logoRed} alt="Logo" className="logoLogin" />
      <Typography component="h1" variant="h5">
      Tilbakestill passord
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={onSubmitClicked}
        sx={{ mt: 3 }}
      >
        <Grid container>
          <Grid item sm={12}>
          <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-post "
                name="email"
                autoComplete="email"
                autoFocus
                error={Boolean(validationErrors.email)}
                helperText={validationErrors.email}
                value={email}
  onChange={e => setEmail(e.target.value)}
              />
          </Grid>
          <Grid item sm={12}>
          <Button
          className="PrimaryButton"
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? <Loading color={"#fff"} size={25} /> : "Tilbakestill passord"}
        </Button>
          </Grid>
        <Grid item sm={12}>
              <Link href="/login" variant="body2">
                 Tilbake til logg inn
                 </Link>
               </Grid>
        </Grid>
        <Copyright sx={{ mt: 1 }} />
      </Box>
    </Box>
  </Container>
</Grid>
)
}