import React from "react";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { Typography, Box } from "@mui/material";
import Carusel from "./Carusel";

const ModuleSuggestions = () => {
  return (
    <Box sx={{ minWidth: 600, maxWidth: 1200 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-start",
          mb: 1.5,
        }}
      >
        <Typography
          className="recommended-modules"
          sx={{ fontWeight: 600, mb: 1, display: "flex", alignItems: "center" }}
        >
          <DashboardCustomizeIcon sx={{ mr: 1, color: "#858585" }} />
          Anbefalte moduler
        </Typography>
      </Box>
      <Carusel />
    </Box>
  );
};

export default ModuleSuggestions;
