import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import VrpanoIcon from "@mui/icons-material/Vrpano";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import {
  useGetGeneralResultAsUpperQuery,
  useGetGeneralResultAsDepQuery,
} from "../../../services/GeneralResults/resultSlice";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ProgressBar from "./ProgressBar";
import { useDispatch } from "react-redux";
import { setResultContainerLoading } from "../../../redux/Dashboard/loadingSlice";
import StatusIndicator from "./StatusIndicator";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ResultContainer = () => {
  const role = JSON.parse(localStorage.getItem("roles"));
  const isDepartmentAdmin = role.includes("org.admin.department");
  const isIntegrasjonAdmin = role.includes("org.admin.integration");


  const departmentAdminResult = useGetGeneralResultAsDepQuery(undefined, {
    skip: !isDepartmentAdmin ,
  });
  const upperAdminResult = useGetGeneralResultAsUpperQuery(undefined, {
    skip: isDepartmentAdmin || isIntegrasjonAdmin
});


  const { data, isLoading, error } = isDepartmentAdmin
    ? departmentAdminResult
    : upperAdminResult;


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (_, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const cellStyles = { textAlign: "right", color: "#fff" };
  const boxStyles = { display: "flex", alignItems: "center" };

  const IconLabelCell = ({ icon: Icon, label, ...props }) => (
    <TableCell sx={cellStyles} {...props}>
      <Box sx={boxStyles}>
        <Icon size="small" sx={{ color: "#fff" }} />
        <Box ml={1}>{label}</Box>
      </Box>
    </TableCell>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      dispatch(setResultContainerLoading(true));
    }
  }, [isLoading, dispatch]);

  return (
    <Box >
        <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start', mb:1.5 }}  >
        <Typography className="course-general-info"
            sx={{ fontWeight: 600, mb: 1, display: "flex", alignItems: "center" }}
        >
        <AssessmentIcon sx={{ mr: 1, color: "#858585" }}/>
            Kursoversikt
        </Typography>
    </Box>

      <TableContainer component={Paper} sx={{ minWidth: 950, maxWidth: 1250 }}  >
        <Table aria-label="module table" size="small" >
          <TableHead className="DashboardTable">
            <TableRow>
              <IconLabelCell icon={VrpanoIcon} label="Kurs" />
              <IconLabelCell icon={CorporateFareIcon} label="Avdeling" />
              <IconLabelCell icon={TrendingUpIcon} label="Status" />
              <IconLabelCell icon={PeopleOutlineIcon} label="Ansatte" />
              <IconLabelCell icon={StackedBarChartIcon} label="Resultat" />
            </TableRow>
          </TableHead>
          <TableBody>
  {isIntegrasjonAdmin ? (
    <TableRow>
      <TableCell sx={{ display: "flex", alignItems: "center", gap: "10px", mt: 0.5 }}>
        <Typography variant="body1" color="textSecondary">
          Ingen kurs planlagt ennå.
        </Typography>
      </TableCell>
    </TableRow>
  ) : data?.data.length > 0 ? (
    <>
      {data?.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result, index) => (
        <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell>{result.courseName}</TableCell>
          <TableCell>{result.departmentName} </TableCell>
          <TableCell>
            <StatusIndicator startDateTime={result.startDateTime} endDateTime={result.endDateTime} />
          </TableCell>
          <TableCell>{result.userCount} </TableCell>
          <TableCell>
            <ProgressBar completed={result.generalResultpercentage} />
          </TableCell>
        </TableRow>
      ))}
    </>
  ) : (
    <TableRow>
      <TableCell sx={{ display: "flex", alignItems: "center", gap: "10px", mt: 0.5 }}>
        <Typography variant="body1" color="textSecondary">
          Ingen kurs planlagt ennå.
        </Typography>
        <Link
          to="/kurs/planlegg-kurs"
          style={{ textDecoration: "none", display: "flex", alignItems: "center" }}
        >
          <AddCircleOutlineIcon fontSize="small" sx={{ mr: 0.5 }} />
          <Typography color="primary">Planlegg kurs</Typography>
        </Link>
      </TableCell>
    </TableRow>
  )}
</TableBody>

        </Table>
        {data?.data.length > 0 && (
          <TablePagination
            sx={{ alignSelf: "flex-end" }}
            rowsPerPageOptions={[4, 8, 12]}
            component="div"
            count={data?.data.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rader pr. side"
          />
        )}
      </TableContainer>
    </Box>
  );
};

export default ResultContainer;
