import { useNavigate } from "react-router-dom";
import { Paper, Grid, Typography, Button } from "@mui/material";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Paper elevation={5} sx={{ m: 4, p: 3 }}>
        <Typography variant="h5" align="center" sx={{ mb: 6 }}>
          Unauthorized
        </Typography>
        <Typography variant="body1" sx={{ m: 3 }}>
          You do not have access to the requested page.
        </Typography>

        <Grid item display="flex" justifyContent="center" xs={12}>
          <Button
            variant="contained"
            className="PrimaryButton"
            sx={{ height: 40, width: 200 }}
            onClick={goBack}
          >
            Go back
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Unauthorized;
