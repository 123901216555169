import React from 'react'
import {Box, Slider} from '@mui/material'


const BasicSlider = ({value, onChange, getText, marks, min, maks, step}) => {
  return (
    <Box display="flex" flexDirection="column">
    <Slider
    min={min}
    max={maks}
    step={step}
    marks={marks}
    value={value} onChange={onChange}
    valueLabelDisplay="auto"
    getAriaValueText={getText}
    />
   
  </Box>
  )
}

export default BasicSlider