import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { GetDepartmentUsersByDepartmenAdmin} from "../../api/endpoints";
import { useNavigate } from "react-router-dom";
import { DataGrid, nbNO, GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,} from "@mui/x-data-grid";
  import CenteredLoading from "../../layout/CenteredLoading";
import apiClient from "../../api/apiClient";

const columns = [
  {
    field: "firstName",
    headerName: "Fornavn",
    width: 150,
    editable: false,
    sortable: true,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: "lastName",
    headerName: "Etternavn",
    width: 150,
    editable: false,
    sortable: true,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: "department",
    headerName: "Avdeling",
    width: 150,
    sortable: true,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: "departmentAdmin",
    headerName: "Aaavdelingsleder",
    width: 150,
    sortable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: "profession",
    headerName: "Stilling",
    width: 150,
    sortable: true,
    headerClassName: 'super-app-theme--header',
  },
];

export default function UserListDepartmentAdmin() {
  const [userList, setUserList] = useState(null);
  const navigate = useNavigate();
  const csvOptions = { delimiter: ';', utf8WithBom: true  };
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(20);


  const CustomToolbar = (props) => (
    <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport csvOptions={csvOptions} />
  </GridToolbarContainer>
  );

  async function getDepartmentUser() {
    try {
      const response = await apiClient.get(GetDepartmentUsersByDepartmenAdmin);
      setUserList(response.data.data);
      setLoading(false)
      // console.log(response)
    } catch (err) {
      console.log(err?.response);
    }
  }

  const option = userList?.map(
    ({name, surname, departmentId, userId, profession}) => ({
      id: `${userId}`,
      firstName: `${name}`,
      lastName: `${surname}`,
      department: `${departmentId}`,
      profession:`${profession}`
    })
  );

  console.log('option', option)
  const options= option?.sort((a, b) => a.id - b.id);

  useEffect(() => {
    getDepartmentUser();
  }, []);

  return (
      <Box sx={{ ml: 3, height: 600, maxHeight:800, mt:5, minWidth: "50rem", maxWidth: "60rem",
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#2f4874",
      color: "#fff",
      fontSize: 16,
    }, }}
    >
      {loading ? (
               <CenteredLoading tekst={'Laster inn…'}/>
      ) : options && (
          <DataGrid
          sx={{
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              "& .MuiDataGrid-row": {
                ":hover": {
                color: '#5177b8',

                },
                cursor: "pointer",
                "&:nth-of-type(2n)": {
                  backgroundColor: "rgba(234, 234, 234, .7)",
                },
                "& .MuiDataGrid-cell:active": {
                  outline: "none",
                },
              },
            },
            "& .MuiDataGrid-toolbarContainer ": {
              backgroundColor: "#2f4874",
              borderBottom: "1px solid #fff",
              p: 1,
              zIndex: "modal",
              "& .MuiButtonBase-root": {
                color: "#fff",
                paddingRight: 4,
              },
            },
            "& .MuiDataGrid-columnHeaders": {
              borderRadius: 0,
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 30, 45]}
         rows={options}
         pagination
         columns={columns}
         disableSelectionOnClick
         rowHeight={50}
        autoHeight
         disableColumnMenu
         components={{ Toolbar: CustomToolbar }}
         onRowClick={(row) => {
           const id = row.id;
           console.log(id); // get the id property of the clicked row
           navigate(`/resultat/${id}`);
         }}
         localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
       />
      )}
    </Box>
  );
}
