import { useState, useEffect, useCallback } from "react";
import { Typography, Box, Paper } from "@mui/material";
import ResultsTabel from "../components/ResultsComponents/GeneralResults/ResultsTable";
import AvdelingButton from "../components/ResultsComponents/FilterButtons/DepartmentFilter";
import CourseButton from "../components/ResultsComponents/FilterButtons/CourseFilter";
import DateButton from "../components/ResultsComponents/FilterButtons/DateFilter";
import CourseOwnerButton from "../components/ResultsComponents/FilterButtons/CourseOwnerFilter";
import {
  GeneralResultAsUpperAdmin,
  GetResultAsDepartmentAdmin,
} from "../api/endpoints";
import apiClient from "../api/apiClient";
import Header from '../layout/Header'
import CenteredLoading from "../layout/CenteredLoading";


const ResultsPage = () => {
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [loading, setLoading] = useState(false);


  // ! Get the current date and time
  const currentDate = new Date();
  
  const role = localStorage.getItem("roles");
  const roleFromLocal = JSON.parse(role);
  const isDepartmentAdmin = useCallback(
    () => roleFromLocal.includes("org.admin.department"),
    [roleFromLocal]
  );

  // ! Subtract one month from the current date
  const customStartDate = new Date(
    currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
  ).toISOString();

  // ! Add one month to the current date
  const customEndDate = new Date(
    currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
  ).toISOString();

  // console.log("general results", resultData);

  const handleSelectedDepartmentsChange = useCallback(
    (updatedSelectedDepartments) => {
      setSelectedDepartments(updatedSelectedDepartments);
    },
    []
  );

  const handleSelectedOwnerChange = useCallback((updatedSelectedOwner) => {
    setSelectedOwner(updatedSelectedOwner);
  }, []);

  const handleSelectedCourseChange = useCallback((updatedSelectedCourse) => {
    setSelectedCourse(updatedSelectedCourse);
  }, []);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // ! Get All Results
  const params = {
    departmentNames: selectedDepartments,
    courseOwnerIds: selectedOwner,
    courseIds: selectedCourse,
    startDateTime: startDate ? startDate.toISOString() : customStartDate,
    endDateTime: endDate ? endDate.toISOString() : customEndDate,
  };

  async function getAllResults() {
    setLoading(true);
    try {
      let endpoint = "";

      for (const role of roleFromLocal) {
        if (role === "org.admin.department") {
          endpoint = GetResultAsDepartmentAdmin;
          delete params.courseOwnerIds;
          delete params.departmentNames;
          break; // Break the loop once the endpoint is found
        } else if (
          role === "org.admin.super" ||
          role === "org.admin.assistant" ||
          role === "org.admin.course"
        ) {
          endpoint = GeneralResultAsUpperAdmin;
          break; // Break the loop once the endpoint is found
        }
      }
      const response = await apiClient.post(endpoint, params, {});
      setResultData(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    // Make the request when all parameters have a valid value
    for (const role of roleFromLocal) {
    if (role === "org.admin.department") {
        if(selectedCourse.length > 0) {
        getAllResults();
      }
    } else if (role !== "org.admin.department") {
      if (
        selectedDepartments.length > 0 &&
        selectedOwner.length > 0 &&
        selectedCourse.length > 0
      ) {
        getAllResults();
      }
    }}
  }, [selectedCourse, selectedDepartments, selectedOwner, startDate, endDate]);

  return (
      <Box width="100%">
        <Header header={'Resultater'} headerSize={'h6'} className={'resultater'}/>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "flex-start",
            alignItems: "center",
            "& > *": {
              mt:2,
            },
          }}
        >
          <Typography>Filtrér: </Typography>

          {!isDepartmentAdmin() && (
            <>
              <AvdelingButton
                onSelectedDepartmentsChange={handleSelectedDepartmentsChange}
              />
              <CourseOwnerButton
                onSelectedOwnerChange={handleSelectedOwnerChange}
              />
            </>
          )}
          <CourseButton onSelectedCourseChange={handleSelectedCourseChange} />
          <DateButton onDateChange={handleDateChange} />
        </Box>
        {loading && (
               <CenteredLoading tekst={'Laster inn…'}/>
        )}
        {!loading && (resultData === null || resultData?.length === 0) && (
          <Paper
            sx={{
              padding: 2,
              marginTop: 6,
              textAlign: "center",
              width: "70%",
              overflow: "auto",
            }}
          >
            <Typography variant="body1">Ingen kursresultater funnet</Typography>
          </Paper>
        )}
        {!loading && resultData?.length > 0 && (
          <ResultsTabel resultData={resultData} />
        )}
      </Box>
  );
};

export default ResultsPage;
