import {
  START_TOUR,
  STOP_TOUR,
  SET_STEP_INDEX,
  SET_STEPS,
  RESET_TOUR
} from "../actions/tourActions";

const initialState = {
  run: false,
  stepIndex: localStorage.getItem('tourStepIndex') || 0,
  steps: [],
  navigationInProgress: false,
};


export const tourReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_TOUR:
      return { ...state, run: true};
    case STOP_TOUR:
      return { ...state, run: false};
    case SET_STEPS:
      return { ...state, steps: action.steps };
    case SET_STEP_INDEX:
      // console.log("Reducer setting step index:", action.stepIndex);
      return { ...state, stepIndex: action.stepIndex };
    case RESET_TOUR:
    return {
      ...state,
      stepIndex: 0
    };
    default:
      return state;
  }
};
