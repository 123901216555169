import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetUserDetail} from '../../api/endpoints';
import getCookie from "../../utils/cookieUtils";

export const getAdminDetailsApi =  createApi({
    reducerPath:'getAdminDetailsApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            const token = getCookie('token');
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
          },
}),
endpoints:(builder)=>({
  getAdminDetails:builder.query({
      query:()=>({url:GetUserDetail, method:'GET'}),
      providesTags: [{ type: 'Detail', id: 'GET' }],
  })
})

})

export const {useGetAdminDetailsQuery} = getAdminDetailsApi;