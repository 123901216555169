import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: null,
  reducers: {
    setErrorMessage: (state, action) => action.payload,
    clearErrorMessage: () => null,
  },
});

export const { setErrorMessage, clearErrorMessage } = errorSlice.actions;

export default errorSlice.reducer;
