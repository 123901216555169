import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const CustomTextField = ({ value, customText, setCustomText, onCustomTextEditing, courseValue, getCustomText}) => {
  const [editMode, setEditMode] = useState(false);

  function handleTextClick() {
    setEditMode(true);
    onCustomTextEditing();
  }

  function handleTextChange(event) {
    setCustomText(event.target.value);
  }

  useEffect(() => {
    if (courseValue > 0) {
      getCustomText();
    }
  }, [courseValue, getCustomText]);
  

  return editMode ? (
    <TextField
      value={customText}
      fullWidth
      multiline
      onChange={handleTextChange}
    //   onBlur={handleTextBlur}
      autoFocus
    />
  ) : (
    <TextField
      value={value}
      fullWidth
      multiline
      onClick={handleTextClick}
    />
  );
};

export default CustomTextField;
