import {React, useEffect }from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Typography, Box } from "@mui/material";
import BigCalendar from "../CalendarComponents/Calendar";
import { useGetScheduledCoursesQuery } from "../../services/ScheduledCourse/scheduledCourseApi";
import { setMiniCalendarLoading  } from '../../redux/Dashboard/loadingSlice';
import { useDispatch } from 'react-redux';

const MiniCalender = () => {
  // const { data = [], isLoading, } = useGetScheduledCoursesQuery();

  const role = JSON.parse(localStorage.getItem("roles"));
  const isIntegrasjonAdmin = role.includes("org.admin.integration");

 const { data = [], isLoading } = useGetScheduledCoursesQuery(undefined, {
    skip: isIntegrasjonAdmin,
  });
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      dispatch(setMiniCalendarLoading(true));
    }
  }, [isLoading, dispatch]);





  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start', mb:2 }} className="calendar-section" >
        <Typography
            sx={{ fontWeight: 600, mb: 1, display: "flex", alignItems: "center" }}
        >
            <CalendarMonthIcon sx={{ mr: 1, color: "#858585" }} />
            Kalender
        </Typography>
    </Box>
    <BigCalendar  width='650px' height={380} customToolbar={true} data={data} fontSize={8.5} />
</Box>

  );
};

export default MiniCalender;

