import { useState, React } from "react";
import Copyright from "../components/LoginComponents/Copyright";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Container,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Link,
  Dialog,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { successNote, toastErrorNotify } from "../helpers/toastNotify";
import Loading from "../layout/Loading";
import { RegisterRootAdmin, AcceptUserAgreement } from "../api/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import apiClient from "../api/apiClient";
import { useDispatch } from "react-redux";
import { setToken } from "../services/auth/authSlice";
import { getTokenExpirationDate } from "../utils/jwtUtils";
import UserAgreement from "../components/RegisterComponents/UserAgreement";



export default function RegisterRootPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const storedUserData = localStorage.getItem("userData");
  const userData = storedUserData ? JSON.parse(storedUserData) : null;
  const [open, setOpen] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const authBackgroundImage = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381778/Making%20View%20Assets/auth_background_texcon.jpg'
  const logoRed =  'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381822/Making%20View%20Assets/MV_logo_red_black_ctm6tn.png'


  const handleAgreementChange = (event) => {
    setAgreementChecked(event.target.checked);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const yupErrors = {};

  const validationSchema = Yup.object({
    password1: Yup.string()
      .required("Passord er påkrevd")
      .matches(
        /\w*[A-Z]\w*/,
        "Passordet ditt må inneholde minst en stor bokstav"
      )
      .matches(/\w*\d\w*/, "Passordet ditt må inneholde minst ett tall")
      .matches(/^.{8,}$/, "Passordet ditt må være minst 8 tegn")
      .matches(
        /(?=.*[!@#$%^&*()-_=+{};:,<.>])/,
        "Passordet må inneholde minumum et spesialtegn"
      ),
    password2: Yup.string()
      .required("Bekreft passordet ditt")
      .oneOf([Yup.ref("password1")], "Passordene matcher ikke"),
    firstName: Yup.string().required("Fornavn er påkrevd"),
    lastName: Yup.string().required("Etternavn er påkrevd"),
  });

  const [values, setValues] = useState({
    password1: "",
    password2: "",
    showPassword1: false,
    showPassword2: false,
  });

  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };

  const handleClickShowPassword2 = () => {
    setValues({
      ...values,
      showPassword2: !values.showPassword2,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const initialValues = {
      password1: data.get("password1"),
      password2: data.get("password2"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
    };
    try {
      await validationSchema.validate(initialValues, { abortEarly: false });
      //! If validation succeeds, proceed with form submission
      const response = await axios.post(RegisterRootAdmin, {
        orgId: userData.orgId,
        userId: userData.userId,
        password1: data.get("password1"),
        password2: data.get("password2"),
        name: data.get("firstName"),
        surname: data.get("lastName"),
        code: userData.registrationKey,
        email: userData.email,
      });
      console.log('response', response)
      if (response.status === 200) {
        const token = response?.data.data.token;
        dispatch(setToken(token));

        const setTokenCookie = (token) => {
          const expirationInSeconds = getTokenExpirationDate(token) / 1000;

          // Check if the token is valid
          if (!expirationInSeconds) {
            console.error("Invalid token");
            return;
          }

          // Get the current time (in seconds)
          const nowInSeconds = Math.floor(Date.now() / 1000);

          // Calculate the remaining time (in seconds)
          const remainingTime = expirationInSeconds - nowInSeconds;
          // console.log("remainingTime", remainingTime);

          // Set the max-age to the remaining time
          document.cookie = `token=${token}; Secure; SameSite=Strict; max-age=${remainingTime}`;
        };

        // Call the function
        setTokenCookie(token);

        setIsLoggedIn(true);

        const acceptResponse = await apiClient.get(AcceptUserAgreement);
        if (acceptResponse.status === 200) {
          localStorage.removeItem("userData");
          successNote("Success");
          navigate("/");
        }
      }
    } catch (err) {
      //! If validation fails, display error messages
      if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      } else if (
        err.response.data.message ===
        "You have to approve your phone number first!"
      ) {
        toastErrorNotify(err.response.data.message);
        navigate("/phoneverification");
      } else if (err.response) {
        toastErrorNotify(err.response.data.message);
      } else if (err.response?.data.title) {
        toastErrorNotify(err.response.data.title);
      } else {
        toastErrorNotify("Registreringen mislyktes");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        backgroundImage: `url(${authBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            backgroundColor: "rgba(255,255,255, 0.8)",
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 5,
          }}
        >
          <img src={logoRed} alt="Logo" className="logoLogin" />
          <Typography component="h1" variant="h5">
            Register Super Administrator
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Fornavn"
                  autoFocus
                  error={Boolean(validationErrors.firstName)}
                  helperText={validationErrors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Etternavn"
                  name="lastName"
                  autoComplete="family-name"
                  error={Boolean(validationErrors.lastName)}
                  helperText={validationErrors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password1"
                  label="Passord"
                  type={values.showPassword1 ? "text" : "password"}
                  id="password1"
                  autoComplete="new-password"
                  error={Boolean(validationErrors.password1)}
                  helperText={validationErrors.password1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            edge="end"
                          >
                            {values.showPassword1 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="Passord"
                  type={values.showPassword2 ? "text" : "password"}
                  id="password2"
                  autoComplete="new-password"
                  error={Boolean(validationErrors.password2)}
                  helperText={validationErrors.password2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            edge="end"
                          >
                            {values.showPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Link
                  onClick={() => navigate("/phoneverification")}
                  color="primary"
                  sx={{ cursor: "pointer", marginLeft: 1 }}
                >
                  <Box display="flex" alignItems="center">
                    <KeyboardBackspaceIcon color="primary" sx={{ mr: 1 }} />
                    Start registrering på nytt
                  </Box>
                </Link>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="userAgreement"
                      checked={agreementChecked}
                      onChange={handleAgreementChange}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">Jeg godtar </Typography>
                      <Link
                        onClick={handleOpen}
                        sx={{ cursor: "pointer", fontSize:15, m:0.5 }}
                      >
                        brukeravtale
                      </Link>
                    </Box>
                  }
                />
              </Grid>
            </Grid>
            <Button
              className="PrimaryButton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!agreementChecked || loading}
            >
              {loading ? <Loading color={"#fff"} size={25} /> : "Register"}
            </Button>
            <Copyright sx={{ mt: 1 }} />
          </Box>
        </Box>

        <Dialog open={open} onClose={handleClose}>
      <UserAgreement handleClose={handleClose} agreementCheck={handleAgreementChange}/>
    </Dialog>
      </Container>
    </Grid>
  );
}
