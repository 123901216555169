const templateReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SELECT_TEMPLATE_DETAILS':
            // console.log('[templateReducer] SELECT_TEMPLATE_DETAILS', action.payload);
            const newState1 = {
                ...state,
                [action.payload.moduleId]: action.payload.templateDetails
            };
            return newState1;
        
        case 'RESET_TEMPLATE_DETAILS':
            // console.log('[templateReducer] RESET_TEMPLATE_DETAILS');
            return {};
            
        default:
            // console.log('[templateReducer] default case', action);
            return state;
    }
};

export default templateReducer;
