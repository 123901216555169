import React from "react";
import {  Typography, Paper } from "@mui/material";
import { useGetScheduledCoursesQuery } from "../../../services/ScheduledCourse/scheduledCourseApi";
import CourseDetailDisplay from "./CourseDetailDisplay";
import CenteredLoading from "../../../layout/CenteredLoading";


const ScheduledCourseContainer = () => {
  const { data = [], isFetching } = useGetScheduledCoursesQuery();

  if (isFetching)
    return (
      <CenteredLoading tekst={'Laster inn planlagte kurs…'}/>
    );

  const groupedData = {};

  data?.data?.forEach((course) => {
    const groupId = course.groupId;

    if (groupedData[groupId]) {
      // Hvis gruppen allerede finnes, legg til kurset i gruppen
      groupedData[groupId].courses.push(course);
    } else {
      // Hvis gruppen ikke finnes, opprett en ny gruppe og legg til kurset i den
      groupedData[groupId] = {
        groupId: groupId,
        courses: [course],
      };
    }
  });

  // !Get unique groupIds
  const uniqueGroupIds = new Set(data?.data?.map((course) => course.groupId));
 
  // !Separere etter grupper og lage array med nødvendige data
  //! Sortering for newest courses
  const groupedCourses = Array.from(uniqueGroupIds).reverse().map((groupId) => {
    const groupCourses = data?.data.filter(
      (course) => course.groupId === groupId
    );
    
    return groupCourses.map(
      ({
        scheduleId,
        groupId,
        endUserName,
        departmentId,
        courseId,
        courseName,
        startDateTime,
        endDateTime,
        endUserId,
      }) => ({
        scheduleId,
        groupId,
        endUserName,
        departmentId,
        courseId,
        courseName,
        startDateTime,
        endDateTime,
        endUserId,
      })
    );
  });


  return (
        <>
          {groupedCourses?.length ? (
            groupedCourses.map((course, i) => (
              <CourseDetailDisplay
                key={i}
                course={course}
                data={data?.data}
              />
            ))
          ) : (
            <Paper
            sx={{
              padding: 2,
              marginTop: 6,
              textAlign: "center",
            }}
          >
            <Typography>Det er for øyeblikket ingen planlagte kurs.</Typography>
          </Paper>
          )}
        </>
  );
};

export default ScheduledCourseContainer;
