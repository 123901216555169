import { useState } from "react";
import React from "react";
import BasicTemplateCard from "../../../layout/BasicTemplateCard";
import { Grid } from "@mui/material";
import { successNote, toastErrorNotify } from "../../../helpers/toastNotify";
import { useDeleteTemplateMutation } from "../../../services/Template/templateSlice";
import { getModuleTypeColor } from "../../../utils/displayUtils";
import { moduleTypeFromName } from "../../../utils/displayUtils";

const TemplateCard = React.memo(({ data, endpoint, label }) => {
  const [templateId, setTemplateId] = useState(null);
  const [deleteUrl, setDeleteUrl] = useState(null);
  const [deleteTemplate] = useDeleteTemplateMutation();

  // Delete operation
  const handleDelete = async () => {
    if (!templateId || !deleteUrl) {
      // handle error when templateId or deleteUrl is not set
      console.error("templateId or deleteUrl is not set");
      console.error("templateId", templateId, "deleteUrl", deleteUrl);

      return;
    }
    try {
      await deleteTemplate({ deleteUrl, templateId });
      successNote("Mal slettet");
    } catch (err) {
      // Error messages
      if (err.code === "ECONNABORTED") {
        toastErrorNotify("Timeout: Serveren brukte for lang tid på å svare");
      } else if (!err.response) {
        toastErrorNotify("Ingen serverrespons");
      } else {
        toastErrorNotify("Mislykkes");
      }
      console.log("err", err);
      console.log("templateid", templateId);
    }
  };

  return (
    <>
        <Grid item key={data.templateId}>
          <BasicTemplateCard
            date={
              data.isDefault
                ? ""
                : new Date(data.creationDateTime)
                    .toLocaleString("no-No")
                    .split(",")[0]
            }
            title={
              data.name.length > 22
                ? data.name.slice(0, 22).charAt(0).toUpperCase() +
                  data.name.slice(0, 22).slice(1) +
                  "..."
                : data.name.charAt(0).toUpperCase() + data.name.slice(1)
            }
            content={`Mal: ${data.trainingIsActive ? "Trening  " : ""}${
              data.testIsActive ? "Test  " : ""
            }${data.theoryIsActive ? "Teori" : ""}`}
            label={label}
            labelColor={`${getModuleTypeColor(moduleTypeFromName(label))}`}
            subContent={[
              data.trainingIsActive &&
                `Krav til godkjent trening: ${data.generalSuccessPercentage}%`,
              data.testIsActive &&
                `Krav til godkjent test: ${data.testApprovalPercentage}%`,
              data.patientCount && `Antall pasienter: ${data.patientCount}`,
            ]
              .filter(Boolean)
              .join(" \r\n")}
            onMouseOver={() => {
              setTemplateId(data.templateId);
              setDeleteUrl(endpoint);
            }}
            isDefault={data.isDefault}
            onDeleteButtonClick={() => handleDelete()}
          />
        </Grid>
    </>
  );
});

export default TemplateCard;
