import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetMentalTemplates, GetFysiskTemplates, GetForstehjelpTemplates,
  CheckAvailableTemplates, } from '../../api/endpoints';
import getCookie from "../../utils/cookieUtils"

export const getTemplateApi = createApi({
  reducerPath: 'getTemplateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/', 
    prepareHeaders: (headers) => {
      const token = getCookie('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    tagTypes: ['Templates'],  
  }),
  endpoints: (builder) => ({
    getMentalTemplates: builder.query({
      query: () => ({ url: GetMentalTemplates, method: 'GET'}),
      providesTags: ['Templates']
    }),
    getFysiskTemplates: builder.query({
      query: () => ({ url: GetFysiskTemplates, method: 'GET'}),
      providesTags: ['Templates']
    }),
    getForstehjelpTemplates: builder.query({
      query: () => ({ url: GetForstehjelpTemplates, method: 'GET'}),
      providesTags: ['Templates']
    }),
    deleteTemplate: builder.mutation({
      query: ({ deleteUrl, templateId }) => ({
        url: deleteUrl,
        method: 'POST',
        body: {
          templateId
        }
      }),
      invalidatesTags: ['Templates'],
    }),
    checkAvailableTemplates: builder.query({
      query: () => ({ url: CheckAvailableTemplates, method: 'GET'})
    }),
      addTemplate: builder.mutation({
      query: ({ templateUrl, params }) => ({
        url: templateUrl,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Templates'],
  }),
  }),
});

export const { useGetMentalTemplatesQuery, useGetFysiskTemplatesQuery, useGetForstehjelpTemplatesQuery, useDeleteTemplateMutation,  useCheckAvailableTemplatesQuery, useAddTemplateMutation} = getTemplateApi;
