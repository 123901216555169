import React, { useState, useEffect } from 'react'
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material'
import { useGetModulesBelongedToOrgQuery } from '../../services/Module/moduleSlice';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useDispatch } from 'react-redux';
import  { setAvailableModulesLoading} from '../../redux/Dashboard/loadingSlice';


const AvailableModules = () => {
  const { data, isLoading} = useGetModulesBelongedToOrgQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if(isLoading) {
      dispatch(setAvailableModulesLoading(true));
    }
  }, [isLoading, dispatch]);
  
  
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box >
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-start",
          mb: 2,
        }}
      >
        <Typography
          className='available-modules'
          sx={{ fontWeight: 600, mb: 1, display: "flex", alignItems: "center" }}
        >
          <DashboardIcon sx={{ mr: 1, color: "#858585" }} />
          Tilgjengelige moduler
        </Typography>
      </Box>
       
      <TableContainer component={Paper} sx={{ minWidth: 300, maxWidth:500 }}>
        <Table  aria-label="module table" size="small" >
          <TableHead >
            <TableRow>
              <TableCell sx={{fontWeight:600}}>Modul</TableCell>
              <TableCell sx={{fontWeight:600}}>Kategori</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((module) => (
              <TableRow
                key={module.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
              >
                <TableCell component="th" scope="row"   sx={{ borderBottom: 'none' }}>
                  {module.name}
                </TableCell>
                <TableCell  sx={{ borderBottom: 'none' }}>{module.moduleType} helse </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[4, 8, 12]}
          component="div"
          count={data?.data.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rader pr. side"
        />
      </TableContainer>
    </Box>
  )
}

export default AvailableModules
