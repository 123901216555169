import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {LoginUrl} from '../../api/endpoints';

export const verifyOtpApi= createApi({
  reducerPath: 'verifyOtpApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    verifyOtp: builder.mutation({
      query: (initialValues) => ({
        url: LoginUrl,
        method: 'POST',
        body: initialValues,
      }),
    }),
  }),
});

export const { useVerifyOtpMutation } = verifyOtpApi;
