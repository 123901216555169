import { useEffect } from "react";
import ModuleCard from './ModuleCard'
import { Box, Grid, Typography } from '@mui/material';
import TemplateCard from './TemplateCard';
import { useSelector } from 'react-redux';
import { validateTemplateStep } from "../MultiStepForm/validation";
import { Error } from "@mui/icons-material";


const TemplateSelection = ({ activeModuleIndex, selectedModules, errors, setErrors }) => {
  const selectedTemplatesFromRedux = useSelector(state => state.template);
  const selectedTemplateForCurrentModule = selectedTemplatesFromRedux[activeModuleIndex];

  useEffect(() => {
    const stepErrors = validateTemplateStep(selectedTemplatesFromRedux, selectedModules);

    if (selectedTemplatesFromRedux[activeModuleIndex]) {
        delete stepErrors[`template_${activeModuleIndex}`];
    }

    setErrors(stepErrors);
}, [selectedTemplatesFromRedux, activeModuleIndex, selectedModules]);


  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Grid container spacing={20}>
        <Grid item xl={3} md={3.5} sm={4}>
          <ModuleCard selectedModules={selectedModules} activeModuleIndex={activeModuleIndex}
           selectedTemplatesFromRedux={selectedTemplatesFromRedux}
           imageHeight={200}
           width={240} />
        </Grid>

        <Grid item xl={9} md={8.5} sm={8}>
    <TemplateCard 
        moduleType={selectedModules[activeModuleIndex-1]?.moduleType} 
        moduleId={selectedModules[activeModuleIndex-1]?.id} 
        activeModuleIndex={activeModuleIndex}
        selectedTemplateId={selectedTemplateForCurrentModule?.id}
    />
</Grid>
      </Grid>

      {errors && Object.keys(errors).length > 0 && Object.values(errors).map((error, index) => (
        <Grid item xl={9} md={8.5} sm={8} key={index}>
     <Typography
     color="error"
     sx={{ mt: 2, display: "flex", alignItems: "center" }}
   >
     <Error sx={{ mr: 1 }} />
   {error}
   </Typography>
   </Grid>
))}
      <Box display="flex" justifyContent="center" width="100%" sx={{mt:2}}>
        <h5>Modul {activeModuleIndex} av {selectedModules.length}</h5>
      </Box>
    </Box>
  )
}

export default TemplateSelection;
