import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

export default function ProgressBar({ completed }) {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={completed} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          completed,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
