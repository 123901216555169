import React from 'react';
import { Button, Box, Grid} from "@mui/material";

const FormButtons = ({ activeStep, steps, handleBack, handleNext, handleAddCourse, handleSave }) => {
  return (
      <Grid
          item
          xs={12}
          sx={{ mt: 2 }}
          container
          justifyContent="space-between"
        >
          {/* Placeholder for "Tilbake" button to keep the "Neste" button on the right */}
          {activeStep > 0 ? (
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ fontWeight: "bold" }}
            >
              Tilbake
            </Button>
          ) : (
            <div style={{ width: "100px", marginRight: "8px" }}></div> // Placeholder with similar width
          )}
          {activeStep !== steps.length - 1 && (
            <Button
              className="PrimaryButton"
              sx={{ width: "100px" }}
              onClick={handleNext}
            >
              Neste
            </Button>
          )}

          {activeStep === steps.length - 1 && (
            <Box>
              <Button
                className="SecondaryButton"
                sx={{
                  width: "100px",
                  marginRight: 2,
                  letterSpacing: 1,
                  fontWeight: "bold",
                }}
                onClick={handleAddCourse}
              >
                Lagre
              </Button>

              <Button
                className="PrimaryButton"
                sx={{ width: "160px" }}
                onClick={handleSave}
              >
                Lagre og planlegg
              </Button>
            </Box>
          )}
        </Grid>
  );
}

export default FormButtons;
