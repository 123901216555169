import { useState, React } from "react";
import { Button, Grid } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import TemplateCreationModal from "./TemplateCreationModal";

const CreateTemplateButton = ({getTemplates, fontHeader, width, height}) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid item >
        <Button
          onClick={() => setOpen(true)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#5177b8",
            fontSize: {fontHeader},
            width:{width}, height:{height},
            padding:0,
          }}
        >
          Opprett ny mal
          <AddCircleOutline sx={{ color: "#5177b8", fontSize: 40 }} />
        </Button>
        <TemplateCreationModal onClose={() => setOpen(false)} open={open} getTemplates={getTemplates}/>
    </Grid>
  );
};

export default CreateTemplateButton;
