import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Box, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function BasicTabs({ tabs }) {
  const location = useLocation(); 
  const activeTab = tabs.findIndex(tab => tab.path === location.pathname); 
  const [value, setValue] = useState(activeTab !== -1 ? activeTab : 0); 

  useEffect(() => {
    const currentTab = tabs.findIndex(tab => tab.path === location.pathname);
    setValue(currentTab !== -1 ? currentTab : 0);
  }, [location.pathname, tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          aria-label="basic tabs"
          sx={{ color: '#424242' }}
        >
          {tabs.map(({ label, icon, path, tooltip, className }, i) => (
            <Tooltip arrow placement="top" key={i} title={tooltip}>
              <Tab
                component={Link}
                to={path}
                label={label}
                icon={icon}
                className={className}
                iconPosition="start"
              />
            </Tooltip>
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={value}>
        {tabs[value].Component()}
      </TabPanel>
    </Box>
  );
}
