import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
  } from "@mui/material";
  import { Logout } from '@mui/icons-material';
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";


export function LogoutConfirmationModal({ open, onClose, onConfirm, title, description, cancelButtonTitle, confirmButtonTitle}) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            sx: {
                px:2, py:1
            },
        }}
      >
        <DialogTitle id="alert-dialog-title" >
          {title || "Er du sikker på at du vil slette?"}
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description">
            {description || "Denne handlingen kan ikke angres. Er du sikker på at du vil fortsette?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
  <Button variant='contained' className='PrimaryButton' onClick={onClose}>
    {cancelButtonTitle || "Avbryt"}
  </Button>
  <Button variant='contained' onClick={onConfirm} color="error" autoFocus>
    {confirmButtonTitle || "Bekreft"}
  </Button>
</DialogActions>

      </Dialog>
    );
  }

const LogOut = () => {
    const [modalOpen, setModalOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();


  const handleOpenModal = () => {
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleConfirmLogout = () => {
    logout();
    navigate('/login');
    handleCloseModal();
  }
  return (
    <div>
        <ListItemButton onClick={handleOpenModal}>
        <ListItemIcon className="sidebar-icon">
          <Logout /> 
        </ListItemIcon>
        <ListItemText primary="Logg ut" />
      </ListItemButton>

         <LogoutConfirmationModal
    open={modalOpen}
    onClose={handleCloseModal}
    onConfirm={handleConfirmLogout}
    title="Bekreft utlogging"
    description="Er du sikker på at du vil logge ut?"
    cancelButtonTitle="Avbryt"
    confirmButtonTitle="Logg ut"
  />
    </div>

  )
}

export default LogOut