import React from 'react'
import { CircularProgress, Box} from '@mui/material'
import '../index.css'

const Loading = ({color, size}) => {
  return (
    <Box sx={{ display: 'flex' }}>
    <CircularProgress className='loadingCircle' sx={{color: `${color}`}} size={size}/>
  </Box>
  )
}

export default Loading