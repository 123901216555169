import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/material';

const StatusIndicator = ({ startDateTime, endDateTime }) => {
  function courseStatus(startDateTime, endDateTime) {
    // Get current date
    const now = new Date();
    
    // Convert date strings to Date objects
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);

    // Check date range
    if (now < start) {
      return 'Planlagt'; // Course hasn't started
    } else if (now > end) {
      return 'Fullført'; // Course has finished
    } else if (start <= now && now <= end) {
      return 'Pågående'; // Course is ongoing
    }
  }

  const status = courseStatus(startDateTime, endDateTime);

  let color = '';
  
  switch(status){
    case 'Planlagt':
      color = 'yellow';
      break;
    case 'Fullført':
      color = 'grey';
      break;
    case 'Pågående':
      color = 'green';
      break;
    default:
      color = 'red';
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <CircleIcon sx={{ color: color }} fontSize='10'/>
      {status}
    </Box>
  );
};

export default StatusIndicator;
