import { React, useState } from "react";
import AdminList from "../components/AdminComponents/AdminList";
import CreateAdminForm from "../components/AdminComponents/CreateAdminForm";
import BasicTabs from '../layout/BasicTabs'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import Header from '../layout/Header'


const tabs = [
  {
    label: "Administratorliste",
    Component: () => <AdminList/>,
    icon:<GroupIcon/>
  },
  {
    label: "Opprett administrator",
    Component: () => <CreateAdminForm/>,
    icon:<PersonAddIcon/>
  },
];

const AdminPage = () => {
const [, setCheckDepLoading] = useState(true);


  return (
    <main>
      <Header header={'Admininnstillinger'} headerSize={'h6'} className={'administrator'}/>
        <BasicTabs tabs={tabs} />
    </main>
          
  );
};

export default AdminPage;
