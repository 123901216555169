import React from "react";
import { useState, useRef } from "react";
import {
  Button,
  Popper,
  MenuList,
  Grow,
  ClickAwayListener,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { nb } from "date-fns/locale"; // Import Norwegian locale


const DateFilter = ({ onDateChange }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  // ! Date Filter to User Results

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  // console.log('date', dateState)

  function handleDateRangeChange(date) {
    setDateState([date?.selection]);
    if (onDateChange) {
      onDateChange(date.selection.startDate, date.selection.endDate);
    }
  }

  // ! Button Functions

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // useEffect(() => {
  //   if (onDateChange) {
  //     onDateChange(dateState[0].startDate, dateState[0].endDate);
  //   }
  // }, []);

  return (
    <>
      <Button
        // variant="outlined"
        className="FilterButton"
        endIcon={<ExpandMoreIcon />}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          mx: 3,
          px: 2,
          "@media (max-width: 600px)": {
            mx: 1,
            px: 1,
          },
          "@media (max-width: 400px)": {
            mx: 0,
            px: 0,
          },
          // Truncate text and add ellipsis when it overflows
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Dato
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: "tooltip" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <DateRange
                     locale={nb} // Set the locale prop to Norwegian
                    editableDateInputs={true}
                    onChange={handleDateRangeChange}
                    moveRangeOnFirstSelection={false}
                    ranges={dateState}
                    minDate={new Date('2023')}
                    maxDate={new Date('2024')}

                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DateFilter;

