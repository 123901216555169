import React, {useState} from 'react'
import { Facebook, LinkedIn, YouTube} from "@mui/icons-material";
import { Dialog, Link, Typography, Stack, IconButton} from '@mui/material';
import PrivacyPolicy from './PrivacyPolicy';

const FooterIcons = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <>
    <Stack
      direction="row"
      spacing={1}
      className="footer"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        mb:5,
        ml:2
      }}
    >
      <IconButton
        className="sidebar-icon"
        aria-label="facebook-icon"
        href="https://www.facebook.com/makingview"
        target="_blank"
      >
        <Facebook />
      </IconButton>
      <IconButton
        className="sidebar-icon"
        aria-label="linkedin-icon"
        href="https://www.linkedin.com/company/making-view-as/mycompany/"
        target="_blank"
      >
        <LinkedIn />
      </IconButton>
      <IconButton
        className="sidebar-icon"
        aria-label="youtube-icon"
        href="https://www.youtube.com/c/MakingViewAS"
        target="_blank"
      >
        <YouTube />
      </IconButton>
    </Stack>

    <Link className='footer-label' onClick={handleClickOpen} sx={{marginTop:5}}>
        <Typography variant="body2">
          Personvernserklæring
        </Typography>
      </Link>
    </>
    <Dialog open={open} onClose={handleClose}>
      <PrivacyPolicy handleClose={handleClose} />
    </Dialog>
  </>
  )
}

export default FooterIcons