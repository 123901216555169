import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  typography: {
    fontFamily:[
      'Roboto', 'Open Sans', 'Lato'
  ].join(','),
  fontSize: 14,
  fontWeightLight:300,
  fontWeightRegular:500,
  fontWeightMedium:500,
  fontWeightBold:600
  },
  palette: {
    contrastThreshold: 4.5,
    primary: {
      main:"#5177b8",
      dark: '#2f4874', 
    },
    secondary: {
      main: '#5177b8',
    },
  },components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit',     // do not change background color in hover
            cursor:'default'
          }, 
      },}
    },
  },
});

export default theme;