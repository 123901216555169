import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetModulesBelongedToOrganization} from '../../api/endpoints';
import getCookie from "../../utils/cookieUtils";
import { setAvailableModulesLoading } from '../../redux/Dashboard/loadingSlice';

export const getModulesApi =  createApi({
    reducerPath:'getModulesApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            const token = getCookie('token');
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
          },
}),
endpoints:(builder)=>({
  getModulesBelongedToOrg:builder.query({
      query:()=>({url:GetModulesBelongedToOrganization, method:'GET'}),
      onQueryStarted: (arg, { dispatch, getState, queryFulfilled }) => {
        dispatch(setAvailableModulesLoading(true));
        queryFulfilled.then(() => {
          dispatch(setAvailableModulesLoading(false));
        });
      },
      providesTags: [{ type: 'Modules', id: 'GET' }],
  })
})

})

export const {useGetModulesBelongedToOrgQuery} = getModulesApi;