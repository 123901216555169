// Helper function to check if a string is valid Base64
function isValidBase64(s) {
    const re = /^[A-Za-z0-9+/=]+$/;
    return re.test(s);
  }
  
  export const getTokenExpirationDate = (token) => {
    const payload = token.split('.')[1];
    
    // If the payload is not a valid Base64 string, return null
    if (!isValidBase64(payload)) {
      return null;
    }
  
    const decodedPayload = JSON.parse(atob(payload));
    
    // If the payload doesn't contain an "exp" field, return null
    if (!decodedPayload.exp) {
      return null;
    }
    
    // Convert the "exp" field (a Unix timestamp) to milliseconds and create a Date object
    const expirationDateInMilliseconds = decodedPayload.exp * 1000;
    return new Date(expirationDateInMilliseconds);
  }


  // ! This function is used during development to simulate a token expiration scenario
//  test your application's behavior when the token has expired.
// export const getTokenExpirationDate = (token) => {
//   return new Date(0);
// }
