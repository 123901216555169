import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetAllSchedulesFromTheDepartment, GetAllSchedulesByUpperAdmin } from "../../api/endpoints";
import getCookie from "../../utils/cookieUtils"
import { setMiniCalendarLoading } from '../../redux/Dashboard/loadingSlice';

export const scheduledCourseApi = createApi({
    reducerPath: 'scheduledCourseApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            const token = getCookie('token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
        tagTypes: ['ScheduledCourses'], 
    }),
    endpoints: (builder) => ({
        getScheduledCourses: builder.query({
            query: () => {
                const role = localStorage.getItem("roles");
                const roleFromLocal = JSON.parse(role);
                let endpoint = "";

                for (const role of roleFromLocal) {
                    if (role === "org.admin.department") {
                        endpoint = GetAllSchedulesFromTheDepartment;
                        break;
                    } else if (
                        role === "org.admin.super" ||
                        role === "org.admin.assistant" ||
                        role === "org.admin.course"
                    ) {
                        endpoint = GetAllSchedulesByUpperAdmin;
                        break;
                    }
                }
                return endpoint;
            },
            onQueryStarted: (arg, { dispatch, getState, queryFulfilled }) => {
                dispatch(setMiniCalendarLoading(true));
                queryFulfilled.then(() => {
                  dispatch(setMiniCalendarLoading(false));
                });
              },
            providesTags: ['ScheduledCourses'],  
        }),
    }),
})

export const { useGetScheduledCoursesQuery } = scheduledCourseApi;


