import BasicCourseModal from "../../layout/BasicCourseModal";
import { Box, Typography, Stack, Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CheckIcon from "@mui/icons-material/Check";
import Loading from "../../layout/Loading";
import {
  useGetCourseDetailsQuery,
} from "../../services/GetCourseDetails/getCourseDetailsApi";
import { useFetchTemplateQuery } from "../../services/GetCourseDetails/getTemplateDetailsApi";

const DetailModal = ({ open, onClose, courseId }) => {

  // The query is skipped (skip === true) when the modal is not open (open === false), and executed otherwise
  const courseDetailQuery = useGetCourseDetailsQuery(courseId, { skip: !open });
const { data: courseDetails, error, isLoadingDetails } = courseDetailQuery;


  // Check if course details is loading or not available yet
  if (isLoadingDetails || !courseDetails) {
    // return <CenteredLoading />;
  }

  function RowItem({ label, moduleType, templateId }) {
    const templateQuery = useFetchTemplateQuery({ templateId, moduleType });
    const {
      data: template,
      isLoading,
    } = templateId
      ? templateQuery
      : { data: null, error: null, isLoading: false };


    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
        <Typography sx={{ color: "#0091ea" }}>{label}</Typography>
        <Typography sx={{ fontSize: 15, color: "#424242" }}>
          {moduleType} helse
        </Typography>
        {
          isLoading ? 
          <Loading size={20} color={"#858585"}/>
           : (  <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "#212121",
                fontSize: 14,
                fontWeight: 600,
                marginBottom: "5px",
              }}
            >
              Mal:
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 0.6,
                alignItems: "center",
                marginBottom: "5px",
                "@media (max-width: 690px)": {
                  "& svg": {
                    display: "none",
                  },
                },
              }}
            >
              {template?.data.testIsActive && (
                <>
                  <CheckIcon sx={{ color: "#4caf50", fontSize: 9 }} />
                  <Typography sx={{ color: "#212121", fontSize: 13 }}>
                    Test
                  </Typography>
                </>
              )}
              {template?.data.theoryIsActive && (
                <>
                  <CheckIcon sx={{ color: "#4caf50", fontSize: 9 }} />
                  <Typography sx={{ color: "#212121", fontSize: 13 }}>
                    Teori
                  </Typography>
                </>
              )}
              {template?.data.trainingIsActive && (
                <>
                  <CheckIcon sx={{ color: "#4caf50", fontSize: 9 }} />
                  <Typography sx={{ color: "#212121", fontSize: 13 }}>
                    Trening
                  </Typography>
                </>
              )}
            </Box>
            {template?.data.testApprovalPercentage && (
              <Typography sx={{ color: "#757575", fontSize: 14, mb: 0.5 }}>
                Godkjent test: {template.data.testApprovalPercentage}%
              </Typography>
            )}
            {template?.data.generalSuccessPercentage && (
              <Typography sx={{ color: "#757575", fontSize: 14, mb: 0.5 }}>
                Godkjent krav: {template.data.generalSuccessPercentage}%
              </Typography>
            )}
            {template?.data.patientCount && (
              <Typography sx={{ color: "#757575", fontSize: 14, mb: 0.5 }}>
                Antall pasienter: {template.data.patientCount}
              </Typography>
            )}
          </Box>)
        }
      
      </Box>
    );
  }

  const getContent = () => (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 2, sm: 3, md: 3 }}
      maxWidth="lg"
    >
      <Grid item xs={5} sm={5} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
            <CalendarMonthIcon sx={{ color: "#bdbdbd" }} />
            <Typography sx={{ fontSize: 13, color: "#bdbdbd" }}>
              Opprettet:{" "}
              {new Date(
                courseDetails?.data.creationDateTime
              ).toLocaleDateString("no-No")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
            <AccessTimeIcon sx={{ color: "#bdbdbd" }} />
            <Typography sx={{ fontSize: 13, color: "#bdbdbd" }}>
              {courseDetails?.data.estimatedTime
                ? courseDetails?.data.estimatedTime < 60
                  ? `${courseDetails?.data.estimatedTime} min`
                  : courseDetails?.data.estimatedTime % 60 === 0
                  ? `${Math.floor(
                      courseDetails?.data.estimatedTime / 60
                    )} time(r)`
                  : `${Math.floor(
                      courseDetails?.data.estimatedTime / 60
                    )} time(r) ${
                      courseDetails?.data.estimatedTime % 60
                    } min`
                : ""}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
            <AssessmentIcon sx={{ color: "#bdbdbd" }} />
            <Typography sx={{ fontSize: 13, color: "#bdbdbd" }}>
              {courseDetails?.data.moduleCount} modul(er)
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} md={8}>
        <Typography
          sx={{
            wordBreak: "break-word",
          }}
        >
          {courseDetails?.data.detail}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 18 }}>Moduler og mal</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {courseDetails?.data.moduler.map((modul, i) => {
            return (
              <Grid item key={i} xs={12} sm={6} md={5} lg={4} xl={3}>
                <RowItem
                  label={modul.moduleName}
                  moduleType={modul.moduleType}
                  templateId={modul.templateId}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <BasicCourseModal
      open={open}
      onClose={onClose}
      title={courseDetails?.data.courseName}
      content={getContent()}
    />
  );
};

export default DetailModal;
