import React from "react";
import { Chart } from "react-google-charts";
import Loading from "../../../layout/Loading"
import { Box } from "@mui/material";

export const options = {
    title: "Course Status",
    pieHole: 0.3,
    is3D: false,
    height: 120,
    width:250,
    backgroundColor: "#fff",
    chartArea: {
        left: 0,
        top: 9,
        right: 0,
        bottom: 9,
        width: "100%",
        height: "100%",
      },
      colors:[ '#808080', '#FF4136',  '#FFDC00', '#2ECC40'],
      tooltip: {
        textStyle: { fontName: "Roboto", fontSize:10 },
        trigger:'selection'
      },
        legend: {
            position: "right",
            textStyle: { fontName: "Roboto", fontSize: 8.5},
            alignment: "start",
          },
        
  };

export default function ResultsChart({chartData}) {
  return (
    <Chart
      chartType="PieChart"
      data={chartData}
      options={options}
      loader={
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
          <Loading size={20} color={'#616161'} />
        </Box>
      }
    />
  );
}

