import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Popper,
  MenuList,
  Grow,
  ClickAwayListener,
  MenuItem,
  Paper,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetAllDepartments } from "../../../api/endpoints";
import apiClient from "../../../api/apiClient";

const DepartmentFilter = ({ onSelectedDepartmentsChange }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [depData, setDepData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState(depData ? [depData[0]?.name] : []);
  const [dataFetched, setDataFetched] = useState(false);


  // ! Get All Department List
  async function getAllDep() {
    setLoading(true);
    try {
      const response = await apiClient.get(GetAllDepartments);
      setDepData(response.data.data);
      setDataFetched(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await getAllDep();
    }
  
    fetchData();
  }, []);
  
  useEffect(() => {
    // TODO: informer om ingen ansatte / organisasjon når depData == null?
    if (dataFetched && depData != null) {
      if (selectedDepartments.length === 0) {
        const allDepartmentNames = depData.map((department) => department.departmentName);
        onSelectedDepartmentsChange(allDepartmentNames);
      } else {
        onSelectedDepartmentsChange(selectedDepartments);
      }
    }
  }, [selectedDepartments, dataFetched]);
  
  

  const handleDepartmentSelect = (departmentName) => {
    if (selectedDepartments.includes(departmentName)) {
      setSelectedDepartments(
        selectedDepartments.filter((name) => name !== departmentName)
      );
    } else {
      setSelectedDepartments([...selectedDepartments, departmentName]);
    }
  };

  const handleDepartmentSelectAll = () => {
    const allDepartments = depData.map((item) => item.departmentName);
    setSelectedDepartments(allDepartments);
  };

  const handleDepartmentClearAll = () => {
    setSelectedDepartments([]);
  };

  // ! Button Functions

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <>
      <Button
  // variant="outlined"
  className="FilterButton"
  endIcon={<ExpandMoreIcon />}
  ref={anchorRef}
  id="composition-button"
  aria-controls={open ? "composition-menu" : undefined}
  aria-expanded={open ? "true" : undefined}
  aria-haspopup="true"
  onClick={handleToggle}
  sx={{
    mx: 3,
    px: 2,
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "@media (max-width: 600px)": {
      mx: 1,
      px: 1,
      maxWidth: "100%",
    },
    "@media (max-width: 400px)": {
      mx: 0,
      px: 0,
      maxWidth: "100%",
    },
  }}
>
  Avdeling
</Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: "tooltip" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    key="select-all"
                    onClick={handleDepartmentSelectAll}
                  >
                    Velg alle
                  </MenuItem>
                  {depData?.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => handleDepartmentSelect(item.departmentName)}
                      selected={selectedDepartments.includes(item.departmentName)}
                    >
                      {item.departmentName}
                    </MenuItem>
                  ))}
                  <MenuItem key="clear-all" onClick={handleDepartmentClearAll}>
                    <ClearIcon
                      fontSize="small"
                      sx={{ color: "#858585", marginRight: 1 }}
                    />
                    Fjern alle
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DepartmentFilter;
