import React, {useState, useEffect} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment/min/moment-with-locales.min";
import { Box, Typography } from "@mui/material";
import './style.css'
import EventDetailModal from "./EventDetailModal";
import { useDispatch, useSelector } from 'react-redux';
import { selectEvent } from "../../services/ScheduledCourse/eventSlice";
import { Views, Navigate } from 'react-big-calendar';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

moment.locale("nb");
const localizer = momentLocalizer(moment);

const BigCalendar = ({width, height, customToolbar, data, fontSize}) => {
  const dispatch = useDispatch();
  const selectedEvent = useSelector(state => state.event.selectedEvent);
  const [activeView, setActiveView] = useState('');

  // colors array
const colors = ["#9fa8da" , "#ef9a9a", "#90caf9", "#81d4fa", "#ce93d8",]; 

  // Group data by 'groupId'
  const groupedData = data?.data?.reduce((groups, item) => {
    const group = groups[item.groupId] || [];
    group.push(item);
    groups[item.groupId] = group;
    return groups;
  }, {});


  // Map over the data array and create an array of events
  const events = (groupedData ? Object.values(groupedData) : []).map((group, index) => ({
    title: group[0].courseName,
    start: new Date(group[0].startDateTime).toISOString(),
    end: new Date(group[0].endDateTime).toISOString(),    
    department: Array.from(new Set(group.map(department => department.departmentId))).join(", "),
    allDay: false,
    personCount: group.length,
    color: colors[index % colors.length],
  }));
  


  const messages = {
    previous: "Forrige",
    next: "Neste",
    today: "I dag",
    month: "Måned",
    week: "Uke",
    day: "Dag",
    agenda: "Agenda",
    date: "Dato",
    work_week: "Arbeidsuke",
    event: "Hendelse", // Or any other custom event title you want to use
    showMore: (total) => `+ Se mer (${total})`,
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      color: "#fff",
      border: "0px",
      opacity:0.9,
      margin:0,
      fontSize:fontSize
    };
    return {
      style: style,
    };
  };

  useEffect(() => {
    if (!activeView) {
      setActiveView('month');
    }
  }, [activeView]);

  const CustomToolbar = (toolbar) => {
    const viewWeek = () => {
      setActiveView('work_week');
      toolbar.onView(Views.WORK_WEEK);
    };
  
    const viewDay = () => {
      setActiveView('day');
      toolbar.onView(Views.DAY);
    };
  
    const viewMonth = () => {
      setActiveView('month');
      toolbar.onView(Views.MONTH);
    };

    const goToBack = () => {
      toolbar.onNavigate(Navigate.PREVIOUS);
    };
  
    const goToNext = () => {
      toolbar.onNavigate(Navigate.NEXT);
    };
  
    return (
      <Box sx={{
        display: 'flex', 
        backgroundColor:'#5177b8', 
        border:'none', 
        justifyContent: 'space-between' 
      }}>
     <Box sx={{display: 'flex', alignItems: 'center'}}>
    <KeyboardArrowLeftIcon className="custom-arrow" onClick={goToBack} />
    <Typography className="custom-label">{toolbar.label}</Typography>
    <KeyboardArrowRightIcon className="custom-arrow" onClick={goToNext} />
  </Box>
    
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <button className={`customButton ${activeView === 'day' ? 'active' : ''}`} onClick={viewDay}>I dag</button>
        <button className={`customButton ${activeView === 'work_week' ? 'active' : ''}`} onClick={viewWeek}>Arbeidsuke</button>
        <button className={`customButton ${activeView === 'month' ? 'active' : ''}`} onClick={viewMonth}>Måned</button>
      </Box>
    </Box>
     
    );
  };
  
 
  return (
    <Box sx={{ width:width, height:height, border: '1px solid #eec',  borderRadius: '5px', overflow: 'hidden'}}>
      <Calendar
        views={["day", "work_week", "month"]} 
        // toolbar={toolbar}
        selectable
        showAllEvents
        defaultDate={new Date()}
        defaultView="month"
        localizer={localizer}
        events={events}
        messages={messages}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={event => dispatch(selectEvent(event))}
        startAccessor={event => new Date(event.start)}
        endAccessor={event => new Date(event.end)}
        className="custom-calendar"
        onView={(view) => setActiveView(view)}
        components={{
          toolbar: customToolbar ? CustomToolbar : undefined,
        }}
        />
         <EventDetailModal
      event={selectedEvent}
      onClose={() => dispatch(selectEvent(null))}
  
    />
    </Box>
  );
};

export default BigCalendar;
