import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Card, CardContent, Grid } from '@mui/material';
import {EventAvailable, EventBusy, Group, Domain} from '@mui/icons-material';

const EventDetailModal = ({ event, onClose }) => {
  // If the event is null or undefined, hide the modal
  if (!event) return null;
  return (
<Dialog open onClose={onClose}>
  <DialogTitle sx={{display:'flex', justifyContent:'center', color:'#424242', fontWeight:600}}>
    {event.title}
  </DialogTitle>
  <DialogContent>
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <EventAvailable fontSize="small" color="disabled" />
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                Startdato
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body1">
              {new Date(event.start).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <EventBusy fontSize="small" color="disabled"/>
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                Sluttdato
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body1">
              {new Date(event.end).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Domain fontSize="small" color="disabled" />
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                Planlagte avdeling(er)
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body1">
              {event.department}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Group fontSize="small" color="disabled"/>
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                Planlagt antall personer: {event.personCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </DialogContent>
</Dialog>
  );
};

export default EventDetailModal;
