function getCookie(name) {
    let cookieArr = document.cookie.split(";");
    
    // Loop through the array elements
    for(let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
  
      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if(name === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }
    
    // Return null if the cookie by the given name does not exist
    return null;
  }
  
  export default getCookie;
  