import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";

export default function ModuleResultTable({ modulDetails }) {
  const { name } = modulDetails;

  function getStatusInfo(isStarted, isFinished, isSuccess) {
    if (isFinished && isSuccess) {
      return { status: "Godkjent" };
    } else if (isStarted && !isFinished) {
      return { status: "Påbegynt" };
    } else if (isFinished && !isSuccess) {
      return { status: "Ikke godkjent" };
    } else if (!isStarted && !isFinished) {
      return { status: "Ikke gjennomført" };
    } else {
      return { status: "Ukjent" };
    }
  }

  const renderDate = (dateString) => {
    const date = new Date(dateString);
    if (date.getTime() === 0 || isNaN(date.getTime())) {
      return "-";
    }
    return date.toLocaleDateString("no-NO");
  };

  return (
    <TableContainer sx={{ mb: 2 }}>
      <Typography variant="h6" color="primary.dark" sx={{ my: 1, ml: 2 }}>
        Modul: {name}
      </Typography>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead sx={{ backgroundColor: "#5177b8" }}>
          <TableRow>
            <TableCell sx={{ color: "#ffffff" }}>Undermodul</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Status</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Score</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Tid brukt</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Forsøk</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Sist Aktiv</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modulDetails?.submodules?.teori && (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": {
                  backgroundColor: "#f4f5f5",
                },
              }}
            >
              <TableCell>Teori</TableCell>
              <TableCell>
                {getStatusInfo(
                  modulDetails?.submodules.teori.isStarted,
                  modulDetails?.submodules.teori.isFinished,
                  modulDetails?.submodules.teori.isSuccess
                ).status}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.teori.bestScoreInHistory}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.teori.usedTime
                  ? `${modulDetails.submodules.teori.usedTime}`
                  : ''}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.teori.howManyTimesTaken}
              </TableCell>
              <TableCell>
                {renderDate(
                  modulDetails?.submodules?.teori.latestInteractionDateTime
                )}
              </TableCell>
            </TableRow>
          )}
          {modulDetails?.submodules?.test && (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": {
                  backgroundColor: "#f4f5f5",
                },
              }}
            >
              <TableCell>Test</TableCell>
              <TableCell>
                {getStatusInfo(
                  modulDetails?.submodules.test.isStarted,
                  modulDetails?.submodules.test.isFinished,
                  modulDetails?.submodules.test.isSuccess
                ).status}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.test.bestScoreInHistory}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.test.usedTime
                  ? `${modulDetails.submodules.test.usedTime}`
                  : ''}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.test.howManyTimesTaken}
              </TableCell>
              <TableCell>
                {renderDate(
                  modulDetails?.submodules?.test.latestInteractionDateTime
                )}
              </TableCell>
            </TableRow>
          )}
          {modulDetails?.submodules?.training && (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": {
                  backgroundColor: "#f4f5f5",
                },
              }}
            >
              <TableCell>Training</TableCell>
              <TableCell>
                {getStatusInfo(
                  modulDetails?.submodules.training.isStarted,
                  modulDetails?.submodules.training.isFinished,
                  modulDetails?.submodules.training.isSuccess
                ).status}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.training.bestScoreInHistory}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.training.usedTime
                  ? `${modulDetails.submodules.training.usedTime}`
                  : ''}
              </TableCell>
              <TableCell>
                {modulDetails?.submodules?.training.howManyTimesTaken}
              </TableCell>
              <TableCell>
                {renderDate(
                  modulDetails?.submodules?.training.latestInteractionDateTime
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
