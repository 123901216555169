import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function DeleteConfirmationModal({ open, onClose, onConfirm, title, description}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title || "Er du sikker på at du vil slette?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description || "Denne handlingen kan ikke angres. Er du sikker på at du vil fortsette?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{m:1}}>
        <Button variant='contained' className='PrimaryButton' onClick={onClose} >
          Avbryt
        </Button>
        <Button variant='contained' onClick={onConfirm} color="error" autoFocus>
          Slett
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteButtonWithConfirmation({
  onDeleteConfirm,
  buttonText = "Slett",
  title,
  description,
  buttonProps, variant, color, colorText, icon, disabled
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (onDeleteConfirm) {
      onDeleteConfirm();
    }
    handleClose();
  };

  return (
    <div>
     <Button 
  variant={variant} 
  onClick={handleClickOpen} 
  sx={{
    ...(color ? {color: color} : {color: '#fff'}), 
    ...buttonProps, 
    backgroundColor:"secondary"
  }}
          disabled={disabled}
            startIcon={icon} 
            color='primary'
          >

        {buttonText}
      </Button>
  
      <DeleteConfirmationModal
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title={title}
        description={description}
      />
    </div>
  );
}

export default DeleteButtonWithConfirmation;
