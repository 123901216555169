import { useState } from "react";
import {
  Container,
  Grid,
  Typography,
} from "@mui/material";
import CourseDescModal from "../CourseDesc/CourseDescModal";
import ModuleSelectionModal from "../Modules/ModuleSelectionModal";
import { Error } from "@mui/icons-material";
import TemplateSelection from "../Template/TemplateSelection";
import {
  validateCourseStep,
  validateModuleStep,
  validateTemplateStep,
} from "./validation";
import CoursePreview from "../CoursePreview/CoursePreview";
import { useSelector, useDispatch } from "react-redux";
import { useAddCourseMutation } from "../../../../../services/Course/courseSlice";
import {
  successNote,
  toastErrorNotify,
} from "../../../../../helpers/toastNotify";
import { useNavigate } from "react-router-dom";
import { resetTemplateDetails } from "../../../../../actions/templateActions";
import CustomStepper from "./CustomStepper";
import FormButtons from "./FormButtons";

const MultiStepForm = ({
  onSuccess,
  activeModuleIndex,
  setActiveModuleIndex,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedModules, setSelectedModulesInParent] = useState([]);
  const initialVisibleSteps = [
    "Opprett kurs",
    "Velg moduler",
    "Velg mal",
    "Kursforhåndsvisning",
  ];
  const [visibleSteps, setVisibleSteps] = useState(initialVisibleSteps);
  // const [activeModuleIndex, setActiveModuleIndex] = useState(0);
  const [steps, setSteps] = useState(initialVisibleSteps);
  const selectedTemplatesFromRedux = useSelector((state) => state.template);
  const [addCourse] = useAddCourseMutation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateStep = () => {
    let stepErrors = {};

    switch (activeStep) {
      case 0:
        stepErrors = validateCourseStep(formData);
        break;
      case 1:
        stepErrors = validateModuleStep(selectedModules);
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        stepErrors = validateTemplateStep(
          selectedTemplatesFromRedux,
          activeModuleIndex
        );
        break;
      case 6:
        break;
      default:
        stepErrors.default = "Error occured";
        break;
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleModulesChange = (modules) => {
    setSelectedModulesInParent(modules);
    setActiveModuleIndex(0);
    setFormData((prevData) => ({
      ...prevData,
      modules: modules,
    }));

    const newSteps = [...initialVisibleSteps.slice(0, 2)]; // take first and second steps

    if (modules.length > 0) {
      modules.forEach((_, index) => {
        newSteps.push(`Hidden Module Step ${index + 1}`); // unvisible steps
      });
      newSteps.push(initialVisibleSteps[3]); // Kursforhåndsvisning
    } else {
      newSteps.push(initialVisibleSteps[2]); // legg til Velg mal step
      newSteps.push(initialVisibleSteps[3]); // legg til Kursforhåndsvisning step
    }

    setSteps(newSteps);
    setVisibleSteps(initialVisibleSteps);
  };

  const handleNext = () => {
    if (!validateStep()) return;

    const isHiddenModuleStep =
      steps[activeStep + 1]?.startsWith("Hidden Module Step");
    const isHidden = steps[activeStep]?.startsWith("Hidden Module Step");
    const isLastStep = activeModuleIndex === selectedModules.length;

    if (isHiddenModuleStep) {
      if (!isLastStep) {
        setActiveModuleIndex((prevIndex) => {
          return prevIndex + 1;
        });
        setActiveStep((prevActiveStep) => {
          return prevActiveStep + 1;
        });
      } else {
        setActiveModuleIndex(0); // If it is last module set index to 0
      }
    } else if (isHidden && !isLastStep) {
      setActiveModuleIndex((prevIndex) => {
        return prevIndex + 1;
      });
    } else {
      setActiveStep((prevActiveStep) => {
        return prevActiveStep + 1;
      });
      setActiveModuleIndex(0);
    }
  };

  const isStepCompleted = (stepIndex) => {
    // ! if step is a hidden step
    if (
      steps[stepIndex]?.startsWith("Hidden Module Step") &&
      stepIndex + selectedModules.length - 1 < activeStep
    ) {
      return true;
      // ! if step is NOT a hidden step
    } else if (
      !steps[stepIndex]?.startsWith("Hidden Module Step") &&
      stepIndex < activeStep
    ) {
      return true;
    }
    return false;
  };

  const isStepActive = (stepIndex) => {
    //! 1. If it's a regular visible step and not a hidden step, then simply check if its index matches the active step.
    if (!steps[stepIndex]?.startsWith("Hidden Module Step")) {
      return stepIndex === activeStep;
    }

    //! 2. If the current active step is a "Hidden Module Step", then only the third step (Velg mal) should be considered active.
    if (steps[activeStep]?.startsWith("Hidden Module Step")) {
      return stepIndex === 2; // Index for the 'Velg mal' step
    }

    //! 3. Check if it's the last step
    if (
      activeStep === steps.length - 1 &&
      stepIndex + (selectedModules.length - 1) === activeStep
    ) {
      return true;
    }

    return false;
  };

  const handleBack = () => {
    //! 1. If the activeModuleIndex is greater than 1, decrease it.
    if (activeModuleIndex > 1) {
      setActiveModuleIndex((prevIndex) => prevIndex - 1);
      return;
    }

    //! 2. Handle the condition where the current step is "Hidden Module Step" and activeModuleIndex is 1.
    if (
      steps[activeStep]?.startsWith("Hidden Module Step") &&
      activeModuleIndex === 1
    ) {
      let previousVisibleStepIndex = activeStep - 1; // Start with the step right before the current step.

      //! Find the previous visible step.
      while (
        previousVisibleStepIndex >= 0 &&
        steps[previousVisibleStepIndex]?.startsWith("Hidden Module Step")
      ) {
        previousVisibleStepIndex--;
      }

      //! If a valid visible step is found, set it as the active step.
      if (previousVisibleStepIndex >= 0) {
        setActiveStep(previousVisibleStepIndex);
        setActiveModuleIndex(0);
        return;
      }
    }

    //! 3. If the previous step is a "Hidden Module Step", move back by one step and set the module index to its max value.
    if (steps[activeStep - 1]?.startsWith("Hidden Module Step")) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setActiveModuleIndex(selectedModules.length);
      return;
    }

    //! 4. Default condition: Just decrease the active step by one.
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function handleAddCourse(e) {
    e.preventDefault();
    const templatesArray = Object.values(selectedTemplatesFromRedux);

    const modulesArray = templatesArray.map((template) => ({
      moduleId: template.moduleId,
      templateId: template.id,
    }));

    const params = {
      name: formData.courseName,
      detail: formData.courseDetails,
      modules: modulesArray,
    };

    try {
      const response = await addCourse(params).unwrap();
      if (response?.success) {
        successNote("Nytt kurs lagt til");
        onSuccess();
        dispatch(resetTemplateDetails(activeModuleIndex));
        return;
      }
    } catch (error) {
      let errorMessage = "Mislyktes";
      console.log("err", error);
      if (
        error?.data?.data?.validationErrors &&
        error.data.data.validationErrors.length > 0
      ) {
        errorMessage = error.data.data.validationErrors[0];
      }
      if (error?.data?.message) {
        errorMessage = error.data.message;
      }
      if (error?.data?.title) {
        errorMessage = error.data.title;
      }
      toastErrorNotify(errorMessage);
    } finally {
      setLoading(false);
    }
  }

  const handleSave = async (e) => {
    try {
      await handleAddCourse(e);
      navigate("/kurs/planlegg-kurs");
    } catch (error) {
      console.error(
        "An error occurred while adding the course and navigating:",
        error
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <CustomStepper
        visibleSteps={visibleSteps}
        activeStep={activeStep}
        isStepCompleted={isStepCompleted}
        isStepActive={isStepActive}
      />
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} container justifyContent="center">
          {activeStep === 0 && (
            <CourseDescModal
              errors={errors}
              setErrors={setErrors}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {activeStep === 1 && (
            <>
              <ModuleSelectionModal
                selectedModules={selectedModules}
                onModulesChange={handleModulesChange}
                activeModuleIndex={activeModuleIndex}
              />
              {errors.module && (
                <Typography
                  color="error"
                  sx={{ mt: 2, display: "flex", alignItems: "center" }}
                >
                  <Error sx={{ mr: 1 }} />
                  {errors.module}
                </Typography>
              )}
            </>
          )}
          {steps[activeStep]?.startsWith("Hidden Module Step") && (
            <>
              <TemplateSelection
                activeModuleIndex={activeModuleIndex}
                setActiveModuleIndex={setActiveModuleIndex}
                selectedModules={selectedModules}
                setErrors={setErrors}
                errors={errors}
              />
              {errors.template && (
                <Typography
                  color="error"
                  sx={{ mt: 2, display: "flex", alignItems: "center" }}
                >
                  <Error sx={{ mr: 1 }} />
                  Vennligst velg en mal for modul {activeModuleIndex + 1}
                </Typography>
              )}
            </>
          )}
          {activeStep === steps.length - 1 && (
            <CoursePreview
              formData={formData}
              selectedModules={selectedModules}
            />
          )}
        </Grid>
        <FormButtons
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          handleAddCourse={handleAddCourse}
          handleSave={handleSave}
        />
      </Grid>
    </Container>
  );
};

export default MultiStepForm;
