import { useState, React } from "react";
import {
  Box,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  MenuItem,
  Link,
  Typography,
  Grid,
  Tooltip,
  Hidden,
  Button,
} from "@mui/material";
import { ExpandMore, FileDownload } from "@mui/icons-material";
import DoughnutChart from "./ResultsChart";
import DetailModal from "../../CourseTemplateDetails/DetailModal";
import UserTable from "./EmployeeDetailTable";
import "../../../index.css";
import { GetCourseResults } from "../../../api/endpoints";
import { formatNorwegianDate } from "../../../utils/dataUtils";
import { truncate } from "../../../utils/stringUtils";
import apiClient from "../../../api/apiClient";

const ResultsTable = ({ resultData }) => {
  const [open, setOpen] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  // const [openTooltip, setOpenTooltip] = useState(false);
  const [accOpen, setAccOpen] = useState(false);
  const XLSX = require("xlsx");

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setOpenTooltip(false);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const handleAccordionClick = () => {
    // setOpenTooltip(false);
    setAccOpen(true);
  };

  const handleOpen = (courseId) => {
    setSelectedCourseId(courseId);
    setOpen(true);
  };

  const convertData = (data) => {
    const wb = XLSX.utils.book_new(); 

    const ws_data = [];

    ws_data.push([
      "Kurs navn",
      "Kurs ansvarlig",
      "Planlagte avdelinger",
      "Kurs moduler",
      "Periode",
      "Gjennomført",
      "Påbegynt",
      "Ikke gjennomført",
      "Godkjent",
      "Ikke godkjent",
    ])

    ws_data.push(...data.map((item) => [
      item.courseName,
      item.courseOwnerNameSurname,
      item.departments.join(", "),
      item.moduleNames.join(", "),
      `${
        new Date(item.startDateTime).toLocaleString("no-NO").split(",")[0]
      } - ${new Date(item.endDateTime).toLocaleString("no-NO").split(",")[0]}`,
      item.counts.doneCount,
      item.counts.startedCount,
      item.counts.notStartedCount,
      item.counts.successCount,
      item.counts.failureCount
    ]));
    
    ws_data.push([]);

    ws_data.push([
        "Navn",
        "Etternavn",
        "Avdeling",
        "Sist aktiv",
        "Poeng",
        "Godkjent",
        "Status",
        "Sist oppdatert",
    ]);

    ws_data.push(...data
    .map((item) =>
      item.userStatus.map((user) => [
        user.name,
        user.surname,
        user.departmentName,
        user.lastActiveDateTime
          ? new Date(user.lastActiveDateTime)
              .toLocaleString("no-NO")
              .split(",")[0]
          : "-",
        user.score || "-",
        user.isApproved ? "Ja" : "Nei",
        user.status,
        user?.lastActiveDateTime
          ? new Date(user.lastActiveDateTime)
              .toLocaleString("no-NO")
              .split(",")[0]
          : "-",
      ])
    )
    .flat());

    
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, 'KursResultatOversikt');

    return wb;
  };

  const exportCourseResult = async (groupId) => {
    try {
      const params = { groupId };
      const response = await apiClient.post(GetCourseResults, params);
      const data = response.data.data;

      const wb = convertData(data);
      XLSX.writeFile(wb, "course-result.xlsx");
    } catch (error) {
      console.error("Error exporting course result:", error.response);
    }
  };

  function RowItem({
    fullData,
    data,
    label,
    icon: Icon,
    statusColor,
    subcontent,
  }) {
    return (
      <>
        <MenuItem sx={{ fontWeight: "600", color: "#858585" }}>
          {label}
        </MenuItem>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip
            title={fullData}
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [-5, 23],
                  },
                },
              ],
            }}
          >
            <MenuItem>{data}</MenuItem>
          </Tooltip>
        </Box>

        {subcontent && (
          <Link
            sx={{
              fontSize: 13,
              textDecoration: "none",
              cursor: "pointer",
              color: "primary",
              ml: 1.5,
            }}
            onClick={handleOpen}
          >
            {subcontent}
          </Link>
        )}
      </>
    );
  }

  const currentDate = new Date(); // Get the current date.

  const sortedResultData = resultData.sort((a, b) => {
    // Calculate the difference between each course's end date and the current date.
    const differenceA = Math.abs(currentDate - new Date(a.endDateTime));
    const differenceB = Math.abs(currentDate - new Date(b.endDateTime));
  
    // First, we sort based on the active status.
    if (a.isActive && !b.isActive) {
      return -1;
    } else if (!a.isActive && b.isActive) {
      return 1;
    } else {
      // If both courses are either active or inactive, we sort them by the end date.
      return differenceA - differenceB;
    }
  });
  


  return (
    <>
      {sortedResultData?.map((data, i) => (
        <Grid container sx={{ my: 5 }} key={i}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={10}
            onClick={() => setSelectedCourseId(data.courseId)}
          >
            <Box
              sx={{
                p: { xs: 2, sm: 2, md: 2 },
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                backgroundColor: "#fff",
              }}
            >
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={4} sm={5}>
                  <Chip
                    label={data.isActive ? "Aktiv" : "Inaktiv"}
                    sx={{
                      backgroundColor: data.isActive ? "#00c853" : "#bdbdbd",
                      color: "#fff",
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography className="container-header">
                      {data.courseName} -{" "}
                      {
                        new Date(data.startDateTime)
                          .toLocaleString("no-NO")
                          .split(",")[0]
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{ color: "primary" }}
                    onClick={() => exportCourseResult(data.groupId)}
                  >
                    <FileDownload fontSize="small" sx={{ mr: 1 }} />
                    Eksportér
                  </Button>
                </Grid>
              </Grid>
              <Grid container sx={{ my: 2 }}>
                {/* //! Chart */}

                <Grid item xs={8} sm={7} md={5} lg={3} xl={3}>
                  <DoughnutChart
                    chartData={[
                      ["Course", "Status"],
                      [
                        "Ikke gjennomført",
                        data.counts.notStartedCount || 0.0000000001,
                      ],
                      ["Ikke godkjent", data.counts.failureCount || 0.00000001],
                      ["Påbegynt", data.counts.startedCount || 0.00000000001],
                      ["Godkjent", data.counts.successCount || 0.00000000001],
                    ]}
                  />
                </Grid>
                <Hidden lgDown>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: 65 }}
                  />
                </Hidden>

                {/* //! Avdeling */}

                <Grid item xs={8} sm={5} md={4} lg={2.5} xl={2.5}>
                  <RowItem
                    label="Avdeling"
                    fullData={data.departments.join(", ")}
                    data={truncate(data.departments.join(", "), 20)}
                  />
                </Grid>
                <Hidden lgDown>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: 65 }}
                  />
                </Hidden>

                {/* //! Kursansvarlig */}

                <Grid item xs={8} sm={4} md={3} lg={2.5} xl={2}>
                  <RowItem
                    label="Kursansvarlig"
                    data={truncate(data.courseOwnerNameSurname, 16)}
                    fullData={data.courseOwnerNameSurname}
                  />
                </Grid>
                <Hidden lgDown>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: 65 }}
                  />
                </Hidden>

                {/* //! Moduler */}

                <Grid item xs={8} sm={4} md={5} lg={2.5} xl={2.5}>
                  <RowItem
                    label="Moduler"
                    data={truncate(data.moduleNames.join(", "), 21)}
                    fullData={data.moduleNames.join(", ")}
                    subcontent={"Se mer info"}
                  />
                </Grid>
                <Hidden lgDown>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: 65 }}
                  />
                </Hidden>

                {/* //! Periode */}

                <Grid item xs={8} sm={3} md={1.5} lg={1} xl={1}>
                  <RowItem
                    label="Periode"
                    fullData={
                      new Date(data.startDateTime).toLocaleDateString("no-No") +
                      ` - ` +
                      new Date(data.endDateTime).toLocaleDateString("no-No")
                    }
                    data={`${formatNorwegianDate(
                      new Date(data.startDateTime)
                    )} - ${formatNorwegianDate(new Date(data.endDateTime))}`}
                  />
                </Grid>
              </Grid>
              {/* <Tooltip
                arrow
                placement="bottom"
                title="Klikk og se alle deltakerne"
                open={!accOpen && openTooltip}
                onOpen={() => setOpenTooltip(true)}
                onClose={() => setOpenTooltip(false)}
              > */}
              <Accordion onClick={handleAccordionClick} sx={{ mt: 1 }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      flexGrow: 0,
                    },
                  }}
                ></AccordionSummary>
                <AccordionDetails sx={{ px: 0, py: 1 }}>
                  <UserTable userList={data.userStatus} />
                </AccordionDetails>
              </Accordion>
              {/* </Tooltip> */}
            </Box>
          </Grid>
        </Grid>
      ))}
      <DetailModal
        onClose={() => setOpen(false)}
        open={open}
        courseId={selectedCourseId}
      />
    </>
  );
};

export default ResultsTable;
