import React from "react";
import { DialogTitle, IconButton, DialogContent, Typography, Link as MuiLink} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const PrivacyPolicy = ({handleClose}) => {
  return (
    <>
      <DialogTitle sx={{  display: 'flex',
    justifyContent: 'space-between'}}>
        Personvernserklæring
        <IconButton  onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Making View AS med orgnr: 989 636 790 leverer kurs til våre kunder
          basert på VR-teknologi (Virtual Reality). Denne erklæringen
          spesifiserer hvilke personopplysninger vi samler inn når vi
          samarbeider med kundene våre og når vi leverer kursene våre. Denne
          personvernerklæringen gjelder for all databehandling i
          forretningsprosesser, kommunikasjon, leveranser og opplæring som
          Making View deltar i.
          <br />
          <br />
          Personvernerklæringen informerer om databehandlingen som utføres av
          Making View. Som behandlingsansvarlig skal Making View informere om
          formålet med databehandlingen og midlene som brukes til
          databehandlingen. I noen tilfeller, når vi behandler kundens data på
          vegne av våre kunder, og basert på deres instruksjoner, er kunden
          behandlingsansvarlig og MakingView-databehandler.
          <br />
          <br />
          Behandlingen av personopplysninger er nødvendig for at vi skal kunne
          betjene våre kunder. Ved å gi oss dine personopplysninger godtar du
          metodene og vilkårene som er beskrevet i denne personvernerklæringen
          <br />
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Hvilke data samler vi?
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Making View henter følgende data når du registreres som bruker:
        </Typography>
        <Typography variant="body2">
          Personlig identifikasjon (Navn, epost, mobilnummer, avdeling)
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Making View henter følgende data under utføring av VR-kurs:
        </Typography>
        <Typography variant="body2">
          Tidspunkt (Når du starter kurs, hvor lenge du holder på, når du
          avslutter) Informasjon om gjennomføring av moduler (Svar på quiz,
          navigasjon gjennom moduler, gjennomføring av pasientundersøkelser og
          annen data fra trening.) Skjermdeling fra VR brille kan gjøres av
          Making View administratorer for å gi teknisk hjelp etter ønske og
          samtykke fra sluttbruker/kunde. Stemmedata og hodebevegelse kan hentes
          fra gjennomføring av video-scenariorer til bruk i
          refleksjons-segmentene av opplæringen.
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Making View samler følgende anonymiserte data:
        </Typography>
        <Typography variant="body2">
          All gjennomføringsdato for teori, test og trening. Når du avslutter,
          og eventuelle feilmeldinger. Når VR-brillen blir slått på og hvor
          lenge den bruker forskjellige apper. Oppdateringsstatus for
          programvare og filer på brillen. Annen status på brillen (batteri-liv,
          versjonsnummer, etc.) Posisjon på håndkontrollere og headset relativt
          til rommet du står i. Lyd fra mikrofon på VR headsettet, slik at man
          kan snakke sammen i flerspiller-modus. Internett-status for å kunne
          koble til web-tjenester for pålogging og flerspiller.
        </Typography>{" "}
        <br />
        <Typography variant="body1" fontWeight={600}>
          Hvordan samler vi dataen din?
        </Typography>
        <Typography variant="body2">
          Vi henter data fra administrator ved din institusjon når du blir
          registrert som bruker. Denne dataen hentes fra Azure AD, eller skrives
          manuelt inn av kursansvarlig eller systemadministrator.<br/> Du gir oss det
          meste av dataene vi samler ved bruk av VR kursene ved å bevege på
          håndkontrollere og briller, og ved å snakke inn i mikrofonen på VR
          headsettet. Vi henter også data fra deg om du skulle svare på
          spørreundersøkelser relatert til VR kursene.<br/> Anonymisert data om
          status på VR brille blir hentet av tredjeparts-oppdateringsprogramvare
          slik at vi kan levere oppdateringer.
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Hvordan bruker vi dataen din?
        </Typography>
        <Typography variant="body2">
          Making View henter kontakt-info fra deg slik at vi kan gi deg tilgang
          til å logge på kursene våre, og slik at kursansvarlige kan spore
          gjennomføringen din av VR kurs. <br/> Vi henter data for å spore ytelsen din
          i kursene og for å få et bilde over hvor brukere eventuelt står fast i
          applikasjonen slik at vi kan forbedre opplevelsen. <br/> <br/> Status på VR brille
          og oppdateringer brukes for å forsikre oss om at kunder oppdaterer
          uten problemer og bruker riktig versjon av programvaren. Upresis
          geografisk posisjon på brillen hentes slik at Making View enkelt kan
          segmentere oversikten over VR briller ute hos forskjellige kunder, og
          spore eventuelle briller som går tapt. Generell informasjon om
          tidsbruk i brillen og oppdateringsstatus håndteres av ManageXR som er
          vår tredjepartsløsning for oppdatering av programvare. <br/><br/> Bevegelse av
          håndkontrollere og headset hentes slik at vi kan gjennomføre logikken
          i kurs-systemet for interaksjon mellom spiller og virtuelt
          miljø/pasient.Stemmedata og din virtuelle posisjon synkroniseres over
          nett til andre medspillere via en tredjepartsleverandør kalt
          PhotonEngine , dette lar deg se og høre andre spillere.<br/><br/> Mail og SMS
          for pålogging og registrering blir levert via SendGrid (epost) og
          Vonage SMS. Disse tjenestene mottar kun informasjonen som skal sendes,
          og nummer/epost det skal sendes til.
        </Typography>{" "}
        <br />
        <Typography variant="body1" fontWeight={600}>
          Hvordan lagrer vi dataen din?
        </Typography>
        <Typography variant="body2">
          Making View lagrer all personlig data (navn, nummer, e post,
          kurs-gjennomføring, etc.) i norske Microsoft Azure-servere. All data
          mellom backend og web frontend sendes kryptert med tilgang sikret via
          CloudFlare. Tilgangsnøkkel til frontend lagres lokalt på nettleseren
          som en cookie.
          <br />
          <br />
          Sensitiv data sendes obfuskert fra både VR brille og web-klient. All
          kommunikasjon til og fra VR brille krever en sikker login og er
          beskyttet av et token system. Data kan kun hentes inn i VR brillen for
          gjennomføring av kurs etter pålogging i VR eller sees av relevante
          systemadministratorer og kursansvarlige etter pålogging på
          web-konsollen MakingView.no/com.
          <br />
          <br />
          Making View beholder kundedata så lenge kundene våre har nytte av det.
          Anonymisert data blir beholdt og brukt til forbedring av våre
          produkter og tjenester. Data for enkeltpersoner kan slettes av
          administrator på kundesiden, eller av Making View ved ønske meldt fra
          kurs-bruker/administrator. Data som ønskes slettet vil være slettet
          fra våre systemer innen en måned. Brukere som blir slettet mister
          tilgang til kurs og applikasjon-pålogging. De vil også bli anonymisert
          i tidligere gjennomføringsdata.
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Utlevering av personopplysninger
        </Typography>
        <Typography variant="body2">
          Vi utleverer aldri personopplysninger til tredjeparter med mindre det
          er nødvendig for å fullføre leveransen til våre kunder. I tilfeller
          der vi har en leverandør som behandler personopplysninger på våre
          vegne, har vi en personopplysningsbehandleravtale som regulerer at
          personopplysningene behandles utelukkende i henhold til våre
          instruksjoner.
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Rettslig grunnlag for vår behandling
        </Typography>
        <Typography variant="body2">
          Vi fører et personregister over hvilke opplysninger vi samler inn, i
          hvilke systemer vi gjør det og hvilket rettslig grunnlag innsamlingen
          er basert på. I tilfeller der vi bruker samtykke som grunnlag for
          innsamling, har du rett til å trekke tilbake samtykket ditt. Hvis du
          ikke ønsker å motta tilbud og/eller markedsføring, vennligst kontakt
          oss. <br/><br/> Vi lagrer noen personopplysninger for å oppfylle juridiske eller
          regulatoriske krav, for eksempel bokføringsloven, noe som betyr at vi
          kan lagre dine personopplysninger i lengre tid. For å holde
          personopplysningene dine oppdaterte og korrekte, kan vi samle inn
          informasjon fra private og offentlige registre. <br/><br/> Det kan også hende at
          personopplysningene dine behandles basert på flere forskjellige
          juridiske grunnlag.
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Rettelse og informasjon
        </Typography>
        <Typography variant="body2">
          Du har rett til å få informasjon om hvilke personopplysninger som er
          lagret om deg. Hvis du ønsker å motta slik informasjon, må det sendes
          en skriftlig forespørsel til den behandlingsansvarlige. Avhengig av
          hva kontakten refererer til, kan vi kreve at du oppgir passende bevis
          på identitet.
          <br />
          <br />
          Hvis du vil benytte deg av din rett til å bli glemt, slettet eller få
          dataen din endret, vennligst skriv til oss på: <MuiLink href="mailto:post@makingview.com">post@makingview.com</MuiLink>
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Cookies/Informasjonskapsler
        </Typography>
        <Typography variant="body2">
          Vårt nettsted og app benytter informasjonskapsler og lokal lagring for
          å forbedre brukeropplevelsen og sikre riktig funksjon. Vi lagrer
          adgangstokenet ditt som en cookie i lokal lagring for å administrere
          autentisering og opprettholde innloggingsstatusen. Ved å bruke vårt
          nettsted og app, samtykker du til vår bruk av informasjonskapsler og
          lokal lagring. Hvis du ikke er enig, kan du justere
          nettleserinnstillingene dine eller velge å ikke bruke vårt nettsted og
          app.
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          SMS og epost-tjenester
        </Typography>
        <Typography variant="body2">
          I våre tjenester bruker vi tredjepartsleverandører for å sende SMS- og
          e-postvarsler til brukerne våre. Disse leverandørene hjelper oss med å
          levere viktige oppdateringer og innlogging. Ved å registrere deg for
          våre tjenester godtar du bruk av disse tjenestene.
          <br />
          <br />
          Vær oppmerksom på at disse tredjepartsleverandørene kan ha tilgang til
          dine personopplysninger, som mobilnummer og e-postadresse, utelukkende
          for å levere nødvendig funksjonalitet. Vi sørger for at disse
          leverandørene overholder gjeldende personvernbestemmelser og følger
          strenge konfidensialitetsavtaler for å beskytte dine
          personopplysninger.
          <br />
          <br />
          Hvis du ikke lenger ønsker å motta SMS- eller e-poster fra oss, kan du
          melde deg av ved å kontakte oss direkte. Vær oppmerksom på at du ikke
          vil kunne bruke kurs-systemene våre uten disse tjenestene.
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Ved brudd på dine rettigheter og datalekkasjer
        </Typography>
        <Typography variant="body2">
          Om du ønsker å rapportere at Making View har gjort et brudd på dine
          rettigheter, så må dette meldes til datatilsynet
          <br /> <br />
          Email: <MuiLink href="mailto:postkasse@datatilsynet.no">postkasse@datatilsynet.no</MuiLink>
        </Typography>
        <br />
        <Typography variant="body1" fontWeight={600}>
          Oppdatering
        </Typography>
        <Typography variant="body2">
          Making View kan oppdatere denne personvernerklæringen når som helst og
          vil publisere den nyeste versjonen på dette nettstedet. Denne ble
          oppdatert sist 22. Mai 2023.
        </Typography>
        <br />
      </DialogContent>
    </>
  );
};

export default PrivacyPolicy;
