import React, { useState } from "react";
import Joyride from "react-joyride";
import { useGuidedTour } from "../hooks/useGuidedTour";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import {  stopTour} from "../actions/tourActions";

const GuidedTour = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isTourRunning,} = useGuidedTour();
  const [showButtonBack, setShowButtonBack] = useState(true); 
 
  const role = localStorage.getItem("roles");
  const roleFromLocal = JSON.parse(role);
  const ROLES = {
    super: "org.admin.super",
    assistant: "org.admin.assistant",
    courseAdmin: "org.admin.course",
    integration: "org.admin.integration",
    department: "org.admin.department",
  };

  const steps = [
    {
      target: ".course-general-info",
      content: "Her finner du generell informasjon om kurset.",
      title: "Generell Kursinfo",
      disableBeacon: true,
      placement: "right-end",
      route: "/",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,
        ROLES.integration,]
    },
    {
      target: ".recommended-modules",
      content: "Se gjennom anbefalte moduler som kan forbedre din læring.",
      title: "Anbefalte Moduler",
      placement: "top",
      route: "/",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,
        ROLES.integration,]
    },
    {
      target: ".calendar-section",
      content: "Kalenderen viser viktige datoer og hendelser i kurset.",
      title: "Kalender",
      placement: "top",
      route: "/",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,
        ROLES.integration,]
    },
    {
      target: ".available-modules",
      content: "Bla gjennom tilgjengelige moduler for å utforske mer innhold.",
      title: "Tilgjengelige Moduler",
      placement: "top",
      route: "/",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,
        ROLES.integration,]
    },
    {
      target: ".kursplanlegger",
      content: "Opprett og inviter til kursgjennomføring.",
      placement: "bottom",
      title: "Kursplanlegger 💡",
      route: "/kurs",
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,]
    },
    {
      target: ".create-course-step",
      content:
        "For å opprette et kurs velger du en modul, for eksempel ABCDE, fra listen over tilgjengelige moduler. For hver modul velger du en mal som inneholder hva som skal gjennomføres og kriterier for godkjent kurs. Du kan enten bruke en eksisterende mal, eller opprette en selv under innstillinger.",
      placement: "right-end",
      title: "Opprett kurs 🧰",
      route: "/kurs",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,]
    },
    {
      target: ".plan-course-step",
      content:
        " Her velger du kurset du har opprettet og sender invitasjon til deltakerne. Deltakere kan velges på avdelingsnivå og individnivå. Invitasjonen sendes på SMS eller E-post og kan tilpasses om ønskelig.",
      placement: "right-end",
      title: "Planlegg kurs 📋",
      route: "/kurs",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,]
    },
    {
      target: ".scheduled-courses-step",
      content:
        " Her får du en oversikt over alle planlagte kurs, du kan også slette tidligere planlagte kurs.",
      placement: "right-end",
      title: "Planlagte kurs 📅",
      route: "/kurs",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,]
    },
    {
      target: ".template-settings-step",
      content:
        "Her har du en oversikt over alle maler og du kan opprette nye maler. En mal beskriver kriteriene for å bestå en modul.",
      placement: "right-end",
      title: "Innstillinger Mal ⚙️",
      route: "/kurs",
      buttonBack:true,
      requiredRoles: [ROLES.super,
        ROLES.courseAdmin,
        ROLES.assistant,
        ROLES.department,]
    },
    {
      target: ".administrator",
      content:
        "Tildel roller og kontroller sikkerheten til din organisasjon.",
      placement: "bottom",
      title: "Administrator 🔧",
      route: "/admin",
      requiredRoles: [ROLES.super,
        ROLES.assistant]
    },
    {
      target: ".kalender",
      content:
        "Oversikt over planlagte kurs.",
      placement: "bottom",
      title: "Kalender 📆",
      route: "/kalender",
      requiredRoles:[
        ROLES.courseAdmin,
        ROLES.super,
        ROLES.department,
        ROLES.assistant,
      ]
    },
    {
      target: ".resultater",
      content:"Oversikt over resultater på avdelings- og individnivå.",
      placement: "bottom",
      title: "Resultater 📊",
      route: "/resultat",
      requiredRoles:[
        ROLES.courseAdmin,
        ROLES.super,
        ROLES.department,
        ROLES.assistant,
      ]
    },
    {
      target: ".ansatte",
      content:
        "Oversikt over ansatte, med mulighet til å filtrere på navn, avdeling og stilling.",
      placement: "bottom",
      title: "Ansatte 🏢",
      route: "/ansatte",
      requiredRoles:[
        ROLES.courseAdmin,
        ROLES.super,
        ROLES.department,
        ROLES.assistant,
        ROLES.integration,
      ]
    },
    {
      target: ".integrasjon",
      content:
        "Integrer ditt eksisterende AzureAD system for å automatisk få tilgang til alle dine ansatte.",
      placement: "bottom",
      title: "Integrasjon 👩‍💻",
      route: "/integrasjon",
      requiredRoles: [ROLES.integration, ROLES.super]
    },
  ];

  const filteredSteps = steps.filter(step => {
    if (step.requiredRoles) {
      return step.requiredRoles.some(role => roleFromLocal.includes(role));
    }
    return true;
  });
  
  const handleCallback = (data) => {
    const { action, index, type } = data;
  
    setShowButtonBack(filteredSteps[index]?.buttonBack || false);
  
    if (action === "reset" || action === "stop" || action === "close") {
      dispatch(stopTour());
      return;
    }
  
    if (action === "next" && type === "step:after") {
      const currentStep = filteredSteps[index];
      const nextStep = filteredSteps[index + 1];
      if (nextStep?.route !== currentStep?.route) {
        navigate(nextStep?.route);
      }
    }
  };
  

  // const handleCallback = (data) => {
  //   const { action, index, type} = data;

  //   setShowButtonBack(steps[index]?.buttonBack || false);
 
  //   if (action === "reset" || action==="stop" || action==='close') {
  //     dispatch(stopTour()); 
  //     return; 
  // }

  //     // if (action === "prev" && index > 0) {
  //     //   const currentStep = steps[index];
  //     //   const prevStep = steps[index - 1];
  //     //   if (prevStep?.route !== currentStep?.route) {
  //     //     toggleTour(false);
  //     //     dispatch(setNavigationInProgress(true));
  //     //     goToStep(index - 1);
  //     //     navigate(prevStep?.route);
  //     //     setTimeout(() => {
  //     //       dispatch(startTour())
  //     //    }, 500);
  //     //   }
  //     // } 
  //     if (action === "next" && type === "step:after") {
  //       const currentStep = steps[index];
  //       const nextStep = steps[index + 1];
  //       if (nextStep?.route !== currentStep?.route) {
  //         navigate(nextStep?.route);
  //       }
  //     } 
  //   }


  return (
    <Joyride
      run={isTourRunning}
      steps={filteredSteps}
      continuous
      spotlightPadding={8}
      disableScrolling
      showSkipButton
      hideCloseButton
      locale={{
        back: "Tilbake",
        last: "Siste",
        next: "Neste",
        open: "Åpne dialogen",
        skip: "Lukk",
      }}
      styles={{
        options: {
          arrowColor: "#fff",
          backgroundColor: "#fff",
          primaryColor: "#7c4dff",
          textColor: "#424242",
          zIndex: 10000,
          textAlign:'left'
        },
        buttonBack: {
          display: showButtonBack ? 'inline' : 'none',
        },tooltip: {
          textAlign: 'left'
        }
      }}
      callback={handleCallback}
    />
  );
};

export default GuidedTour;
