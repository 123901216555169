import { useState, React } from "react";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import {
  Link,
  Box,
  Grid,
  Typography,
  IconButton,
  InputAdornment,   Container,
  Button,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OtpVerify from "../components/LoginComponents/OtpVerify";
import { successNote, toastErrorNotify } from "../helpers/toastNotify";
import Loading from "../layout/Loading";
import Copyright from "../components/LoginComponents/Copyright";
import * as Yup from "yup";
import { usePostOtpMutation } from "../services/auth/login";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [otpMenu, setOtpMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postOtp,] = usePostOtpMutation();

  const yupErrors = {};
  const [validationErrors, setValidationErrors] = useState({});

  const logoRed =  'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381822/Making%20View%20Assets/MV_logo_red_black_ctm6tn.png'
  const authBackgroundImage = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381778/Making%20View%20Assets/auth_background_texcon.jpg'

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("A valid email is required")
      .required("A valid email is required"),
    password1: Yup.string()
      .required("Password is required")
      .matches(
        /\w*[A-Z]\w*/,
        "Your password must contain at least one uppercase letter."
      )
      .matches(/\w*\d\w*/, "Your password must contain at least one number.")
      .matches(/^.{8,}$/, "Your password must be at least 8 characters."),
    // .matches(
    //   /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1}).*$/,
    //   "Password must contain one special case character"
    // ),
  });

  const [values, setValues] = useState({
    password1: "",
    showPassword1: false,
  });

  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData(e.currentTarget);
    const email = data.get("email");
    const password = data.get("password1");

    const initialValues = {
      email,
      password1: password,
      password2: password,
    };

    try {
      await validationSchema.validate(initialValues, { abortEarly: false });
      const resultAction = await postOtp(initialValues);
      // console.log(resultAction,  "action")
      if (resultAction.data?.statusCode === 200) {
        setOtpMenu(true);
        setEmail(email);
        successNote("Verifiseringskode sendes");
      }else {
         if (resultAction?.error?.status === 500) {
          toastErrorNotify("Server Error");
        } else if (resultAction?.error.data?.data) {
          toastErrorNotify(resultAction?.error.data.data?.validationErrors[0]);
        }else if (resultAction?.error.data.data === null) {
          toastErrorNotify(resultAction?.error.data?.message);
        }else if (!resultAction.error) {
            toastErrorNotify("Ingen serverrespons");
        } else {
          toastErrorNotify("Innlogging Mislyktes");
        }
        // console.log(resultAction?.error);
      }
    } catch (err) {
      console.log('Error:', err);
      if (err instanceof Yup.ValidationError) {
        // This is a validation error
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      } 
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        backgroundImage: `url(${authBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "120vh",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            backgroundColor: "rgba(255,255,255, 0.8)",
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 5,
          }}
        >
          {
            otpMenu ? (
              <OtpVerify email={email}/>
            ) : (
              <>
              <img src={logoRed} alt="Logo" className="logoLogin" />
          <Typography component="h1" variant="h5">
            Logg inn
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item sm={12}>
              <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-post "
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={Boolean(validationErrors.email)}
                    helperText={validationErrors.email}
                  />
              </Grid>
              <Grid item sm={12}>
              <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password1"
                    label="Passord"
                    type={values.showPassword1 ? "text" : "password"}
                    id="password1"
                    error={Boolean(validationErrors.password1)}
                    helperText={validationErrors.password1}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword1 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
              </Grid>
            </Grid>
             {/* <Grid> 
                 <FormControlLabel
                 control={<Checkbox value="remember" color="primary" />}
                   label="Remember me"
               /> 
           </Grid>  */}
            <Button
              className="PrimaryButton"
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <Loading color={"#fff"} size={25} /> : "Logg inn"}
            </Button>
                     <Grid item xs>
                      <Link href="/forgotpassword" variant="body2">
                       Glemt passord
                      </Link>
                    </Grid>
            <Copyright sx={{ mt: 1 }} />
          </Box>
              </>
            )
          }
        </Box>
      </Container>
    </Grid>
  );
}
