import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";


const EmployeeDetailTable = ({ userList }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("endUserName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const sortedCourse = userList?.sort((a, b) => {
  //   const isAsc = order === "asc";
  //   if (orderBy === "endUserName") {
  //     return isAsc
  //       ? a.endUserName.localeCompare(b.endUserName)
  //       : b.endUserName.localeCompare(a.endUserName);
  //   } else {
  //     return isAsc
  //       ? a.departmentId.localeCompare(b.departmentId)
  //       : b.departmentId.localeCompare(a.departmentId);
  //   }
  // });

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, course?.length - page * rowsPerPage);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow className="table-header-small">
            <TableCell sx={{ color: "#ffffff" }}></TableCell>
            <TableCell sx={{ color: "#ffffff" }}>
              <TableSortLabel
                active={orderBy === "endUserName"}
                direction={orderBy === "endUserName" ? order : "asc"}
                onClick={(event) => handleRequestSort(event, "endUserName")}
                sx={{
                  '&.MuiButtonBase-root.MuiTableSortLabel-root': {
                    color: '#fff',
                  },
                  '&.MuiButtonBase-root.MuiTableSortLabel-root:hover': {
                    color: '#fff',
                  },'& .MuiTableSortLabel-icon': {
                        color: '#fff !important',
                    },
                }}  
              >
                Navn
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: "#ffffff" }}>
              <TableSortLabel
                active={orderBy === "departmentId"}
                direction={orderBy === "departmentId" ? order : "asc"}
                onClick={(event) => handleRequestSort(event, "departmentId")}
                sx={{
                  '&.MuiButtonBase-root.MuiTableSortLabel-root': {
                    color: '#fff',
                  },
                  '&.MuiButtonBase-root.MuiTableSortLabel-root:hover': {
                    color: '#fff',
                  },'& .MuiTableSortLabel-icon': {
                        color: '#fff !important',
                    },
                }}  
              >
                Avdeling
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Status</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Resultat</TableCell>
            <TableCell sx={{ color: "#ffffff" }}>Sist aktiv</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user, index) => (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:nth-of-type(even)": {
                    backgroundColor: "rgba(234, 234, 234, .7)",
                  },
                  "&:hover": {
                    cursor: "pointer",
                },"&:hover .MuiTableCell-root": { 
                  color: "#0091ea",
                },
                }}
                key={index}
                onClick={() => {
                  navigate(`/resultat/${user.userId}`);
                }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {user?.name} {user?.surname}
                </TableCell>
                <TableCell>{user?.departmentName}</TableCell>
                <TableCell>{user?.status || "-"}</TableCell>
                <TableCell>{user?.score || "-"}</TableCell>
                <TableCell>
                  {user?.lastActiveDateTime
                    ? new Date(user.lastActiveDateTime)
                        .toLocaleString("no-NO")
                        .split(",")[0]
                    : "-"}
                </TableCell>
              </TableRow>
            ))}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 33 * emptyRows }}>
              <TableCell colSpan={3} />
            </TableRow>
          )} */}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={userList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rader pr. side"
      />
    </TableContainer>
  );
};

export default EmployeeDetailTable;