import { useEffect, useState, useRef, React } from "react";
import {
  Box,
  Button,
  Popper,
  MenuList,
  Grow,
  ClickAwayListener,
  MenuItem,
  Paper, Grid,
} from "@mui/material";
import { GetAllUsers } from "../../api/endpoints";
import SearchBar from "./SearchBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomDataGrid from "./CustomDataGrid";
import ClearIcon from "@mui/icons-material/Clear";
import apiClient from "../../api/apiClient";
import CenteredLoading from "../../layout/CenteredLoading";


export default function UserListUpperAdmin() {
  const [userList, setUserList] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [loading, setLoading] = useState(true);


  async function getAllUser() {
    try {
      const response = await apiClient.get(GetAllUsers);
      setUserList(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }


  // ! Organise the JSON data

  const flattenedUsers = userList?.reduce(
    (acc, { user }) => acc.concat(user),
    []
  );

  const option = flattenedUsers?.map(({ name, surname, departmentId, userId, profession,phone,email }) => ({
    id: `${userId}`,
    firstName: `${name}`,
    lastName: `${surname}`,
    department: `${departmentId}`,
    profession: `${profession}`,
    phone: `${phone}`,
      email: `${email}`
  }));

  const options = option?.sort((a, b) => a.id - b.id);
  const uniqueDepartments = new Set(
    flattenedUsers?.map(({ departmentId }) => departmentId)
  );

  const filteredOptions = options?.filter(
    ({ firstName, lastName, department }) =>
      firstName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      lastName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      department?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const filteredDep = options?.filter(({ department }) => {
    if (selectedDepartments.length === 0) {
      return true; // Return all options if nothing is selected
    }
    const departmentLowerCase = department?.toLowerCase();
    return selectedDepartments.some((selectedDep) =>
      departmentLowerCase.includes(selectedDep?.toLowerCase())
    );
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    getAllUser();
  }, []);

  // ! Filter Button Functions

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <Grid container sx={{
      maxWidth: "70rem", ml: 3
    }}>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          my: 3,
          mx: 1,
          width: "90%",
          maxWidth: "60rem",
        }}
      > */}
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={7} md={6}
        >
          <SearchBar onChange={handleSearch} />

        </Grid>
        <Grid item xs={12} sm={2} md={3} sx={{ flexBasis: '1%' }} />
        <Grid item xs={12} sm={3} md={3}>
          <Button
            className="FilterButton"
            startIcon={<FilterListIcon />}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            Avdeling
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{ zIndex: "tooltip" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        key="select-all"
                        onClick={() => {
                          setSelectedDepartments([...uniqueDepartments]);
                        }}
                      >
                        Velg Alle
                      </MenuItem>
                      {[...uniqueDepartments].map((item, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => {
                            if (selectedDepartments.includes(item)) {
                              setSelectedDepartments(
                                selectedDepartments.filter((d) => d !== item)
                              );
                            } else {
                              setSelectedDepartments([
                                ...selectedDepartments,
                                item,
                              ]);
                            }
                          }}
                          selected={selectedDepartments.includes(item)}
                        >
                          {item}
                        </MenuItem>
                      ))}
                      <MenuItem
                        key="clear-all"
                        onClick={() => {
                          setSelectedDepartments([]);
                        }}
                      >
                        <ClearIcon
                          fontSize="small"
                          sx={{ color: "#858585", marginRight: 1 }}
                        />
                        Fjern Alle
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      {/* </Box> */}
      {loading ? (
        <CenteredLoading tekst={'Laster inn…'} />
      ) : searchValue ? (
        filteredOptions && <CustomDataGrid rows={filteredOptions} onRowClick={(id) => console.log(id)} />
      ) : selectedDepartments ? (
        filteredDep && <CustomDataGrid rows={filteredDep} />
      ) : (
        options && <CustomDataGrid rows={options} />
      )}
      {/* </Box> */}
    </Grid>
  );
}
