import { useState, React } from "react";
import Copyright from "../LoginComponents/Copyright";
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  TextField, Link,
} from "@mui/material";
import { successNote, toastErrorNotify } from "../../helpers/toastNotify";
import Loading from "../../layout/Loading";
import { ValidateOTP, SendPhoneValidationOTP  } from "../../api/endpoints";
import axios from "axios";
import { useNavigate, } from "react-router-dom";
import * as Yup from "yup";

export default function OTPValidation() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const yupErrors = {};
  const storedUserData = localStorage.getItem('userData');
const userData = storedUserData ? JSON.parse(storedUserData) : null;
const [otpRequestCount, setOtpRequestCount] = useState(0);
const authBackgroundImage = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381778/Making%20View%20Assets/auth_background_texcon.jpg'
const logoRed =  'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381822/Making%20View%20Assets/MV_logo_red_black_ctm6tn.png'


  const validationSchema = Yup.object().shape({
    validationOTP: Yup.string().length(4, "'Kode' må være 4 tegn lang.").required("Verifiseringkode er påkrevd"),
  });


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (otpRequestCount >= 2) {  // if there are requests more then 3 times
      toastErrorNotify("Du kan ikke be om OTP mer enn 3 ganger");  // show error
      setLoading(false); 
      return; 
  }


    const data = new FormData(event.currentTarget);
    const initialValues = {
      validationOTP: data.get("otp"),
    };

    try {
      await validationSchema.validate(initialValues, { abortEarly: false });
      //! If validation succeeds, proceed with form submission
     const response=  await axios.post(ValidateOTP, {
      validationOTP: data.get("otp"),
      registrationKey: userData.registrationKey,
      email: userData.email,
      phone: userData.phone,
    });
    setOtpRequestCount(otpRequestCount + 1);
      if (userData.adminType === "super"){
      navigate("/registerroot");
      }else if (userData.adminType === "lower"){
      navigate("/register");
      }
      // console.log(JSON.stringify(response?.data));
    } catch (err) {
      // console.log('err', err)
      //! If validation fails, display error messages
      if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      }else if(err.response.data.message){
        toastErrorNotify(err.response.data.message);
      }else if(err.response.data.title){
        toastErrorNotify(err.response.data.title);
      } else {
        toastErrorNotify("Mislyktes");
      }
    } finally {
      setLoading(false);
    }
  };

  //! Send OTP Again

  const fetchOTPAgain = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post(SendPhoneValidationOTP, {
        validationKey:userData.validationKey,
        registrationKey: userData.registrationKey,
        email: userData.email,
        phone: userData.phone,
      });
      successNote("Verifiseringkode sent");
    } catch (err) {
      //! If validation fails, display error messages
      if (err?.inner) {
        err.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setValidationErrors(yupErrors);
      }else if(err.response.data.message){
        toastErrorNotify(err.response.data.message);
      }else if(err.response.data.title){
        toastErrorNotify(err.response.data.title);
      } else {
        toastErrorNotify("Mislyktes");
      }
      console.log(err);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Grid
      container
      component="main"
      sx={{
        backgroundImage: `url(${authBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "130vh",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            backgroundColor: "rgba(255,255,255, 0.9)",
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 5,
          }}
        >
          <img src={logoRed} alt="Logo" className="logoLogin" />
          <Typography component="h1" variant="h5">
          Bekreft verifiseringkode
          </Typography>
          <Typography sx={{ mt: 2, fontSize: 15.5 }}>
            Hvis du ikke mottar OTP, kan du be om å sende det igjen ved å klikke på 'Send igjen'. <br />
            <br />Det er mulig å motta en ny OTP opptil 3 ganger.
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
                <TextField
                sx={{mb:2}}
                  required
                  fullWidth
                  id="otp"
               label="Verifiseringkode"
               name="otp"
                 error={Boolean(validationErrors.validationOTP)}
                  helperText={validationErrors.validationOTP}
                />
                 <Box sx={{display:"flex", justifyContent:"space-between"}}>
   <Link onClick={fetchOTPAgain} sx={{cursor: 'pointer', mr: 6}}>Send igjen</Link>
   <Link onClick={()=>navigate('/phoneverification', { state: {userData} })} sx={{cursor: 'pointer', ml: 5}}>Endre mobilnummer</Link>
 </Box>  
            <Button
              className="PrimaryButton"
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <Loading color={"#fff"} size={25} /> : "Verify"}
            </Button>
            <Copyright sx={{ mt: 1 }} />
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
