import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import Dashboard from "../pages/Dashboard";
import CalendarPage from "../pages/CalendarPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import PasswordResetPage from "../pages/PasswordReset";
import ResultatPage from "../pages/ResultPage";
import AdminPage from "../pages/AdminPage";
import KontaktPage from "../pages/ContactPage";
import IntegrasjonPage from "../pages/IntegrationPage";
import KursPage from "../pages/CoursePage";
import Drawer from "../layout/sidebar/Drawer";
import RequireAuth from "../utils/RequireAuth";
import Unauthorized from "../components/LoginComponents/Unauthorized";
import AnsattePage from "../pages/EmployeePage";
import EmployeeResultsContainer from "../components/ResultsComponents/EmployeeIndividualResults/EmployeeResultsContainer";
import RegisterPage from "../pages/RegisterPage";
import RegisterRootPage from "../pages/RegisterRootAdmin";
import PhoneVerification from "../components/RegisterComponents/PhoneVerification";
import OTPValidation from "../components/RegisterComponents/OTPValidation";
import AdminProfile from "../components/DashboardComponents/AdminProfile";
import CoursePlanning from "../components/CourseComponents/PlanningTab/CoursePlanningTab";
import CourseTab from "../components/CourseComponents/CourseTab/CourseTab";
import ScheduledCourseContainer from "../components/CourseComponents/ScheduledCourseTab/ScheduledCourseContainer";
import TemplateTab from "../components/CourseComponents/TemplateTab/TemplateTab";

export const AppRouter = () => {
  const ROLES = {
    super: "org.admin.super",
    assistant: "org.admin.assistant",
    courseAdmin: "org.admin.course",
    integration: "org.admin.integration",
    department: "org.admin.department",
  };



  return (
    <BrowserRouter>
      {/* routes with sidebar*/}

      <Routes>
        <Route path="/" element={<Drawer />} />
        <Route path="kurs" element={<Drawer />}>
          <Route path="planlegg-kurs" element={<CoursePlanning />} />
          <Route path="planlagte-kurs" element={<ScheduledCourseContainer />} />
          <Route path="innstillinger-mal" element={<TemplateTab />} />
        </Route>
        <Route path="admin" element={<Drawer />} />
        <Route path="resultat" element={<Drawer />} />
        <Route path="integrasjon" element={<Drawer />} />
        <Route path="kontakt" element={<Drawer />} />
        <Route path="ansatte" element={<Drawer />} />
        <Route path="kalender" element={<Drawer />} />
        <Route path="/resultat/:id" element={<Drawer />} />
        <Route path="profile" element={<Drawer />} />

      </Routes>

      {/* public routes */}

      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="registerroot" element={<RegisterRootPage />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="passwordreset" element={<PasswordResetPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="phoneverification" element={<PhoneVerification />} />
        <Route path="otpvalidation" element={<OTPValidation />} />

        {/* protected routes */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.super,
                ROLES.courseAdmin,
                ROLES.assistant,
                ROLES.avdelingAdmin,
                ROLES.integrasjonAdmin,
              ]}
              roles
            />
          }
        >
          <Route path="/" element={<Dashboard />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.super,
                ROLES.courseAdmin,
                ROLES.assistant,
                ROLES.avdelingAdmin,
                ROLES.integrasjonAdmin,
              ]}
              roles
            />
          }
        >
          <Route path="profile" element={<AdminProfile />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.courseAdmin,
                ROLES.super,
                ROLES.department,
                ROLES.assistant,
              ]}
              roles
            />
          }
        >
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.courseAdmin,
                ROLES.super
              ]}
              roles
            />
          }
        >
          <Route path="kurs" element={<KursPage />}>
            <Route index element={<CourseTab />} />
            <Route path="planlegg-kurs" element={<CoursePlanning />} />
            <Route
              path="planlagte-kurs"
              element={<ScheduledCourseContainer />}
            />
            <Route path="innstillinger-mal" element={<TemplateTab />} />
          </Route>
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.courseAdmin,
                ROLES.super,
                ROLES.department,
                ROLES.assistant,
                ROLES.integration,
              ]}
              roles
            />
          }
        >
          <Route path="ansatte" element={<AnsattePage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.courseAdmin,
                ROLES.super,
                ROLES.department,
                ROLES.assistant,
              ]}
              roles
            />
          }
        >
          <Route path="kalender" element={<CalendarPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.courseAdmin,
                ROLES.super,
                ROLES.department,
                ROLES.assistant,
              ]}
              roles
            />
          }
        >
          <Route path="/resultat/:id" element={<EmployeeResultsContainer />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.courseAdmin,
                ROLES.super,
                ROLES.department,
                ROLES.assistant,
              ]}
              roles
            />
          }
        >
          <Route path="resultat" element={<ResultatPage />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={[ROLES.super, ROLES.assistant]} roles />
          }
        >
          <Route path="admin" element={<AdminPage />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={[ROLES.super, ROLES.assistant]} roles />
          }
        >


          <Route path="integrasjon" element={<IntegrasjonPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.integration,
                ROLES.courseAdmin,
                ROLES.super,
                ROLES.department,
                ROLES.assistant,
              ]}
              roles
            />
          }
        >
          <Route path="kontakt" element={<KontaktPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
