import { useSelector, useDispatch } from 'react-redux';
import { startTour, stopTour, setStepIndex, setSteps, resetTour} from '../actions/tourActions';

export const useGuidedTour = () => {
  const isTourRunning = useSelector((state) => state.tour.run);
  const currentStepIndex = useSelector((state) => state.tour.stepIndex);
  const dispatch = useDispatch();

  const toggleTour = () => {
    if (isTourRunning) {
      dispatch(stopTour());
    } else {
      dispatch(startTour());
    }
  };

  const goToStep = (index) => {
    // console.log(`Going to step ${index}`); // Belirli bir adıma gitmek için log atılıyor
    dispatch(setStepIndex(index));
  };  

  const initializeSteps = (steps) => {
    dispatch(setSteps(steps));
  };

  const resetTheTour = () => {
    dispatch(resetTour());
  };

  return { isTourRunning, toggleTour, currentStepIndex, goToStep, initializeSteps, resetTheTour };
};
