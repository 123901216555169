import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Link,
  Typography,
  Grid,
  Hidden,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import Loading from "../../../layout/Loading";
import DetailModal from "../../CourseTemplateDetails/DetailModal";
import {
  GetCourseDetails,
  DeleteScheduleCourseAsUpperadmin,
} from "../../../api/endpoints";
import { successNote, toastErrorNotify } from "../../../helpers/toastNotify";
import DeleteButtonWithConfirmation from "../../../layout/DeleteButtonWithConfirmation";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EnrolledUserTable from "./EnrolledUsersTable";
import {
  useGetScheduledCoursesQuery,
  scheduledCourseApi,
} from "../../../services/ScheduledCourse/scheduledCourseApi";
import { useDispatch } from "react-redux";
import { formatNorwegianDate } from "../../../utils/dataUtils";
import {truncate} from "../../../utils/stringUtils";
import apiClient from "../../../api/apiClient";


const CourseDetailDisplay = ({ course }) => {
  const [courseDetail, setCourseDetail] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { courseName, startDateTime, endDateTime, groupId } = course[0];
  const uniqueDepartmentIds = Array.from(
    new Set(course.map((item) => item.departmentId))
  );
  const formattedDepartmentIds = uniqueDepartmentIds.join(", ");
  const moduleName = courseDetail?.moduler
    .map((modul) => modul.moduleName)
    .join(", ");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [accOpen, setAccOpen] = useState(false);
  const { data = [], isFetching } = useGetScheduledCoursesQuery();
  const dispatch = useDispatch();

  // ! Accordion and tooltip
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenTooltip(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleAccordionClick = () => {
    setOpenTooltip(false);
    setAccOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  // ! Get More Course Details
  async function getScheduledCourses() {
    setIsLoading(true);
    try {
      const params = { courseId: course[0].courseId };
      const scheduledCourses = await apiClient.post(GetCourseDetails, params);
      setCourseDetail(scheduledCourses.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  // ! Delete Courses
  async function DeleteScheduledCourses(groupId) {
    try {
      const params = { groupId };
      await apiClient.post(DeleteScheduleCourseAsUpperadmin, params);
      successNote("Planlagte kurs slettet");
      // !Invalidate the 'ScheduledCourses' tag, to get the new scheduled course data
      dispatch(scheduledCourseApi.util.invalidateTags(["ScheduledCourses"]));
    } catch (err) {
      if (!err.response) {
        toastErrorNotify("Ingen serverrepons");
      }else if (err.response.data?.data.message) {
        toastErrorNotify(err?.response.data.data?.message);
      }else if (err?.response.data.data === null) {
        toastErrorNotify(err?.response.data?.message);
      } else if (err?.response.data.data?.validationErrors && err?.response.data.data?.validationErrors.length > 0) {
        toastErrorNotify(err?.response.data.data?.validationErrors[0]);
      } else {
        toastErrorNotify("Mislyktes");
      }
      // console.log("err", err);
    }
  }

  useEffect(() => {
    getScheduledCourses();
  }, []);

  // !Sett loading if scheduled course data is loading
  if (isFetching)
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: 6,
          ml: 2,
        }}
      >
        <Typography sx={{ color: "#858585", marginRight: 2 }} variant="body1">
          Laster inn planlagte kurs…
        </Typography>
        <Loading color={"#858585"} size={20} />
      </Box>
    );

  // !Custom Style To Menu Item
  function RowItem({ data, label, subcontent, fullData }) {

    return (
      <>
        <MenuItem sx={{ fontWeight: "600", color: "#858585" }}>
          {label}
        </MenuItem>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {
            fullData === undefined || fullData=== null ? (
              <Box sx={{ml:2}}>
              <Loading color='#616161' size={18}/>
              </Box>
            ) : (
              <Tooltip title={fullData} placement="top" PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [-5, 23],
                        },
                    },
                ],
            }} >
              <MenuItem>{data}</MenuItem>
            </Tooltip>
            )
          }
        </Box>
        {subcontent && (
            <Link
              sx={{
                fontSize: 13,
                textDecoration: "none",
                cursor: "pointer",
                color:(theme)=>theme.palette.secondary.main,
                ml: 2,
              }}
              className="customLink"
              onClick={handleOpen}
            >
              {subcontent}
            </Link>
        )}
      </>
    );
            }    


  return (
    <Grid container sx={{ mb: 3 }}>
      <Box
        sx={{
          p: 2.5,
          minWidth: 1050,
          border: 1,
          borderColor: "#e0e0e0",
          borderRadius: 2,
          backgroundColor: "#fff",
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'

        }}
      >
        <Grid
          container
          //  sx={{ display: "flex",
          //     justifyContent: "space-between",}}
        >
          <Grid item xs={0} sm={0} md={5} lg={5}></Grid>
          <Grid item xs={6} sm={6} md={5} lg={5} sx={{ ml: { sm: 2, md: 0 } }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EventAvailableIcon fontSize="small" sx={{ color: (theme) => theme.palette.primary.dark }} />
              <Typography
                sx={{ fontWeight: "bold", fontSize: 17, ml: 1 }}
                className="container-header"
              >
                {courseName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={5} md={2} lg={2}>
              <Box display="flex" justifyContent="flex-end">
                <DeleteButtonWithConfirmation
                  onDeleteConfirm={() => DeleteScheduledCourses(groupId)}
                  disabled={isLoading}
                  color="primary"
                  icon={<DeleteIcon />}
                  buttonText={<>Slett</>}
                />
              </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1.5 }}>
          <Grid item xs={8} sm={6} md={3} lg={3} xl={3}>
            <RowItem label="Avdeling" data={truncate(formattedDepartmentIds, 28)} fullData={formattedDepartmentIds} />
          </Grid>

          <Hidden lgDown>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ height: 70, mr: 1 }}
            />
          </Hidden>
          <Grid item xs={8} sm={6} md={3} lg={3} xl={3}>
            <RowItem label="Kursansvarlig" data={truncate((courseDetail?.courseOwnerName), 20)} fullData={courseDetail?.courseOwnerName}/>
          </Grid>

          <Hidden lgDown>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ height: 70, mr: 1 }}
            />
          </Hidden>
          <Grid item xs={8} sm={6} md={3} lg={3} xl={3}>
            <RowItem
              label="Moduler"
              data={truncate(moduleName, 25)}
              fullData={moduleName}
              subcontent={"Se mer info"}
            />
          </Grid>

          <Hidden lgDown>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ height: 70, mr: 1 }}
            />
          </Hidden>
          <Grid item xs={8} sm={6} md={3} lg={2} xl={2}>
            <RowItem
              label="Periode"
              fullData={
                new Date(startDateTime).toLocaleDateString("no-No") +
                ` - ` +
                new Date(endDateTime).toLocaleDateString("no-No")
              }
              data={`${formatNorwegianDate(
                new Date(startDateTime)
              )} - ${formatNorwegianDate(new Date(endDateTime))}`}
            />
          </Grid>
        </Grid>
        <Tooltip
          arrow
          placement="bottom"
          title="Klikk og se alle deltakerne"
          open={!accOpen && openTooltip}
          onOpen={() => setOpenTooltip(true)}
          onClose={() => setOpenTooltip(false)}
        >
          <Accordion
            onClick={handleAccordionClick}
            sx={{ mt: 2, bgcolor: " #fafafa " }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                "& .MuiAccordionSummary-content": {
                  flexGrow: 0,
                },
              }}
            ></AccordionSummary>
            <AccordionDetails sx={{ px: 0, py: 1 }}>
              <EnrolledUserTable course={course} />
            </AccordionDetails>
          </Accordion>
        </Tooltip>
      </Box>
      <DetailModal
        onClose={() => setOpen(false)}
        open={open}
        courseId={course[0].courseId}
      />
    </Grid>
  );
};
// }
export default CourseDetailDisplay;
