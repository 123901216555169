import React  from "react";
import { Grid } from "@mui/material";
import {
  DeleteFysiskTemplate,
  DeleteMentalTemplate,
  DeleteForstehjelpTemplate
} from "../../../api/endpoints";
import TemplateCard from "./TemplateCard";
import CreateTemplateButton from "./CreateTemplateButton"
import { useGetFysiskTemplatesQuery, useGetMentalTemplatesQuery, useGetForstehjelpTemplatesQuery } from "../../../services/Template/templateSlice";
import CenteredLoading from "../../../layout/CenteredLoading";
import { moduleNameFromType } from "../../../utils/displayUtils";


const TemplateTab = () => {
  const { data: mentalTemplates, isLoading: loadingMentalTemplates } = useGetMentalTemplatesQuery();
  const { data: fysiskTemplates, isLoading: loadingFysiskTemplates } = useGetFysiskTemplatesQuery();
  const { data: forstehjelpTemplates, isLoading: loadingForstehjelpTemplates } = useGetForstehjelpTemplatesQuery();

  const sortedMentalTemplates = mentalTemplates?.data 
  ? [...mentalTemplates.data].sort((a, b) => new Date(b.creationDateTime) - new Date(a.creationDateTime))
  : [];


  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
      maxWidth="xl"
      >
      {(loadingMentalTemplates || loadingFysiskTemplates || loadingForstehjelpTemplates) && (
      <CenteredLoading tekst={'Laster inn…'}/>
          )}
        {(!loadingMentalTemplates && !loadingFysiskTemplates && !loadingForstehjelpTemplates) && (
        <>
        <Grid item>
            <CreateTemplateButton fontHeader={18} width={250} height={240}/>
            </Grid>
          {/* // ! Get Mental and Fysisk Template Cards */}

          {sortedMentalTemplates?.map((data, i) => (
            <TemplateCard
              data={data}
              endpoint={DeleteMentalTemplate}
              label={`${moduleNameFromType('Mental')}`}
              key={i}
            />
          ))}

          {fysiskTemplates?.data.slice().reverse().map((data, i) => (
            <TemplateCard
              data={data}
              endpoint={DeleteFysiskTemplate}
                  label={`${moduleNameFromType('Fysisk')}`}
              key={i}
            />
          ))}

          {forstehjelpTemplates?.data.slice().reverse().map((data, i) => (
            <TemplateCard
              data={data}
              endpoint={DeleteForstehjelpTemplate}
                  label={`${moduleNameFromType('Forstehjelp')}`}
              key={i}
            />
          ))}
        </>
      )}
    </Grid>
  );
};

export default TemplateTab;
