export function getModuleTypeColor(moduleType) {
    switch (moduleType) {
        case 'Mental':
            return "#8994D1 ";
        case 'Fysisk':
            return "#C185B2 ";
        case 'Forstehjelp':
            return "#FA5C5C "
        default:
            return "#888888 ";
    }
}

export function moduleNameFromType(moduleType) {
    if (moduleType.includes('Fysisk') || moduleType.includes('Mental'))
        return moduleType + " Helse";

    return moduleType;
}

export function moduleTypeFromName(moduleName) {
    if (moduleName.includes(' Helse'))
        return moduleName.substring(0, moduleName.indexOf(' Helse'));

    return moduleName;
}