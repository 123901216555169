// Definerer konstantene
export const START_TOUR = 'START_TOUR';
export const STOP_TOUR = 'STOP_TOUR';
export const SET_STEP_INDEX = 'SET_STEP_INDEX';
export const SET_STEPS = 'SET_STEPS';
export const RESET_TOUR = 'RESET_TOUR';

// Handlinger (actions)
export const startTour = () => ({
  type: START_TOUR,
});

export const stopTour = () => ({
  type: STOP_TOUR,
});

export const setStepIndex = (stepIndex) => {
  localStorage.setItem('tourStepIndex', stepIndex);
  return {
    type: SET_STEP_INDEX,
    payload: {
      stepIndex
    }
  };
};

export const setSteps = (steps) => ({
  type: SET_STEPS,
  payload: {
    steps
  },
});

export const resetTour = () => ({
  type: RESET_TOUR
});
