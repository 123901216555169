
const FysiskHelse = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381798/Making%20View%20Assets/fysisk2_cpgau1.png'
const FysiskHelse2 = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1696934855/Making%20View%20Assets/fysiskhelse_lbrpoe.png'
const ABCDE = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1696934828/Making%20View%20Assets/abcde_yvvrdj.png'
const NEWS2= 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381807/Making%20View%20Assets/fysiskBilde_qso9s4.png'
const MentalHelse ='https://res.cloudinary.com/dge9ers9x/image/upload/v1696935724/Making%20View%20Assets/mental1_ime6ue.png'
const Mental2 = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381815/Making%20View%20Assets/mentalBilde_bznya1.png'
const Mental3 = 'https://res.cloudinary.com/dge9ers9x/image/upload/v1693381818/Making%20View%20Assets/voldOgTrusler_if5zl3.png'


export const allModuleData = [
  {
    id: 1,
    name: "ABCDE",
    moduleDetail: "Lær hvordan du utfører en ABCDE status på pasienter og reflekster rundt pasientcase.",
    totalEstimatedTime: "12",
    moduleType: "Fysisk",
    image: ABCDE
  },
  {
    id: 2,
    name: "NEWS2",
    moduleDetail: "Lær hvordan du bruker scoringssystemet for å fange opp forverret tilstand hos pasienter.",
    totalEstimatedTime: "13",
    moduleType: "Fysisk",
    image:NEWS2
  },
  {
    id: 3,
    name: "Broset",
    moduleDetail: "Bli med Stig mens han diskuterer og vurderer en bråkete klinisk syk pasient via BVC-standarden.",
    totalEstimatedTime: "65",
    moduleType: "Mental",
    image: Mental2
  },
  {
    id: 4,
    name: "Egensikkerhet",
    moduleDetail: "Her gis en innføring i ivaretakelse av egen sikkerhet i potensielt utfordrende situasjoner i møte med truende / voldelig atferd.",
    totalEstimatedTime: "36",
    moduleType: "Mental",
    image: MentalHelse
  },
  {
    id: 5,
    name: "ISBAR",
    moduleDetail: "Lær hvordan du kan plassere viktig informasjon i ISBAR kommunikasjonsmodell.",
    totalEstimatedTime: "8",
    moduleType: "Fysisk",
    image: FysiskHelse
  },
  {
    id: 6,
    name: "TidligTegn",
    moduleDetail: "Gir deg grunnleggende informasjon om ulike tidlig tegn i forkant av potensielt utfordrende atferd.",
    totalEstimatedTime: "15",
    moduleType: "Mental",
    image: Mental3
  },
  {
    id: 7,
    name: "Deeskalering",
    moduleDetail: "Her får du viktig kunnskap om ulike teknikker innenfor deeskalering, og du får mulighet til å observere teorien i praksis gjennom VR video.",
    totalEstimatedTime: "9",
    moduleType: "Mental",
    image: MentalHelse
  },
  {
    id: 8,
    name: "Førstehjelp",
    moduleDetail: "Lær grunnleggende førstehjelp og hjerte- og lungeredning i modulbasert førstehjelpskurs",
    totalEstimatedTime: "30",
    moduleType: "Fysisk",
    image: FysiskHelse2
  },
  {
      id: 9,
      name: "Refleksjonrom",
      moduleDetail: "I vårt refleksjonsrom kan man i gruppe på opp til 10 personer, få pre-brief, se et scenario, deretter få skreddersydde refleksjonspørsmål basert på innhold i scenarioet.",
    totalEstimatedTime: "30",
      moduleType: "Mental",
    image:Mental2
    },
  ]






